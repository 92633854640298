import React, { Component } from "react";
import MenuAdmin from "../components/administracion/MenuAdmin";
import MenuCalidad from "../components/calidad/MenuCalidad";
import MenuJefeObra from "../components/jefe obra/MenuJefeObra";
import MenuJefeGrupo from "../components/jefe grupo/MenuJefeGrupo";
import MenuAdminNormal from "../components/administracion/MenuAdminNormal";


class Slider extends Component {

  render() {
    return (
      <div id="slider" className={this.props.size}>
        <p id="brand-align" className="brand-titulo">
          {this.props.title}
        </p>
        {this.props.visibleAdmin === "true" && (<MenuAdmin />)}
        {this.props.visibleAdminNormal === "true" && (<MenuAdminNormal />)}
        {this.props.visibleJO === "true"  && (<MenuJefeObra subtitle="Jefe obra"/>)}
        {this.props.visibleJO2 === "true"  && (<MenuJefeGrupo subtitle="Jefe grupo"/>)}
        {this.props.visibleCA === "true" && (<MenuCalidad subtitle="Calidad y PRL"/>)}
       
      </div>
    );
  }
}

export default Slider;

import React, { Component } from "react";
import Logo from '../assets/images/LOGO_SIMA_png-02 (1).png';

class Footer extends Component {
  render() {
    return (
      <footer id="footer">
        
        <div className="center">
          <p>
            <img
              src={Logo}
              className="app-logo"
              alt="logotipo"
            />
            Calle Antonio Suárez, 10 - Edificio C Oficinas 006-007 - 28802
            Alcalá de Henares (Madrid)
          </p>
        </div>
        
      </footer>
    );
  }
}

export default Footer;

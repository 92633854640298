import React, { Component } from "react";
import logo from "../../assets/images/LOGO_SIMA-02 (1).jpg";
import { NavLink } from "react-router-dom";

class HeaderProveedor extends Component {
  render() {
    return (
      <header id="header">
        <div className="center">
          {/*LOGO*/}
          <div id="logo">
            <img src={logo} className="app-logo" alt="logotipo" />
          </div>

          {/**MENU*/}
          <nav id="menu">
            <ul>
              <li>
                <NavLink to="/home" activeClassName="active">
                  {this.props.inicioSesion2}
                </NavLink>
              </li>
              <li>
                <NavLink to="/proveedor/misfacturas" activeClassName="active">
                  {this.props.misFacturas}
                </NavLink>
              </li>
              <li>
                <NavLink to="/proveedor/factura" activeClassName="active">
                  {this.props.crearFactura}
                </NavLink>
              </li>
            </ul>
          </nav>

          {/*LIMPIAR FLOTADOS*/}
          <div className="clearfix"></div>
        </div>
      </header>
    );
  }
}

export default HeaderProveedor;

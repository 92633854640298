import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Login from "./components/Login";
import RegistroProveedor from "./components/proveedores/RegistroProveedor";
import FacturaProveedor from "./components/jefe obra/FacturaProveedor";
import RegistroUsuario from "./components/RegistroUsuario";
import RegistroJefeObra from "./components/administracion/RegistroJefeObra";
import RegistroJefeObraAdmin from "./components/administracion/RegistroJefeObraAdmin";
import RegistroProveedorAdmin from "./components/administracion/RegistroProveedorAdmin";
import JefeObra from "./components/jefe obra/JefeObra";
import Error from "./components/Error";
import MisFacturas from "./components/proveedores/MisFacturas";
import DatosFactura from "./components/administracion/DatosFactura";
import Pedidos from "./components/administracion/Pedidos";
import JefeGrupo from "./components/jefe grupo/JefeGrupo";
import RegistroJefeGrupoAdmin from "./components/administracion/RegistroJefegrupoAdmin";
import RegistroJefeGrupo from "./components/administracion/RegistroJefeGrupo";
import RegistroObras from "./components/administracion/RegistroObras";
import ObrasProyectos from "./components/administracion/ObrasProyectos";
import CalidadPrl from "./components/calidad/CalidadPrl";
import PedidosCalidad from "./components/calidad/PedidosCalidad";
import FacturasJefeObra from "./components/jefe obra/FacturasJefeObra";
import BajaJefeObra from "./components/administracion/BajaJefeObra";
import BajaJefeGrupo from "./components/administracion/BajaJefeGrupo";
import BajaObras from "./components/administracion/BajaObras";
import BajaProveedor from "./components/administracion/BajaProveedores";
import AsignarJefeObra from "./components/administracion/AsignarJefeObra";
import AsignarJefeGrupo from "./components/administracion/AsignarJefeGrupo";
import ConsultaProveedor from "./components/administracion/ConsultaProveedor";
import ConsultaJefeObra from "./components/administracion/ConsultaJefeObra";
import ConsultaJefeGrupo from "./components/administracion/ConsultaJefeGrupo";
import AdminNormal from "./components/administracion/AdminNormal";
import CrearFacturaAdmin from "./components/administracion/CrearFacturaAdmin";
import CrearFacturaAdminNormal from "./components/administracion/CrearFacturaAdminNormal";
import ConsultaFacturaAdmin from "./components/administracion/ConsultarFacturaAdmin";
import ConsultaFacturaAdminNormal from "./components/administracion/ConsultaFacturaAdminNormal";
import Gestoria from "./components/Gestoria";
import ModificarRolesContrasena from "./components/administracion/ModificarRolesContrasenas";
import Buzon from "./components/Buzon";
import AmpliacionPedCompra from "./components/jefe obra/AmpliacionPedCompra";
import PlazoPagoEspecial from "./components/jefe obra/PlazoPagoEspecial";
import Direccion from "./components/Direccion";
import Gestion from "./components/Gestion";
import AmpliacionPagoPedido from "./components/AmpliacionPagoPedido";
import AprobacionFacturas from "./components/AprobacionFacturas";
import AmpliacionPagoEspecial from "./components/AmpliacionPagoPedidoEspecial";


class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        {/*configurar rutas y paginas*/}
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <React.Fragment>
                <Header />
                <Login />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path="/home"
            render={() => (
              <React.Fragment>
                <Header />
                <Login />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path="/registro/proveedor"
            component={RegistroProveedor}
          />
          <Route exact path="/buzon" component={Buzon} />
          <Route exact path="/proveedor/factura" component={FacturaProveedor} />
          <Route
            exact
            path="/proveedor/factura/administracion"
            component={CrearFacturaAdmin}
          />
          <Route
            exact
            path="/proveedor/factura/administracion/normal"
            component={CrearFacturaAdminNormal}
          />
          <Route exact path="/proveedor/misfacturas" component={MisFacturas} />
          <Route exact path="/registro/usuario" component={RegistroUsuario} />
          <Route
            exact
            path="/modificacion/usuario"
            component={ModificarRolesContrasena}
          />
          <Route
            exact
            path="/home/administracion"
            component={ConsultaFacturaAdmin}
          />
          <Route exact path="/home/gestoria" component={Gestoria} />
          <Route
            exact
            path="/factura/administracion/normal"
            component={ConsultaFacturaAdminNormal}
          />
          <Route
            exact
            path="/home/administracion/normal"
            component={AdminNormal}
          />
          <Route exact path="/jefe/obra/factura" component={FacturasJefeObra} />
          <Route exact path="/proveedores" component={RegistroProveedor} />
          <Route
            exact
            path="/proveedores/consulta"
            component={ConsultaProveedor}
          />
          <Route
            exact
            path="/proveedores/administracion"
            component={RegistroProveedorAdmin}
          />
          <Route
            exact
            path="/proveedores/baja/administracion"
            component={BajaProveedor}
          />
          <Route exact path="/crear/obra" component={RegistroObras} />
          <Route exact path="/eliminar/obra" component={BajaObras} />
          <Route
            exact
            path="/jefe/obra/alta/administracion"
            component={RegistroJefeObraAdmin}
          />
          <Route
            exact
            path="/jefe/grupo/administracion"
            component={RegistroJefeGrupoAdmin}
          />
          <Route exact path="/jefe/obra/usuario" component={RegistroJefeObra} />
          <Route
            exact
            path="/jefe/obra/consulta/usuario"
            component={ConsultaJefeObra}
          />
          <Route
            exact
            path="/jefe/obra/baja/usuario"
            component={BajaJefeObra}
          />
          <Route
            exact
            path="/jefe/obra/modificar/usuario"
            component={AsignarJefeObra}
          />
          <Route
            exact
            path="/jefe/obra/ampliacion/pedido"
            component={AmpliacionPedCompra}
          />
          <Route
            exact
            path="/jefe/obra/plazo/pago/pedido"
            component={PlazoPagoEspecial}
          />
          <Route
            exact
            path="/jefe/grupo/usuario"
            component={RegistroJefeGrupo}
          />
          <Route
            exact
            path="/jefe/grupo/baja/usuario"
            component={BajaJefeGrupo}
          />
          <Route
            exact
            path="/jefe/grupo/consulta/usuario"
            component={ConsultaJefeGrupo}
          />
          <Route
            exact
            path="/jefe/grupo/modificar/usuario"
            component={AsignarJefeGrupo}
          />
          <Route exact path="/jefeObra/pedido" component={JefeObra} />
          <Route exact path="/ver/factura" component={DatosFactura} />
          <Route exact path="/pedidos" component={Pedidos} />
          <Route exact path="/jefeGrupo/pedido" component={JefeGrupo} />
          <Route exact path="/obras" component={ObrasProyectos} />
          <Route exact path="/proveedor" component={CalidadPrl} />
          <Route exact path="/calidad/pedidos" component={PedidosCalidad} />
          <Route exact path="/direccion" component={Direccion} />
          <Route exact path="/direccion/gestion" component={Gestion} />
          <Route exact path="/direccion/ampliacion" component={AmpliacionPagoPedido} />
          <Route exact path="/direccion/aprobacion" component={AprobacionFacturas} />
          <Route exact path="/direccion/pago" component={AmpliacionPagoEspecial} />

          <Route component={Error} />
        </Switch>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default Router;

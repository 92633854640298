import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";


class TablaJefeGrupo extends Component{
      //url base API
  url = Global.url;
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  state = {
    jefeGrupo: this.props.jefeGrupo,
    page: 0,
    rowsPerPage: 3,
    status: null,
  };
  getBossWork = (e) => {
    e.preventDefault();

    //peticion AJAX
    axios
      .get(this.url + "jefe/grupo",this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            jefeGrupo: res.data,
          });
        }
      })
      .catch((err) => {
        swal("No hay jefe de grupos que mostrar!!!", {
          icon: "warning",
        });
      });
  };

  getIdBossWork = (e) => {
    const id = e.target.dataset.txt;
    axios
      .get(this.url + "jefe/grupo/" + id,this.config)
      .then((res) => {
        this.setState({
          jefeGrupo: res.data,
        });
      })
      .catch((err) => {
        swal(
          "Upps hubo ocurrio algún error en la busqueda, intentelo de nuevo!!!",
          {
            icon: "warning",
          }
        );
      });
  };

  deleteBossWork = (e) => {
    e.preventDefault();

    swal({
      title: "¿Estas seguro/a que quieres eliminar a este usuario?",
      text: "El jefe de grupo se dara de baja!!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        axios.get(this.url + 'usuarios',this.config).then(user => {
          for(var i=0;i<user.data.length;i++){
           if(user.data[i].email === this.state.jefeGrupo.email){
  
            axios.delete(this.url + 'eliminar/usuario/' + user.data[i].id, this.config).then(response =>{
              swal("Usuario eliminado correctamente!!!", {
                icon: "success",
              });
            }).catch(err => {
              swal("No se pudo eliminar el usuario!!!",{icon:'warning'})
            })
  
           }
          }
        
        }).catch(err => {
          swal("No se ha podido encontrar usuarios!!!",{icon:'warning'})
        })
        axios
        .put(this.url + "actualizar/jefe/grupo/" + this.state.jefeGrupo.id,{estado:'BAJA',denominacion:this.state.jefeGrupo.denominacion},this.config)
        .then((res) => {
          this.setState({
            status: "success",
          });
          swal("Jefe grupo dado de baja correctamente!!", {
            icon: "success",
          });
        })
        .catch((err) => {
          swal("No se pudo dar de baja a el jefe de grupo, intentelo de nuevo!!!", {
            icon: "warning",
          });
        });

      } else {
        swal("Tranquilo/a el usuario no fue borrado!!");
      }
    });

  };

  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.jefeGrupo.length * this.state.rowsPerPage
    );
    render(){
        if (this.state.status === "success") {
            return <Redirect to="/home/administracion" />;
          }
        return(
            <div className="center">
            <section id="content">
              <h1 className="subheader">Datos jefe grupo</h1>
    
              <Paper>
                <TableContainer>
                  <Table className={this.useStyles.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Nombre</TableCell>
                        <TableCell align="left">Apellidos</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Cod proyecto</TableCell>
                        <TableCell align="left">Denominación</TableCell>
                        <TableCell align="left">Estado</TableCell>
                        <TableCell align="left">Ver Jefe grupo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.jefeGrupo.length >= 0
                        ? this.state.jefeGrupo
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage
                            ).sort((a,b) => a.nombre > b.nombre ? 1 : -1)
                            .map((row) => (
                              <TableRow key={row.id}>
                                <TableCell align="left">{row.nombre}</TableCell>
                                <TableCell align="left">{row.apellidos}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">
                                  {row.cod_proyecto}
                                </TableCell>
                                <TableCell align="left">
                                  {row.denominacion}
                                </TableCell>
                                <TableCell align="left">
                                  {row.estado}
                                </TableCell>
                                <TableCell align="left">
                                  <input
                                    type="submit"
                                    name="submit"
                                    value="Ver jefe grupo"
                                    data-txt={row.id}
                                    onClick={this.getIdBossWork}
                                    className="btn-table2"
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                        : null}
                      {this.emptyRows > 0 && (
                        <TableRow style={{ height: 0 * this.emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    labelRowsPerPage={"Filas por página"}
                    component="div"
                    rowsPerPageOptions={[1, 3, 10]}
                    count={
                      this.state.jefeGrupo.length >= 0
                        ? this.state.jefeGrupo.length
                        : 1
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Paper>
              <div id="search" className="slidebar-item">
                <form className="mid-form-formulario">
                  <div className="form-group">
                    <label htmlFor="Nombre">
                      Nombre:{" "}
                      <p style={{ fontWeight: 12 }}>{this.state.jefeGrupo.nombre}</p>
                    </label>
                  </div>
    
                  <div className="form-group">
                    <label htmlFor="Apellidos">
                      Apellidos:{" "}
                      <p style={{ fontWeight: 12 }}>
                        {this.state.jefeGrupo.apellidos}
                      </p>
                    </label>
                  </div>
    
                  <div className="form-group">
                    <label htmlFor="email">
                      Email:{" "}
                      <p style={{ fontWeight: 12 }}>{this.state.jefeGrupo.email}</p>
                    </label>
                  </div>
    
                  <div className="form-group">
                    <label htmlFor="cod_proyecto">
                      Cod proyecto:{" "}
                      <p style={{ fontWeight: 12 }}>
                        {this.state.jefeGrupo.cod_proyecto}
                      </p>
                    </label>
                  </div>
    
                  <div className="form-group">
                    <label htmlFor="denominacion">
                      Denominación:{" "}
                      <p style={{ fontWeight: 12 }}>
                        {this.state.jefeGrupo.denominacion}
                      </p>
                    </label>
                  </div>
    
                  <div className="form-group">
                    <label htmlFor="estado">
                      Estado:{" "}
                      <p style={{ fontWeight: 12 }}>
                        {this.state.jefeGrupo.estado}
                      </p>
                    </label>
                  </div>
    
                  <input
                    type="submit"
                    value="refrescar"
                    onClick={this.getBossWork}
                    className="btn btn-success"
                  />
                  {this.props.visible === "true" && (
                    <input
                      type="submit"
                      value="Eliminar GP."
                      onClick={this.deleteBossWork}
                      className="btn btn-success"
                    />
                  )}
                </form>
              </div>
            </section>
          </div>
        );
    }
}

export default TablaJefeGrupo;
import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import InputFiles from "react-input-files";
import Slider from "../slider";
import swal from "sweetalert";
import HeaderProveedor from "../proveedores/HeaderProveedor";
import IncidenciaProveedorCapa from "../proveedores/IncidenciaProveedorCapa";


class FacturaProveedor extends Component {
  //url base
  url = Global.url;
  //array de atributos para archivo subido
  selectedFileRef = [];
  pedidos = [];
  totalGarantia = [];
  totalFactura = 0;
  num_pedido = "";
  rol = '';
  name = '';
  email = '';
  user_id = '';

  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  //datos campos factura
  ivaporcentaje = "%iva";
  numPedidoRef = React.createRef();
  anoRef = React.createRef();
  cifRef = React.createRef();
  empresaref = React.createRef();
  nº_facturaRef = React.createRef();
  fecha_emisionref = React.createRef();
  porRetRef = React.createRef();
  retencionRef = React.createRef();
  retencionTotalIrpfRef = React.createRef();
  retencionGaTlRef = React.createRef();
  baseImpoRetRef = React.createRef();
  base_imponibleRef = React.createRef();
  ivaPorcentajeRef = React.createRef();
  ivaRef = React.createRef();
  base_imponibleRef2 = React.createRef();
  ivaPorcentajeRef2 = React.createRef();
  ivaRef2 = React.createRef();
  base_imponibleRef3 = React.createRef();
  ivaPorcentajeRef3 = React.createRef();
  ivaRef3 = React.createRef();
  total_facturaRef = React.createRef();

  //variables de proveedor

  state = {
    factura: {},
    pedido: {},
    email: {},
    form:[],
    proveedor: {},
    status: null,
  };

  calculoIva = () => {
    this.baseImponible = this.base_imponibleRef.current.value;

    this.porIva = this.ivaPorcentajeRef.current.value;

    this.iva = this.ivaRef.current.value;
    this.iva2 = this.ivaRef2.current.value;
    this.iva3 = this.ivaRef3.current.value;

    if (this.baseImponible !== 0) {
      this.iva =
        (parseFloat(this.baseImponible) * parseFloat(this.porIva)) / 100;

      this.totalRet =
        parseFloat(this.baseImponible) +
        parseFloat(this.iva) +
        parseFloat(this.iva2) +
        parseFloat(this.iva3);

      this.setState({
        factura: {
          iva: this.iva,

          total_factura1: this.totalRet,
        },
      });
    }
    axios.get(this.url + "user", this.config).then((res) => {
      if (res.data) {
        axios.get(this.url + "registro", this.config).then((response) => {
          for (var i = 0; i < response.data.length; i++) {

            if (res.data.email === response.data[i].email) {

              this.setState({
                proveedor: {
                  cif: response.data[i].cif,
                  empresa: response.data[i].nombre_empresa,
                  incidencias: response.data[i].incidencias,
                },
              });
            }
          }
        });
      }
    });

  };

  calculoIva2 = () => {
    this.baseImponible2 = this.base_imponibleRef2.current.value;
    this.porIva2 = this.ivaPorcentajeRef2.current.value;
    this.iva = this.ivaRef.current.value;
    this.iva2 = this.ivaRef2.current.value;
    this.iva3 = this.ivaRef3.current.value;

    if (this.baseImponible2 !== 0) {
      this.iva2 =
        (parseFloat(this.baseImponible2) * parseFloat(this.porIva2)) / 100;

      this.total2 =
        parseFloat(this.baseImponible2) +
        parseFloat(this.iva2) +
        parseFloat(this.iva) +
        parseFloat(this.iva3);

      this.setState({
        factura: {
          iva2: this.iva2,
          total_retencion_garantia: this.state.pedido.total_retencion_garantia,
          total_factura1: this.total2,
        },
      });
    }
    axios.get(this.url + "user", this.config).then((res) => {
      if (res.data) {
        axios.get(this.url + "registro", this.config).then((response) => {
          for (var i = 0; i < response.data.length; i++) {

            if (res.data.email === response.data[i].email) {

              this.setState({
                proveedor: {
                  cif: response.data[i].cif,
                  empresa: response.data[i].nombre_empresa,
                  incidencias: response.data[i].incidencias,
                },
              });
            }
          }
        });
      }
    });

  };

  calculoIva3 = () => {
    this.baseImponible3 = this.base_imponibleRef3.current.value;
    this.porIva3 = this.ivaPorcentajeRef3.current.value;
    this.iva = this.ivaRef.current.value;
    this.iva2 = this.ivaRef2.current.value;
    this.iva3 = this.ivaRef3.current.value;

    if (this.baseImponible3 !== 0) {
      this.iva3 =
        (parseFloat(this.baseImponible3) * parseFloat(this.porIva3)) / 100;

      this.total3 =
        parseFloat(this.baseImponible3) +
        parseFloat(this.iva3) +
        parseFloat(this.iva2) +
        parseFloat(this.iva);

      this.setState({
        factura: {
          iva3: this.iva3,
          total_retencion_garantia: this.state.pedido.total_retencion_garantia,
          total_factura1: this.total3,
        },
      });
    }
    axios.get(this.url + "user", this.config).then((res) => {
      if (res.data) {
        axios.get(this.url + "registro", this.config).then((response) => {
          for (var i = 0; i < response.data.length; i++) {

            if (res.data.email === response.data[i].email) {

              this.setState({
                proveedor: {
                  cif: response.data[i].cif,
                  empresa: response.data[i].nombre_empresa,
                  incidencias: response.data[i].incidencias,
                },
              });
            }
          }
        });
      }
    });
  };

  changeStateFactura = () => {
    var fecha = new Date();
    var ano = fecha.getFullYear();

    this.setState({
      factura: {
        num_pedido: this.numPedidoRef.current.value,
        año: ano,
        cif: this.cifRef.current.value,
        empresa: this.empresaref.current.value,
        nº_factura: this.nº_facturaRef.current.value,
        cod_proyecto: this.state.pedido.cod_proyecto,
        denominacion: this.state.pedido.denominacion,
        fecha_emision: this.fecha_emisionref.current.value,
        fecha_entrada: "sin fecha",
        forma_pago: this.state.pedido.forma_pago,
        fecha_prevista_pago: "sin fecha",
        fecha_real_pago: "sin fecha",
        base_imponible: this.base_imponibleRef.current.value,
        porcentaje_iva: this.ivaPorcentajeRef.current.value,
        iva: this.ivaRef.current.value,
        base_imponible2: this.base_imponibleRef2.current.value,
        porcentaje_iva2: this.ivaPorcentajeRef2.current.value,
        iva2: this.ivaRef2.current.value,
        base_imponible3: this.base_imponibleRef3.current.value,
        porcentaje_iva3: this.ivaPorcentajeRef3.current.value,
        iva3: this.ivaRef3.current.value,
        base_imponible_retencion: this.retencionTotalIrpfRef.current.value,
        porcentaje_retencion_irpf: this.porRetRef.current.value,
        retencion_irpf: this.retencionRef.current.value,
        total_retencion_irpf: this.retencionTotalIrpfRef.current.value,
        porcentaje_retencion_garantia: 0,
        retencion_garantia: 0,
        total_retencion_garantia: 0,
        total_factura1: this.total_facturaRef.current.value,
        total_factura2: this.total_facturaRef.current.value,
        total_factura3: this.total_facturaRef.current.value,
        estado_factura: "enviada",
        path_factura: "",
        observaciones: "Sin incidencias",
      },
    });
  };
  componentDidMount() {
    this.getProveedor();
  }

  //peticiones ajax
  getProveedor = () => {
    axios.get(this.url + "user", this.config).then((res) => {
      if (res.data) {
        axios.get(this.url + "registro", this.config).then((response) => {
          for (var i = 0; i < response.data.length; i++) {
           
            if (res.data.email === response.data[i].email) {
              this.setState({
                proveedor: {
                  nombre: response.data[i].nombre,
                  cif: response.data[i].cif,
                  email: response.data[i].email,
                  empresa: response.data[i].nombre_empresa,
                  incidencias: response.data[i].incidencias,
                },

              });
             
              axios.get(this.url + "pedidos", this.config).then((resp) => {

                for (var x = 0; x < resp.data.length; x++) {
                  if (
                    resp.data[x].estado === "pedido aceptado" &&
                    this.state.proveedor.empresa === resp.data[x].proveedor
                  ) {
                    this.pedidos[x] = resp.data[x];

                    this.setState({
                      pedido: {
                        num_pedido: this.pedidos[x].num_pedido,
                        cod_proyecto:this.pedidos[x].cod_proyecto,
                        total_retencion_garantia: this.pedidos[x]
                          .total_retencion_garantia,
                        importe_total_real:this.pedidos[x].importe_total_real,  
                      },
                    });
                  }
                }
              });
            }
          }
        });
      }
    });
    
  };
  createNotification = () =>{
  
    axios.get(this.url + 'role',this.config).then(role => {
      role.data.forEach(element => {
       
          if(element.name === 'jefe_obra'){
            this.rol = element.id;
          }
        
      });

      axios.get(this.url + 'role/' + this.rol,this.config).then(user =>{
      
        user.data.forEach(element => {
      
            this.name = element.name;
            this.email = element.email;
            this.user_id = element.id;
          
            
        });


        axios
        .post(
          this.url + "crear/notificacion/" + this.user_id,
          {title:this.name, description:'Factura enviada por el proveedor: ' + this.state.proveedor.empresa, user_id:this.user_id},
          this.config
        )
        .then((res) => {
          if (res.data) {
            swal(res.data, { icon: "success" });
          }
        })
        .catch((err) => {
          swal("No se pudo enviar notificacion!!", { icon: "warning" });
        });

      }).catch(err =>{
        swal('Upps, no se creo la notificaión!!',{icon:'warning'});
      })
    }).catch(err =>{
      swal('Upps, no se creo la notificaión!!',{icon:'warning'});
    })
  }

  //peticion para crear factura
  crearFactura = (e) => {
    e.preventDefault();

    //rellenar state con formulario
    this.changeStateFactura();

    //guardamos archivo factura
    this.saveFactura();

    axios
      .post(this.url + "crear/factura", this.state.factura, this.config)
      .then((res) => {
        this.setState({
          factura: res.data,
          status: "success",
        });
        swal("ok! Tu factura se registro exitosamente!", {
          icon: "success",
        });
        this.getProveedor();
       
        axios
          .get(this.url + "facturas", this.config)
          .then((response) => {
          
            for (var x = 0; x < response.data.length; x++) {
              console.log(' --> ' + this.state.pedido.importe_total_real );
           
              if(response.data[x].base_imponible === this.state.pedido.importe_total_real  && response.data[x].num_pedido === this.state.pedido.num_pedido){
                this.totalFactura +=
                parseFloat(response.data[x].base_imponible);
               
                
                if (
                  parseFloat(this.totalFactura) >
                  parseFloat(this.state.pedido.importe_total_real)
                  &&  parseFloat(this.base_imponibleRef.current.value) <  parseFloat(this.state.pedido.importe_total_real)
                ) {
                  this.sendEmail();
                  x = response.data.length;
                }
               
              }
              if(response.data[x].base_imponible !== this.state.pedido.importe_total_real && response.data[x].num_pedido === this.state.pedido.num_pedido){
                this.totalFactura +=
                parseFloat(response.data[x].base_imponible);
             
            
                if (
                  parseFloat(this.totalFactura) >
                  parseFloat(this.state.pedido.importe_total_real)
                  &&  parseFloat(this.base_imponibleRef.current.value) <  parseFloat(this.state.pedido.importe_total_real)
                ) {
                  this.sendEmail();
                  x = response.data.length;
                }
               
              }

            }
            this.createNotification();
          })
          .catch((err) => {
            swal(
              "Upps, hubo algún problema al comprobar los importes totales de tus facturas relacionado con su pedido!!",
              { icon: "info" }
            );
          });
      })
      .catch((err) => {
        swal("Hubo algún problema al guardar tu factura, intentelo de nuevo.", {
          icon: "warning",
        });
      });
     // this.clearState();
  };
  saveFactura = () => {
    //año actual
    var fecha = new Date();
    var ano = fecha.getFullYear();
    //crear formdata y añadir fichero
    const formData = new FormData();
    formData.append(
      "file0",
      this.selectedFileRef[0],
      this.selectedFileRef[0].name,
      this.state.form.mounth
    );

    var number = fecha.getMonth() + parseInt(1);

    axios.post(this.url + 'storage/create/subdir/bills/' + ano, {mounth:number},this.config).then(subdir =>{
      if(subdir.data){
        swal("Nuevo directorio creado.", {
          icon: "success",
        });
      }
    })

    axios
      .post(this.url + "storage/create/file/bill/" + number + '/' + ano,  formData , this.config)
      .then((res) => {
        swal("Factura creada.", {
          icon: "success",
        });
      })
      .catch((err) => {
        swal("Hubo algún problema al crear tu factura, intentelo de nuevo.", {
          icon: "warning",
        });
      });
  };

  getOrders = (e) => {
    this.num_pedido = e.target.value;

    axios
      .get(this.url + "pedidos", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].num_pedido === this.num_pedido) {
            this.setState({
              pedido: res.data[i],
            });
          }
        }
      })
      .catch((err) => {
        swal("Hubo algún problema al buscar tus pedidos!!.", {
          icon: "warning",
        });
      });
  };

  sendEmail = () => {
    axios.get(this.url + 'role',this.config).then(role => {
      role.data.forEach(element => {
       
          if(element.name === 'jefe_obra'){
            this.rol = element.id;
          }
        
      });

      axios.get(this.url + 'role/' + this.rol,this.config).then(user =>{
      
        user.data.forEach(element => {
      
            this.name = element.name;
            this.email = element.email;
            this.user_id = element.id;
          
            
        });
    axios
      .get(this.url + "jefe/obra", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {

          if (res.data[i].cod_proyecto === this.state.pedido.cod_proyecto) {
            this.setState({
              email: {
                emailTo: this.email, //jefe obra
                email: this.state.proveedor.email, //email contacto
                name:
                  this.state.proveedor.nombre +
                  ", " +
                  this.state.proveedor.email,
                title:
                  "El total de la/s factura/s es mayor al importe total de el pedido; Código proyecto: " +
                  res.data[i].cod_proyecto +
                  ", denominación: " +
                  res.data[i].denominacion +
                  ", Número pedido: " + 
                  this.state.pedido.num_pedido +
                  ", total facturas: " + 
                  this.totalFactura
              },
            });
            console.log(this.state.email, this.state.proveedor.email);
          }
        }
        axios
          .post(this.url + "email", this.state.email, this.config)
          .then((email) => {})
          .catch((err) => {
            swal("Error envio de correo!!", { icon: "warning" });
          });
      })
      .catch((err) => {
        swal(
          "Nose pudo encontrar la obra relacionada con este número de pedido",
          { icon: "warning" }
        );
      });
    }).catch(err =>{
      swal('Upps, no se envio el email!!',{icon:'warning'});
    })
  }).catch(err =>{
    swal('Upps, no se envio el email!!',{icon:'warning'});
  })
  };

  calculateIrpfRental = () => {
    this.baseImponibleRetencion = this.baseImpoRetRef.current.value;
    this.porIrpfRen = this.porRetRef.current.value;
    this.irpf = this.retencionRef.current.value;

    if (this.baseImponibleRetencion !== 0) {
      this.irpf =
        (parseFloat(this.baseImponibleRetencion) *
          parseFloat(this.porIrpfRen)) /
        100;
      this.total_retencion =
        parseFloat(this.baseImponibleRetencion) + parseFloat(this.irpf);

      this.setState({
        factura: {
          retencion_garantia: this.irpf,
          total_retencion_garantia: this.total_retencion,
        },
      });
    }
    axios.get(this.url + "user", this.config).then((res) => {
      if (res.data) {
        axios.get(this.url + "registro", this.config).then((response) => {
          for (var i = 0; i < response.data.length; i++) {
          
            if (res.data.email === response.data[i].email) {
              
              this.setState({
                proveedor: {
                  cif: response.data[i].cif,
                  empresa: response.data[i].nombre_empresa,
                  incidencias: response.data[i].incidencias,
                },
              });
            }
          }
        });
      }
    });
  };
  clearState = () =>{
    this.nº_facturaRef.current.value= '';
    this.fecha_emisionref.current.value= '';
    this.base_imponibleRef.current.value= 0;
    this.ivaPorcentajeRef.current.value= '';
    this.ivaRef.current.value= 0;
    this.base_imponibleRef2.current.value= 0;
    this.ivaPorcentajeRef2.current.value= '';
    this.ivaRef2.current.value= 0;
    this.base_imponibleRef3.current.value= 0;
    this.ivaPorcentajeRef3.current.value= '';
    this.ivaRef3.current.value= 0;
    this.retencionTotalIrpfRef.current.value= 0;
    this.porRetRef.current.value= '';
    this.retencionRef.current.value= '';
    this.retencionTotalIrpfRef.current.value= 0;
    this.retencionGaTlRef.current.value= '';
    this.total_facturaRef.current.value= 0;
    
    this.setState({
      factura: {
        iva: 0,
        iva2:0,
        iva3:0,
        total_factura1: 0,
        total_factura2:0,
        total_factura3:0
      },
    });
  }
  fileChange = (files) => {
    this.selectedFileRef[0] = files[0];
    var fecha = new Date();
    var ano = fecha.getFullYear();


    this.setState({
      form:{
        mounth:'enero',
        name: this.selectedFileRef[0].name, 
      }
    })

    this.setState({
      factura: {
        num_pedido: this.numPedidoRef.current.value,
        año: ano,
        cif: this.cifRef.current.value,
        empresa: this.empresaref.current.value,
        nº_factura: this.nº_facturaRef.current.value,
        cod_proyecto: this.state.pedido.cod_proyecto,
        denominacion: this.state.pedido.denominacion,
        fecha_emision: this.fecha_emisionref.current.value,
        fecha_entrada: "sin fecha",
        forma_pago: this.state.pedido.forma_pago,
        fecha_prevista_pago: "sin fecha",
        fecha_real_pago: "sin fecha",
        base_imponible: this.base_imponibleRef.current.value,
        porcentaje_iva: this.ivaPorcentajeRef.current.value,
        iva: this.ivaRef.current.value,
        base_imponible2: this.base_imponibleRef2.current.value,
        porcentaje_iva2: this.ivaPorcentajeRef2.current.value,
        iva2: this.ivaRef2.current.value,
        base_imponible3: this.base_imponibleRef3.current.value,
        porcentaje_iva3: this.ivaPorcentajeRef3.current.value,
        iva3: this.ivaRef3.current.value,
        base_imponible_retencion: this.retencionTotalIrpfRef.current.value,
        porcentaje_retencion_irpf: this.porRetRef.current.value,
        retencion_irpf: this.retencionRef.current.value,
        total_retencion_irpf: this.retencionTotalIrpfRef.current.value,
        porcentaje_retencion_garantia: this.state.pedido
          .porcentaje_retencion_garantia,
        retencion_garantia: this.state.pedido.retencion_garantia,
        total_retencion_garantia: this.retencionGaTlRef.current.value,
        total_factura1: this.total_facturaRef.current.value,
        total_factura2: this.total_facturaRef.current.value,
        total_factura3: this.total_facturaRef.current.value,
        estado_factura: "pendiente firma jefe obra",
        path_factura: this.selectedFileRef[0].name,
        observaciones: "Sin incidencias",
      },
    });
  };

  render() {

    return (
      <div id="factura">
        <HeaderProveedor
          inicioSesion2="Inicio Sesión"
          misFacturas="Mis Facturas"
          crearFactura="Crear Facturas"
        />

        <Slider title={`${this.state.proveedor.empresa}`} size="slider-small" />
        {this.state.proveedor.incidencias !== "Sin incidencias" ? (
          <React.Fragment>
            <IncidenciaProveedorCapa />
          </React.Fragment>
        ) : (
          <p></p>
        )}
        <div className="center">
          <section id="content">
            <div id="search" className="slidebar-item">
              <form
                className="mid-form-formulario"
                id="formulario"
                encType="multipart/form-data"
                onSubmit={this.crearFactura}
              >
                <div className="form-group"></div>
                <div className="form-group">
                  <p className="subheader">Datos factura</p>
                </div>
                <div className="form-group"></div>
                <div className="form-group">
                  <label htmlFor="numero-pedido">Número de pedido</label>
                  <select
                    id="porcentaje-iva"
                    name="porcentaje-iva"
                    ref={this.numPedidoRef}
                    onChange={this.getOrders}
                  >
                    <option value="null">NP</option>
                    {this.pedidos.map((ped, i) => (
                      <option
                        key={i}
                        value={`${ped.num_pedido}`}
                      >{`${ped.num_pedido}`}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="cif">Cif</label>
                  <input
                    type="text"
                    name="cif"
                    id="cif"
                    ref={this.cifRef}
                    onChange={this.changeStateFactura}
                    value={`${this.state.proveedor.cif}`}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="empresa ">Empresa </label>
                  <input
                    type="text"
                    name="empresa "
                    id="empresa "
                    ref={this.empresaref}
                    onChange={this.changeStateFactura}
                    value={`${this.state.proveedor.empresa}`}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="factura">Nº factura</label>
                  <input
                    type="text"
                    name="factura"
                    id="factura"
                    ref={this.nº_facturaRef}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="fecha_emision">Fecha emisión</label>
                  <input
                    type="text"
                    name="fecha_emision"
                    id="fecha_emision"
                    ref={this.fecha_emisionref}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="base-imponible-retencion">
                    Base imponible retención
                  </label>
                  <input
                    type="text"
                    name="base-imponible-retencion"
                    id="base-imponible-retencion"
                    defaultValue={0}
                    ref={this.baseImpoRetRef}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="total-retencion">
                    Total retención garantia
                  </label>

                  {this.state.pedido.total_retencion_garantia === undefined ? (
                    <input
                      type="text"
                      name="total-retencion"
                      id="total-retencion"
                      value={0}
                      disabled={true}
                      ref={this.retencionGaTlRef}
                    />
                  ) : (
                    <input
                      type="text"
                      name="total-retencion"
                      id="total-retencion"
                      value={this.state.pedido.total_retencion_garantia}
                      disabled={true}
                      ref={this.retencionGaTlRef}
                    />
                  )}
                </div>
                <div className="form-group"></div>
                <div className="form-group">
                  <label htmlFor="porcentaje-irpf">
                    %Retención IRPF/Alquiler
                  </label>
                  <select
                    id="porcentaje-irpf"
                    name="porcentaje-irpf"
                    onChange={this.calculateIrpfRental}
                    ref={this.porRetRef}
                  >
                    <option value="0"></option>
                    <option value="15">15</option>
                    <option value="19">19</option>
                  </select>
                  <label htmlFor="retencion">Retención IRPF/Alquiler</label>
                  <input
                    type="text"
                    name="retencion"
                    id="retencion"
                    value={this.state.factura.retencion_garantia}
                    defaultValue={0}
                    ref={this.retencionRef}
                  />
                  <label htmlFor="total-retencion">
                    Total retención IRPF/Alquiler
                  </label>
                  <input
                    type="text"
                    name="total-retencion"
                    id="total-retencion"
                    value={this.state.factura.total_retencion_garantia}
                    defaultValue={0}
                    ref={this.retencionTotalIrpfRef}
                  />
                </div>

                <div className="form-group"></div>
                <div className="form-group">
                  <p className="subheader">Típos de iva</p>
                </div>
                <div className="form-group"></div>
                <div className="form-group">
                  <label htmlFor="base-imponible">Base imponible</label>

                  <input
                    type="text"
                    name="base-imponible"
                    id="base-imponible"
                    ref={this.base_imponibleRef}
                    defaultValue={0}
                  />

                  <label htmlFor="iva">%Iva</label>
                  <select
                    id="porcentaje-iva"
                    name="porcentaje-iva"
                    ref={this.ivaPorcentajeRef}
                    onChange={this.calculoIva}
                  >
                    <option value="0">Inversión del sujeto pasivo</option>
                    <option value="0">Exención de iva</option>
                    <option value="0">Suplido</option>
                    <option value="4">4</option>
                    <option value="10">10</option>
                    <option value="21">21</option>
                  </select>
                  <label htmlFor="iva">Iva</label>
                  <input
                    type="text"
                    name="iva"
                    id="iva"
                    ref={this.ivaRef}
                    defaultValue={0}
                    value={this.state.factura.iva}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="base-imponible">Base imponible 2</label>
                  <input
                    type="text"
                    name="base-imponible"
                    id="base-imponible"
                    ref={this.base_imponibleRef2}
                    defaultValue={0}
                  />

                  <label htmlFor="iva">%Iva 2</label>
                  <select
                    id="porcentaje-iva"
                    name="porcentaje-iva"
                    ref={this.ivaPorcentajeRef2}
                    onChange={this.calculoIva2}
                  >
                    <option value="0">Inversión del sujeto pasivo</option>
                    <option value="0">Exención de iva</option>
                    <option value="0">Suplido</option>
                    <option value="4">4</option>
                    <option value="10">10</option>
                    <option value="21">21</option>
                  </select>
                  <label htmlFor="iva">Iva 2</label>
                  <input
                    type="text"
                    name="iva"
                    id="iva"
                    ref={this.ivaRef2}
                    defaultValue={0}
                    value={this.state.factura.iva2}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="base-imponible">Base imponible 3</label>
                  <input
                    type="text"
                    name="base-imponible"
                    id="base-imponible"
                    ref={this.base_imponibleRef3}
                    defaultValue={0}
                  />

                  <label htmlFor="iva">%Iva 3</label>
                  <select
                    id="porcentaje-iva"
                    name="porcentaje-iva"
                    ref={this.ivaPorcentajeRef3}
                    onChange={this.calculoIva3}
                  >
                    <option value="0">Inversión del sujeto pasivo</option>
                    <option value="0">Exención de iva</option>
                    <option value="0">Suplido</option>
                    <option value="4">4</option>
                    <option value="10">10</option>
                    <option value="21">21</option>
                  </select>
                  <label htmlFor="iva">Iva 3</label>
                  <input
                    type="text"
                    name="iva"
                    id="iva"
                    ref={this.ivaRef3}
                    defaultValue={0}
                    value={this.state.factura.iva3}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="total-factura">Total factura</label>
                  <input
                    type="text"
                    name="total-factura"
                    id="total-factura"
                    ref={this.total_facturaRef}
                    defaultValue={0}
                    value={this.state.factura.total_factura1}
                  />
                </div>

                <div className="form-group"></div>

                <div className="form-group" id="file">
                  <label htmlFor="file0" id="label-file">
                    Archivo PDF.
                  </label>
                  <InputFiles
                    className="btn btn-success"
                    name="file0"
                    webkitdirectory
                    directory
                    multiple
                    onChange={(files) => this.fileChange(files)}
                  >
                    <input
                      type="submit"
                      name="submit"
                      value="Subir factura"
                      className="btn btn-success"
                    />
                  </InputFiles>
                </div>
                <div className="form-group"></div>
                <div className="form-group">
                  <input
                    type="submit"
                    name="submit"
                    value="Guardar fact."
                    className="btn btn-success"
                  />
                </div>

                <div className="clearfix"></div>
              </form>
            </div>
          </section>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default FacturaProveedor;

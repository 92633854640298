import React from "react";
import Router from "./Router";
import "./assets/css/App.css";


function App() {
  return (
    <div className="App">

      <Router />

      {/**END DIV CENTER */}
  
    </div>
  );
}

export default App;

import React, { Component } from "react";
import logo from "../assets/images/LOGO_SIMA-02 (1).jpg";
import { NavLink } from "react-router-dom";
import Buzon from "./Buzon";

class Header extends Component {
  render() {
    return (
      <header id="header">
        <div className="center">
          {/*LOGO*/}
          <div id="logo">
            <img src={logo} className="app-logo" alt="logotipo" />
          </div>

          {/**MENU*/}
          <nav id="menu">
            <ul>
              {this.props.visibleBU === "true" && (
                <li>
                  <Buzon
                    visible="true"
                    count={this.props.count}
                    content={this.props.content}
                    title={this.props.title}
                  />
                </li>
              )}

              <li>
                <NavLink to="/obras" activeClassName="active">
                  {this.props.proyectos}
                </NavLink>
              </li>
              <li>
                <NavLink to="/home/administracion" activeClassName="active">
                  {this.props.facturas}
                </NavLink>
              </li>

              <li>
                <NavLink to="/pedidos" activeClassName="active">
                  {this.props.pedidos}
                </NavLink>
              </li>
              <li>
                <NavLink to="/proveedor" activeClassName="active">
                  {this.props.calidad}
                </NavLink>
              </li>

              <li>
                <NavLink to="/direccion/gestion" activeClassName="active">
                  {this.props.gestion}
                </NavLink>
              </li>
            </ul>
          </nav>

          {/*LIMPIAR FLOTADOS*/}
          <div className="clearfix"></div>
        </div>
      </header>
    );
  }
}

export default Header;

import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import { Redirect } from "react-router-dom";
import Slider from "../../components/slider";

class RegistroJefeGrupo extends Component {
  //url base para peticiones
  url = Global.url;
  //datos campos registro usuario
  // roleRef = React.createRef();
  roleRef = React.createRef();
  nombreUsuRef = React.createRef();
  emailUsuRef = React.createRef();
  passwordRef = React.createRef();

  state = {
    user: {
        role: '',
        name: '',
        email: '',
        password:'',
      },
    status: null,
  };

  changeStateUser = () => {
    this.setState({
      user: {
        role: this.roleRef.current.value,
        name: this.nombreUsuRef.current.value,
        email: this.emailUsuRef.current.value,
        password: this.passwordRef.current.value,
      },
    });

    this.forceUpdate();
  };

  registerUser = (e) => {
    e.preventDefault();

    //rellenar state con formulario
    this.changeStateUser();

    axios.post(this.url + "registro/usuarios", this.state.user).then((res) => {
      this.setState({
        status: "success",
      });
      
    });
  };
  render() {
    if (this.state.status === "success") {
      return <Redirect to="/home/administracion" />;
    }
    return (
      <div id="jefe_obra">
         <Header           
          
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
          visible="true"
          visibleBU="true"/>
         <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />
        <aside id="slidebar">
          <h1 className="subheader">Usuario jefe de grupo</h1>
          <div id="search" className="slidebar-item">
            <form className="mid-form" onSubmit={this.registerUser}>
              <div className="form-group-login">
                <label htmlFor="role">Role</label>

                <input
                  type="text"
                  name="role"
                  id="role"
                  ref={this.roleRef}
                  className="formulario-input"
                  value="jefe_grupo"
                  onChange={this.changeStateUser}
                />
              </div>
              <div className="form-group-login">
                <label htmlFor="nombre">Nombre</label>

                <input
                  type="text"
                  name="nombre"
                  id="nombre"
                  ref={this.nombreUsuRef}
                  className="formulario-input"
                  placeholder="Nombre"
                  onChange={this.changeStateUser}
                />
              </div>

              <div className="form-group-login">
                <label htmlFor="email">Email</label>

                <input
                  type="text"
                  name="email"
                  id="email"
                  ref={this.emailUsuRef}
                  className="formulario-input"
                  placeholder="Email"
                  onChange={this.changeStateUser}
                />
              </div>

              <div className="form-group-login">
                <label htmlFor="contrasena">Contraseña</label>

                <input
                  type="password"
                  name="contrasena"
                  id="contrasena"
                  ref={this.passwordRef}
                  className="formulario-input"
                  placeholder="password"
                  onChange={this.changeStateUser}
                />
              </div>

              <input
                type="submit"
                name="submit"
                value="crear jefe GP."
                className="btn btn-success"
              />
            </form>
          </div>
        </aside>
      </div>
    );
  }
}

export default RegistroJefeGrupo;
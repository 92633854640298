import React, { Component } from "react";
import Header from "./Header";
import Slider from "./slider";
import axios from "axios";
import Global from "../Global";
import swal from "sweetalert";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ProgressTimer from "./ProgressTimer";
import { Redirect } from "react-router-dom";

class Direccion extends Component {
  //url general peticiones
  url = Global.url;
  state = {
    post: [],
    value: 0,
    remainig: 1,
    status: null,
    length: "",
  };

  adminRef = React.createRef();

  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  componentDidMount() {
    this.getPost();
  }
  getPost = () => {
    axios
      .get(this.url + "user", this.config)
      .then((user) => {
        axios
          .get(this.url + "notificacion/" + user.data.id, this.config)
          .then((res) => {
            if (res.data.length > 0) {
              for (var i = 0; i < res.data.length; i++) {
                var date = new Date();
                var fech = new Date(res.data[i].created_at);
               

               if(fech.toISOString().split('T').splice(0,1) >= date.toISOString().split('T').splice(0,1)){
                this.setState({
                  post: {
                    title: res.data[i].data.title,
                    description: res.data[i].data.description,
                  },
                  length: res.data.length,
                });
               }
 
              }
            } else {
          
              this.setState({
                post: {
                  title: "Sin notificaciones",
                },
              });
            }
          })
          .catch((err) => {
            swal("No tienes notificaciones!!!", { icon: "warning" });
          });
      })
      .catch((err) => {
        swal("No se encontraron notificaciones para este usuario!", {
          icon: "warning",
        });
      });
  };

  TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography ><span  className="subheader">{children}</span></Typography>
          </Box>
        )}
      </div>
    );
  };

  handleChange = (event, newValue) => {
  
    this.setState({
      value: newValue,
    });
  };

  a11yProps = (index) => {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
  };

  LinkTab(props) {
    
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }
  renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return this.appModule();
      //  return  <div className="timer">Enviando</div>
    }

    return (
      <div className="timer">
        <div className="text">Restante</div>
        <div className="value">{remainingTime}</div>
        <div className="text">Segundos</div>
      </div>
    );
  };
  appModule = () => {
    if (this.state.value === 0) {
      return (
        <>
          <Redirect to="/home/administracion" />
        </>
      );
    }
    if (this.state.value === 1) {
      return (
        <>
          <Redirect to="/home/administracion/normal" />
        </>
      );
    }
    if (this.state.value === 2) {
      return (
        <>
          <Redirect to="/proveedor" />
        </>
      );
    }
    if (this.state.value === 3) {
      return (
        <>
          <Redirect to="/jefeObra/pedido" />
        </>
      );
    }
    if (this.state.value === 4) {
      return (
        <>
          <Redirect to="/jefeGrupo/pedido" />
        </>
      );
    }
    if (this.state.value === 5) {
      return (
        <>
          <Redirect to="/home/gestoria" />
        </>
      );
    }
    if (this.state.value === 6) {
      return (
        <>
          <Redirect to="/proveedor/factura" />
        </>
      );
    }
  };

  render() {
    this.TabPanel.prototype = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };

    return (
      <div className="direccion">
        <Header
          buzon="Buzon"
          proyectos="Proyectos"
          facturas="Facturas"
          pedidos="Pedidos"
          calidad="Calidad"
          gestion="Gestion"
          visible="true"
          visibleBU="true"
          count={this.state.length}
          content={this.state.post.description}
        />

        <Slider title="Dirección" size="slider-small" />
        <div className="center">
          <section id="content">
            <p className="subheader">Módulos App</p>
            <div>
              <AppBar position="static">
                <Tabs
                  variant="fullWidth"
                  value={this.state.value}
                  onChange={this.handleChange}
                  aria-label="nav tabs example"
                  className="tab-dire"
                >
                  <this.LinkTab
                    label="Administración res"
                    {...this.a11yProps(0)}
                  />
                  <this.LinkTab
                    label="Administración normal"
                    {...this.a11yProps(1)}
                  />
                  <this.LinkTab label="Calidad" {...this.a11yProps(2)} />
                  <this.LinkTab label="Jefe obra" {...this.a11yProps(3)} />
                  <this.LinkTab label="Jefe grupo" {...this.a11yProps(4)} />
                  <this.LinkTab label="Gestoria" {...this.a11yProps(5)} />
                  <this.LinkTab label="Proveedores" {...this.a11yProps(6)} />
                </Tabs>
              </AppBar>
              <this.TabPanel value={this.state.value} index={0}>
                Administración res
              </this.TabPanel>
              <this.TabPanel value={this.state.value} index={1}>
                Administración normal
              </this.TabPanel>
              <this.TabPanel value={this.state.value} index={2}>
                Calidad
              </this.TabPanel>
              <this.TabPanel value={this.state.value} index={3}>
                Jefe obra
              </this.TabPanel>
              <this.TabPanel value={this.state.value} index={4}>
                Jefe grupo
              </this.TabPanel>
              <this.TabPanel value={this.state.value} index={5}>
                Gestoria
              </this.TabPanel>
              <this.TabPanel value={this.state.value} index={6}>
                Proveedores
              </this.TabPanel>
              <ProgressTimer renderTime={this.renderTime} />

            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Direccion;

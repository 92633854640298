import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import { Redirect } from "react-router-dom";
import Slider from "../../components/slider";
import swal from "sweetalert";
import { grey } from "@material-ui/core/colors";

class ModificarRolesContrasena extends Component {
  //url base para peticiones
  url = Global.url;
  //datos campos registro usuario
  // roleRef = React.createRef();
  roleRef = React.createRef();
  emailUsuRef = React.createRef();
  passwordRef = React.createRef();

  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  state = {
    user: {},
    role: [],
    disable: true,
    status: null,
  };
  componentDidMount() {
    this.getRole();
  }
  changeStateUser = () => {
    this.setState({
      user: {
        role: this.roleRef.current.value,
        email: this.emailUsuRef.current.value,
        password: this.passwordRef.current.value,
      },
    });

    this.forceUpdate();
  };

  updateUser = (e) => {
    e.preventDefault();

    //rellenar state con formulario
    this.changeStateUser();
    axios.get(this.url + 'usuarios',this.config).then(user => {
      for(var i = 0;i<user.data.length; i++){
        if(this.state.user.email === user.data[i].email){
          
          axios.put(this.url + 'actualizar/usuario/' + user.data[i].id,this.state.user,this.config ).then(res => {
            if(res.data){
              swal("Password/role modificado/s con exito!!!",{icon:"success"});
            }
          }).catch(err => {
            swal("No se pudo modificar el rol o la contraseña de el usuario",{icon:'warning'});
          })

        }
      }
    }).catch(err =>{
      swal("No se pudo encontrar el usuario",{icon:'warning'});
    })

  };

  getRole = () => {
    axios
      .get(this.url + "role",this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            role: res.data,
          });
        }
      })
      .catch((err) => {
        swal("No se pudo encontrar el role, intentelo de nuevo!!", {
          icon: "warning",
        });
      });
  };

  visible = (e) => {
    e.preventDefault();
    if (this.state.disable === false) {

      this.setState({
        disable: true,
      });
    } else {
      this.setState({
        disable: false,
      });
    }

  
  };

  render() {
    if (this.state.status === "success") {
      return <Redirect to="/home" />;
    }

    return (
      <div id="usuario">
        <Header
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />
        <aside id="slidebar">
          <h1 className="subheader">Usuario</h1>
          <div id="search" className="slidebar-item">
            <form className="mid-form" >
              <div className="form-group-login">
                <label htmlFor="role">Role</label>
                <select
                  id="role"
                  name="role"
                  ref={this.roleRef}
                  onChange={this.getRole}
                >
                  <option value="null">roles</option>
                  {this.state.role
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((role, i) => (
                      <option value={role.name} key={i}>
                        {role.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group-login">
                <label htmlFor="email">Email</label>

                <input
                  type="text"
                  name="email"
                  id="email"
                  ref={this.emailUsuRef}
                  className="formulario-input"
                  placeholder="Email"
                  onChange={this.changeStateUser}
                />
              </div>

              <div className="form-group-login">
                <label htmlFor="contrasena">Contraseña</label>
                {this.state.disable === true ? (
                  <input
                    type="password"
                    name="contrasena"
                    id="contrasena"
                    defaultValue={null}
                    style={{ background: grey[300] }}
                    disabled={this.state.disable}
                    ref={this.passwordRef}
                    className="formulario-input"
                    placeholder="password"
                    onChange={this.changeStateUser}
                  />
                ) : (
                  <input
                    type="password"
                    name="contrasena"
                    id="contrasena"
                    ref={this.passwordRef}
                    className="formulario-input"
                    placeholder="password"
                    onChange={this.changeStateUser}
                  />
                )}
              </div>
              <div className="block-inline">
                <input
                  type="submit"
                  name="submit"
                  value="mod. role/pass"
                  onClick={this.updateUser}
                  className="btn btn-success"
                />
                <input
                  type="submit"
                  name="submit"
                  value="hab. password"
                  onClick={this.visible}
                  className="btn btn-success"
                />
              </div>
            </form>
          </div>
        </aside>
      </div>
    );
  }
}

export default ModificarRolesContrasena;

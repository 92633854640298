import React, { Component } from "react";
import Header from "../Header";
import Slider from "../slider";
import axios from "axios";
import Global from "../../Global";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import DatosPedido from "./DatosPedido";

class Pedidos extends Component {
  //url general peticiones
  url = Global.url;

  num_pedidoRef = React.createRef();

  path = "https://drive.google.com/file/d/";
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  pedido = [];
  state = {
    pedidos: [],
    entrada: 0,
    page: 0,
    rowsPerPage: 5,
    status: null,
  };

  changeStatePedido = () => {
    this.setState({
      pedido: {
        num_pedido: this.num_pedidoRef.current.value,

      },
    });
  };
  //filtrar pedidos
  getPedidosAdministracion = (e) => {
    e.preventDefault();


    const num_pedido = this.num_pedidoRef.current.value;

    axios.get(this.url + "pedidos",this.config).then((res) => {
      if (res.data) {
        for (var i = 0; i < res.data.length; i++) {
          if (
            res.data[i].num_pedido === num_pedido &&
            res.data[i].estado === "pendiente firma administración"
          ) {
            this.setState({
              pedidos: res.data[i],
              status: "success",
            });
            
          } else {
          }
        }
      }
    }).catch((err) => {
      swal("No se pudo encontrar el pedido!!!", { icon: "warning" });
    });;
  };

  //ver pedidos
  getPedidos = (e) => {
    e.preventDefault();
    axios.get(this.url + "pedidos", this.config)
      .then((res) => {
        if (res.data) {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].estado === "pendiente firma administración") {
              this.pedido[i] = res.data[i];
              this.setState({
                pedidos: res.data,
                status: "failed",
              });
            }
          }
        }
      })
      .catch((err) => {
        swal("No hay pedidos pendientes!!!", { icon: "warning" });
      });
  };

  useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
   
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.pedido.length * this.state.rowsPerPage
    );

    getImageFactura = (e) => {
      this.texto = e.target.dataset.txt;
      
      axios.get(this.url + "pedidos", this.config).then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].path_pedido === this.texto) {
            this.setState({
              proveedores: res.data[i],
              entrada: res.data[i].id,
            });
  
            axios
              .get(this.url + "pedido/imagen/" + res.data[i].id, this.config)
              .then((res) => {
                this.setState({
                  image: res.data,
                });
               
                window.open(this.path + res.data);
              }).catch(err =>{
                swal("Upps, no se encontraron peidos!!" , {icon:'warning'});
              });;
          }
        }
      }).catch(err =>{
        swal("Upps, no se encontraron peidos!!" , {icon:'warning'});
      });;
    };

  render() {
    if (this.state.status === "success") {
      return (
        <>
          <Header
            buzon="Buzon"
            facturas="Facturación"
            proyectos="Proyectos"
            pedidos="Pedidos"
          />

          <Slider
            title="Administración"
            size="slider-small"
            visibleAdmin="true"
          />

          <DatosPedido pedido="true" estado={this.state.pedidos} />
        </>
      );
    }
    return (
      <div id="pedidos">
        <Header
          buzon="Buzón"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
          visible="true"
          visibleBU="true"
        />

        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />
        <h1 className="subheader">Pedidos proveedores</h1>
        <div className="center">
          <section id="content">
            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Número de pedido</TableCell>
                      <TableCell align="right">Cod proyecto</TableCell>
                      <TableCell align="right">Proveedor</TableCell>
                      <TableCell align="right">cif</TableCell>
                      <TableCell align="right">Fecha inicio</TableCell>
                      <TableCell align="right">Fecha fin</TableCell>
                      <TableCell align="right">
                        Retención por garantia
                      </TableCell>
                      <TableCell align="right">Importe pedido</TableCell>
                      <TableCell align="right">Plazo ejecución</TableCell>
                      <TableCell align="right">Plazo pago</TableCell>
                      <TableCell align="right">Forma pago</TableCell>
                      <TableCell align="right">Observaciones</TableCell>
                      <TableCell align="right">Pedido</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.pedido.length >= 1 ? (
                      this.pedido
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        ).sort((a,b) => a.proveedor > b.proveedor ? 1 : -1)
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="right">
                              {row.num_pedido}
                            </TableCell>
                            <TableCell align="right">
                              {row.cod_proyecto}
                            </TableCell>
                            <TableCell align="right">{row.proveedor}</TableCell>
                            <TableCell align="right">{row.cif}</TableCell>
                            <TableCell align="right">
                              {row.fecha_inicio}
                            </TableCell>
                            <TableCell align="right">{row.fecha_fin}</TableCell>

                            <TableCell align="right">
                              {row.total_retencion_garantia}
                            </TableCell>
                            <TableCell align="right">
                              {row.importe_total_real}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_ejecucion}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_pago}
                            </TableCell>

                            <TableCell align="right">
                              {row.forma_pago}
                            </TableCell>
                            <TableCell align="right">
                              {row.observaciones}
                            </TableCell>
                            <TableCell align="right">
                            <input
                                type="submit"
                                name="submit"
                                value=""
                                data-txt={row.path_pedido}
                                onClick={this.getImageFactura}
                                className="btn-table"
                              />
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                     null
                    )}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={13} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 5, 10]}
                  count={
                    this.state.pedidos.length > 1
                      ? this.state.pedidos.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
            <div className="clearfix"></div>
            <p className="subheader">Buscar pedido</p>
            <form
              className="mid-form-formulario"
              onSubmit={this.getPedidosAdministracion}
            >
              <div className="form-group">
                <label htmlFor="numero-pedido">Número de pedido</label>
                <select
                  id="numero-pedido"
                  name="numero-pedido"
                  ref={this.num_pedidoRef}
                  onChange={this.changeStatePedido}
                >
                  {this.pedido.map((option) => (
                    <option
                      key={`${option.id}`}
                      value={`${option.num_pedido}`}
                    >{`${option.num_pedido}`}</option>
                  ))}
                </select>
              </div>

              <div className="form-group"></div>
              <div className="form-group"></div>
              <input
                type="submit"
                name="submit"
                value="buscar pedido."
                className="btn btn-success"
              />

              <input
                type="submit"
                name="submit"
                value="Refrescar"
                className="btn btn-success"
                onClick={this.getPedidos}
              />
            </form>
          </section>
        </div>
      </div>
    );
  }
}

export default Pedidos;

import React, { Component } from "react";
import Header from "../../components/Header";
import Slider from "../../components/slider";
import TablaJefeObra from "../tablas/TablaJefeObra";
import Global from "../../Global";



class ConsultaJefeObra extends Component {
  //url base API
  url = Global.url;

  render() {
    return (
      <div id="jefe-obra">
        <Header
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
          visible="true"
          visibleBU="true"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />
        <TablaJefeObra  visible="false"/>
      </div>
    );
  }
}

export default ConsultaJefeObra;

import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import Slider from "../../components/slider";
import swal from "sweetalert";
import TablaProveedor from "../tablas/TablaProveedor";


class BajaProveedor extends Component {
  //url base API
  url = Global.url;

  supplierRef = React.createRef();
  emailRef = React.createRef();
  nameRef = React.createRef();

  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  supplier  = [];
  state = {
    proveedores: [],
    proveedor: [],
    status: null,
  };
  componentDidMount() {
    this.getSupplier();
  }
  deleteSupplier = (e) => {
    e.preventDefault();
    const nombre = this.nameRef.current.value;
    const nombre_empresa = this.supplierRef.current.value;
    const email = this.emailRef.current.value;
    var id = 0;
    axios
      .get(this.url + "registro", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          
          if (
            nombre === res.data[i].cif &&
            nombre_empresa === res.data[i].nombre_empresa &&
            email === res.data[i].email
          ) {
           
            id = res.data[i].id;

            
          }
        }
      })
      .catch((err) => {
        swal("No se pudo encontrar al proveedor!!!", { icon: "warning" });
      });

    swal({
      title: "¿Estas seguro/a que quieres eliminar a este usuario?",
      text: "El proveedor sera dado de baja!!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
        
          axios.get(this.url + "usuarios", this.config).then((user) => {
            for (var i = 0; i < user.data.length; i++) {
              if (user.data[i].email === email) {
                axios
                  .delete(this.url + "eliminar/usuario/" + user.data[i].id, this.config)
                  .then((response) => {
                  
                      swal("Usuario eliminado correctamente!!!", {
                        icon: "success",
                      });
                    
                  })
                  .catch((err) => {
                    swal("No se pudo eliminar el usuario!!!", {
                      icon: "warning",
                    });
                  });
              }
            }
          });
          axios
            .put(this.url + "registro/actualizar/" + id,{incidencias:'BAJA'}, this.config)
            .then((response) => {
            
                swal("Proveedor eliminado correctamente!!!", {
                  icon: "success",
                });
              
            })
            .catch((err) => {
              swal("No se pudo eliminar al proveedor!!!", { icon: "warning" });
            });
        }
      })
      .catch((err) => {
        swal("No se pudo eliminar al proveedor!!!", { icon: "warning" });
      });
  };

  getSupplier = () => {
    axios
      .get(this.url + "registro", this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            proveedores: res.data,
          });
        }
      })
      .catch((err) => {
        swal("No se encontro ningún proveedor!!!", { icon: "warning" });
      });
  };
  render() {
    return (
      <div className="administracion">
        <Header
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />
        <TablaProveedor incidencia = "false"/>
        <div className="center">
          <section id="content">
            <div id="search" className="slidebar-item">
              <form className="mid-form-formulario">
            
                <div className="form-group">
                  <label htmlFor="proveedor">Proveedores</label>

                  <select
                    id="proveedor"
                    name="proveedor"
                    ref={this.supplierRef}
                  >
                    {this.state.proveedores
                      .sort((a, b) => a.nombre_empresa > b.nombre_empresa ? 1: -1)
                      .map((row, i) => (
                        <option key={i} value={row.nombre_empresa}>
                          {row.nombre_empresa}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="proveedor">Cif</label>
                  <select id="proveedor" name="proveedor" ref={this.nameRef}>
                    {this.state.proveedores
                      .sort((a, b) => a.cif > b.cif ? 1 : -1)
                      .map((row, i) => (
                        <option key={i} value={row.cif}>
                          {row.cif}
                        </option>
                      ))}
                  </select>
                </div>
                
                <div className="form-group">
                  <label htmlFor="proveedor">Email</label>
                  <select id="proveedor" name="proveedor" ref={this.emailRef}>
                    {this.state.proveedores
                      .sort((a, b) => a.email > b.email ? 1:-1)
                      .map((row, i) => (
                        <option key={i} value={row.email}>
                          {row.email}
                        </option>
                      ))}
                  </select>
                </div>


                <input
                  type="submit"
                  name="submit"
                  value="Eliminar PV."
                  onClick={this.deleteSupplier}
                  className="btn btn-success"
                />
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default BajaProveedor;

import React, { Component } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/images/LOGO_SIMA-02 (1).jpg";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";

class MenuCalidad extends Component {
  //url base
  url = Global.url;
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  state = {
    visible: false,
    role: "",
  };
  role = () => {
    axios
      .get(this.url + "user", this.config)
      .then((user) => {
        axios
          .post(
            this.url + "login",
            { email: user.data.email, password: "1234" },
            this.config
          )
          .then((res) => {
            if (res.data) {
              this.setState({
                role: res.data[1][0].name,
              });
            }
          })
          .catch((err) => {
            swal("Error de autenticación de usuario!!", { icon: "warning" });
          });
      })
      .catch((err) => {
        swal("No se pudo encontrar el usuario", { icon: "warning" });
      });
  };
  openDrawer = () => {
    if (this.state.visible === false) {
      this.setState({
        visible: true,
      });
      this.role();
    } else {
      this.setState({
        visible: false,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.visible === true ? (
          <div className="drawer">
            <div className="sidenav">
              <header id="header">
                <div className="center">
                  {/*LOGO*/}
                  <div id="logo-drawer">
                    <img src={logo} className="app-logo" alt="logotipo" />
                  </div>
                </div>
              </header>
              {/**MENU*/}
              <MenuIcon
                onClick={this.openDrawer}
                className="btn-sidenav"
                style={{ float: "right" }}
              />
              <p>Calidad y PRL</p>
              <nav class="navigation">
                <ul class="mainmenu">
                {this.state.role === "direccion" && (
                    <li>
                      <NavLink to="/direccion">Dirección</NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink to="/proveedor">Gestón proveedores</NavLink>
                    <ul class="submenu">
                      <li>
                        <NavLink to="/proveedor" activeClassName="active">
                          {" "}
                          Supervisar proveedores
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink to="/calidad/pedidos">Gestión pedidos</NavLink>
                    <ul class="submenu">
                      <li>
                        <NavLink to="/calidad/pedidos" activeClassName="active">
                          {" "}
                          Pedidos activos
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        ) : (
          <>
            <MenuIcon
              onClick={this.openDrawer}
              className="btn-drawer"
              style={{ float: "left" }}
            />
            <p className="submenu-p">Menu</p>
          </>
        )}
      </>
    );
  }
}

export default MenuCalidad;

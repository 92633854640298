import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import { Redirect } from "react-router-dom";
import Slider from "../../components/slider";
import swal from "sweetalert";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

class BajaObras extends Component {
  //url base API
  url = Global.url;
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  state = {
    obras: {},
    page: 0,
    rowsPerPage: 3,
    status: null,
  };

  getPlays = (e) => {
    e.preventDefault();

    //peticion AJAX
    axios
      .get(this.url + "obras", this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            obras: res.data,
          });
        }
      })
      .catch((err) => {
        swal("No hay obras que mostrar!!!", {
          icon: "warning",
        });
      });


  };

  getIdPlays = (e) => {
    const id = e.target.dataset.txt;
    axios
      .get(this.url + "obra/" + id, this.config)
      .then((res) => {
        this.setState({
          obras: res.data,
        });
      })
      .catch((err) => {
        swal(
          "Upps hubo ocurrio algún error en la busqueda, intentelo de nuevo!!!",
          {
            icon: "warning",
          }
        );
      });

  };
  deletePlays = (e) => {
    e.preventDefault();
    var id = this.state.obras.id;
    swal({
      title: "¿Estas seguro/a que quieres eliminar esta obra?",
      text: "La obra se dara de baja!!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        axios
        .put(this.url + "actualizar/obra/" + id, {estado:'BAJA'},this.config)
        .then((res) => {
          this.setState({
            status: "success",
          });
          swal("Obra dada de baja correctamente!!", {
            icon: "success",
          });
        })
        .catch((err) => {
          swal("No se pudo eliminar la obra, intentelo de nuevo!!!", {
            icon: "warning",
          });
        });

      } else {
        swal("Tranquilo/a la obra no fue borrada!!");
      }
    });

  };
  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
    
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.obras.length * this.state.rowsPerPage
    );

  render() {
    if (this.state.status === "success") {
      return <Redirect to="/home/administracion" />;
    }
    return (
      <div id="jefe-obra">
        <Header
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />

        <div className="center">
          <section id="content">
            <h1 className="subheader">Datos obras</h1>

            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Cod proyecto</TableCell>
                      <TableCell align="left">Denominación</TableCell>
                      <TableCell align="left">Descripción</TableCell>
                      <TableCell align="left">Observaciones</TableCell>
                      <TableCell align="left">Estado</TableCell>
                      <TableCell align="left">Ver obra</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.obras.length >= 0
                      ? this.state.obras
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left">
                                {row.cod_proyecto}
                              </TableCell>
                              <TableCell align="left">
                                {row.denominacion}
                              </TableCell>
                              <TableCell align="left">
                                {row.descripcion}
                              </TableCell>
                              <TableCell align="left">
                                {row.observaciones}
                              </TableCell>
                              <TableCell align="left">
                                {row.estado}
                              </TableCell>
                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value="Ver obra"
                                  data-txt={row.id}
                                  onClick={this.getIdPlays}
                                  className="btn-table2"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                      : null}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 3, 10]}
                  count={
                    this.state.obras.length >= 0
                      ? this.state.obras.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
            <div id="search" className="slidebar-item">
              <form className="mid-form-formulario">


                <div className="form-group">
                  <label htmlFor="cod_proyecto">
                    Cod proyecto:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.obras.cod_proyecto}
                    </p>
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="denominacion">
                    Denominación:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.obras.denominacion}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="descripcion">
                    Descripción:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.obras.denominacion}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="observaciones">
                    Observaciones:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.obras.denominacion}
                    </p>
                  </label>

                </div>
                <div className="form-group">
                  <label htmlFor="observaciones">
                    Estado:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.obras.estado}
                    </p>
                  </label>

                </div>
               
                <div className="form-group"></div>
                <input
                  type="submit"
                  value="Eliminar obra."
                  onClick={this.deletePlays}
                  className="btn btn-success"
                />
                <input
                  type="submit"
                  value="refrescar"
                  onClick={this.getPlays}
                  className="btn btn-success"
                />
              </form>
            </div>
            
          </section>

          <div className="clearfix"></div>
          <br />
        </div>
      </div>
    );
  }
}

export default BajaObras;
import React, { Component } from "react";
import Header from "./Header";
import Slider from "./slider";
import axios from "axios";
import Global from "../Global";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";

class AprobacionFacturas extends Component{
     //url general peticiones
  url = Global.url;
  path = "https://drive.google.com/file/d/";
  texto = "";
  numPedido = "";
  id = 0;
  factura = [];
  pedido = [];
  totalFactura = 0;
  importTotPed = "";
  fechaPrevista = "";
  fechaReal ='';



  fechRef = React.createRef();


  state = {
    facturas: [],
    pedido: [],
    email: [],
    fecha_prevista:'',
    fecha_real:'',
    isAuthenticated: true,
    entrada: 0,
    image: "",
    page: 0,
    rowsPerPage: 3,
    status: null,
  };

  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };


  getImageBills = (e) => {
    this.texto = e.target.dataset.txt;
    axios
      .get(this.url + "facturas", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].path_factura === this.texto) {
            this.setState({
              facturas: res.data[i],
              entrada: res.data[i].id,
            });

            axios
              .get(this.url + "factura/imagen/" + res.data[i].id, this.config)
              .then((res) => {
                this.setState({
                  image: res.data,
                });

                window.open(this.path + res.data);
              })
              .catch((err) => {
                swal("Upps, no se encontraron facturas!!", { icon: "warning" });
              });
          }
        }
      })
      .catch((err) => {
        swal("Upps, no se encontraron facturas!!", { icon: "warning" });
      });
  };

  getBillDate = (e) => {
    this.numPedido = e.target.dataset.txtx;
    this.id = e.target.dataset.id;
    this.fechaPrevista=e.target.dataset.fecha_prevista;
    this.fechaReal = e.target.dataset.fecha_real;
    this.setState({
      facturas:{
        num_pedido:this.numPedido,
        fecha_prevista_pago:this.fechaPrevista,
        fecha_real_pago:this.fechaReal,
      }
    })
   
  };

  //lista de facturas de proveedores
  getBills = (e) => {
    e.preventDefault();
    axios.get(this.url + "pedidos", this.config).then((order) => {
      axios
        .get(this.url + "facturas", this.config)
        .then((res) => {
          this.factura = [];
          for (var x = 0; x < order.data.length; x++) {
            for (var i = 0; i < res.data.length; i++) {
              if (
                res.data[i].estado_factura === "contabilizada" &&
                order.data[x].num_pedido === res.data[i].num_pedido
              ) {
           
               this.factura[i] = res.data[i];
               this.setState({
                 
                 facturas: this.factura,
               })
          

              }
            }
          }
          
        })
        .catch((err) => {

          if (401) {
            swal("Tiempo de sesión excedido, logueese de nuevo, por favor!! ", {
              icon: "info",
            });
            this.setState({
              isAuthenticated: false,
            });
          } else {
            swal("Upps, no se encontraron facturas!! ", { icon: "warning" });
          }
          
        });
    });
  };
  getBillsAcepted = (e) =>{
    e.preventDefault();
    axios.get(this.url + "pedidos", this.config).then((order) => {
      axios
        .get(this.url + "facturas", this.config)
        .then((res) => {
          this.factura = [];
          for (var x = 0; x < order.data.length; x++) {
            for (var i = 0; i < res.data.length; i++) {
              if (
                res.data[i].estado_factura === "facturada" &&
                order.data[x].num_pedido === res.data[i].num_pedido
              ) {
               
               this.factura[i] = res.data[i];
               this.setState({
                 
                 facturas: this.factura,
               })
          

              }
            }
          
          }
        })
        .catch((err) => {

          if (401) {
            swal("Tiempo de sesión excedido, logueese de nuevo, por favor!! ", {
              icon: "info",
            });
            this.setState({
              isAuthenticated: false,
            });
          } else {
            swal("Upps, no se encontraron facturas!! ", { icon: "warning" });
          }
          
        });
    });
  }
  updateOrder = (e) =>{
    e.preventDefault();
  axios.put(this.url + 'factura/actualizada/' + this.id , {fecha_real_pago:this.fechRef.current.value,estado_factura:'facturada'}, this.config).then(order =>{

    if(order.data){
      swal('Factura aprobada!!',{icon:'success'});
    }
    this.fechRef.current.value = '';
  }).catch(err =>{
    swal('No se pudo aprobar la factura!!',{icon:'warning'});
  }) 
  }



  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.facturas.length * this.state.rowsPerPage
    );
    render(){
        if (!this.state.isAuthenticated) return <Redirect to="/home" />;
        return(
            <div className="Gestion">
            <Header
              buzon="Buzon"
              proyectos="Proyectos"
              facturas="Facturas"
              pedidos="Pedidos"
              calidad="Calidad"
              gestion="Gestion"
              visible="true"
              visibleBU="true"
            />
             <Slider title="Dirección" size="slider-small" />
             <div className="center">
          <section id="content">
            <h1 className="subheader">Facturas proveedores</h1>

            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Entrada</TableCell>
                      <TableCell align="left">Cif</TableCell>
                      <TableCell align="left">Proveedor</TableCell>
                      <TableCell align="left">Nº factura</TableCell>
                      <TableCell align="left">Fecha emisión</TableCell>
                      <TableCell align="left">Fecha entrada</TableCell>
                      <TableCell align="left">Total factura</TableCell>
                      <TableCell align="left">Forma pago</TableCell>
                      <TableCell align="left">Fecha prevista pago</TableCell>
                      <TableCell align="left">Fecha real pago</TableCell>
                      <TableCell align="left">Cod Proyecto</TableCell>
                      <TableCell align="left">Denominación</TableCell>
                      <TableCell align="left">Pedido</TableCell>
                      <TableCell align="left">Factura</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.facturas.length >= 0
                      ? this.state.facturas
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left" >{row.id}</TableCell>
                              <TableCell align="left">{row.cif}</TableCell>
                              <TableCell align="left">{row.empresa}</TableCell>
                              <TableCell align="left">
                                {row.nº_factura}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_emision}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_entrada}
                              </TableCell>

                              <TableCell align="left">
                                {row.total_factura1}
                              </TableCell>
                              <TableCell align="left">
                                {row.forma_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_prevista_pago}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_real_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.cod_proyecto}
                              </TableCell>
                              <TableCell align="left">
                                {row.denominacion}
                              </TableCell>

                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value={row.num_pedido}
                                  data-txtx={row.num_pedido}
                                  data-id={row.id}
                                  data-fecha_real={row.fecha_real_pago}
                                  data-fecha_prevista={row.fecha_prevista_pago}
                                  onClick={this.getBillDate}
                                  className="btn-table2"
                                />
                              </TableCell>
                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value=""
                                  data-txt={row.path_factura}
                                  onClick={this.getImageBills}
                                  className="btn-table"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                      : null}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={14} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 3, 10]}
                  count={
                    this.state.facturas.length >= 0
                      ? this.state.facturas.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
          </section>
          <div id="search" className="slidebar-item">
            <form className="mid-form-formulario" id="formulario">
              <div className="form-group"></div>
              <div className="form-group">
                <p className="subheader">Aprobar factura</p>
              </div>
              <div className="form-group"></div>
              <div className="form-group">
                <label htmlFor="pedido">
                  Número pedido:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.facturas.num_pedido}</p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="pedido">
                  Fecha prevista:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.facturas.fecha_prevista_pago}</p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="pedido">
                Fecha real:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.facturas.fecha_real_pago}</p>
                </label>
              </div>

              <div className="form-group">
           
              </div>
              <div className="form-group"></div>
              <div className="form-group"></div>


              <div className="form-group">
                <label htmlFor="direccion">Fecha pago</label>
                <input
                  type="text"
                  name="direccion"
                  id="direccion"
                  ref={this.fechRef}
                />
              </div>
              <div className="form-group"></div>
              <div className="form-group"></div>
              
              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="Aprobar fact."
                  onClick={this.updateOrder}
                  className="btn btn-success"
                />
              </div>
              
              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="ver fact apro."
                  onClick={this.getBillsAcepted}
                  className="btn btn-success"
                />
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="refrescar fac"
                  onClick={this.getBills}
                  className="btn btn-success"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
             
        );
    }
}

export default AprobacionFacturas;
import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import InputFiles from "react-input-files";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";

class FacturaAdministracion extends Component {
  //url base
  url = Global.url;
  //array de atributos para archivo subido
  selectedFileRef = [];

  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
   fecha = new Date();
  //datos campos factura
  ivaporcentaje = "%iva";
  numPedidoRef = React.createRef();
  cifRef = React.createRef();
  empresaref = React.createRef();
  nº_facturaRef = React.createRef();
  codRef = React.createRef();
  denoRef = React.createRef();
  fecha_entradaRef = React.createRef();
  formPagoRef = React.createRef();
  porRetRef = React.createRef();
  retencionRef = React.createRef();
  retencionTotalIrpfRef = React.createRef();
  baseImpoRetRef = React.createRef();
  base_imponibleRef = React.createRef();
  ivaPorcentajeRef = React.createRef();
  ivaRef = React.createRef();
  base_imponibleRef2 = React.createRef();
  ivaPorcentajeRef2 = React.createRef();
  ivaRef2 = React.createRef();
  base_imponibleRef3 = React.createRef();
  ivaPorcentajeRef3 = React.createRef();
  ivaRef3 = React.createRef();
  total_facturaRef = React.createRef();

  //variables calculo
  baseImponible = 0;
  baseImponible2 = 0;
  baseImponible3 = 0;
  porIva = 0;
  porIva2 = 0;
  porIva3 = 0;
  iva = 0;
  iva2 = 0;
  iva3 = 0;
  total = 0;

  rol = '';
  name = '';
  email = '';
  user_id = '';

  //variables de proveedor

  state = {
    factura: {},
    form:[],
    status: null,
  };

  createNotification = () =>{

    axios.get(this.url + 'role',this.config).then(role => {
      role.data.forEach(element => {
       
          if(element.name === 'direccion'){
            this.rol = element.id;
          }
        
      });
      axios.get(this.url + 'role/' + this.rol,this.config).then(user =>{
        user.data.forEach(element => {
        this.name = element.name;
        this.email = element.email;
        this.user_id = element.id;
        });

        axios
        .post(
          this.url + "crear/notificacion/" + this.user_id,
          {title:this.name, description:'Aprobación factura para ' + this.email, user_id:this.user_id},
          this.config
        )
        .then((res) => {
          if (res.data) {
            swal(res.data, { icon: "success" });
          }
        })
        .catch((err) => {
          swal("No se pudo enviar notificacion!!", { icon: "warning" });
        });

      }).catch(err =>{
        swal('Upps, no se creo la notificaión!!',{icon:'warning'});
      })
    }).catch(err =>{
      swal('Upps, no se creo la notificaión!!',{icon:'warning'});
    })
  }

  calculoIva = () => {
    this.baseImponible = this.base_imponibleRef.current.value;

    this.porIva = this.ivaPorcentajeRef.current.value;

    this.iva = this.ivaRef.current.value;
    this.iva2 = this.ivaRef2.current.value;
    this.iva3 = this.ivaRef3.current.value;

    if (this.baseImponible !== 0) {
      this.iva =
        (parseFloat(this.baseImponible) * parseFloat(this.porIva)) / 100;

      this.total =
        parseFloat(this.baseImponible) +
        parseFloat(this.iva) +
        parseFloat(this.iva2) +
        parseFloat(this.iva3);

      this.setState({
        factura: {
          iva: this.iva,
          total_factura: this.total,
        },
      });
    }
  };

  calculoIva2 = () => {
    this.baseImponible2 = this.base_imponibleRef2.current.value;
    this.porIva2 = this.ivaPorcentajeRef2.current.value;
    this.iva = this.ivaRef.current.value;
    this.iva2 = this.ivaRef2.current.value;
    this.iva3 = this.ivaRef3.current.value;

    if (this.baseImponible2 !== 0) {
      this.iva2 =
        (parseFloat(this.baseImponible2) * parseFloat(this.porIva2)) / 100;

      this.total =
        parseFloat(this.baseImponible2) +
        parseFloat(this.iva2) +
        parseFloat(this.iva) +
        parseFloat(this.iva3);

      this.setState({
        factura: {
          iva2: this.iva2,
          total_factura: this.total,
        },
      });
    }
  };

  calculoIva3 = () => {
    this.baseImponible3 = this.base_imponibleRef3.current.value;
    this.porIva3 = this.ivaPorcentajeRef3.current.value;
    this.iva = this.ivaRef.current.value;
    this.iva2 = this.ivaRef2.current.value;
    this.iva3 = this.ivaRef3.current.value;

    if (this.baseImponible3 !== 0) {
      this.iva3 =
        (parseFloat(this.baseImponible3) * parseFloat(this.porIva3)) / 100;

      this.total =
        parseFloat(this.baseImponible3) +
        parseFloat(this.iva3) +
        parseFloat(this.iva2) +
        parseFloat(this.iva);

      this.setState({
        factura: {
          iva3: this.iva3,
          total_factura: this.total,
        },
      });
    }
  };
  calculateIrpfRental = () => {
    this.baseImponibleRetencion = this.baseImpoRetRef.current.value;
    this.porIrpfRen = this.porRetRef.current.value;
    this.irpf = this.retencionRef.current.value;

    if (this.baseImponibleRetencion !== 0) {
      this.irpf =
        (parseFloat(this.baseImponibleRetencion) *
          parseFloat(this.porIrpfRen)) /
        100;
      this.total_retencion =
        parseFloat(this.baseImponibleRetencion) + parseFloat(this.irpf);

      this.setState({
        factura: {
          retencion_garantia: this.irpf,
          total_retencion_garantia: this.total_retencion,
        },
      });
    }
  };
  changeStateFactura = () => {
    
    var ano = this.fecha.getFullYear();
    var fech = [];
    fech[0]= this.fecha.toISOString().split('T').splice(0,1).toString();
    this.setState({
      factura: {
        num_pedido: "NP",
        año: ano,
        cif: this.cifRef.current.value,
        empresa: this.empresaref.current.value,
        nº_factura: this.nº_facturaRef.current.value,
        cod_proyecto: this.codRef.current.value,
        denominacion: this.denoRef.current.value,
        fecha_emision: this.fecha.toLocaleDateString(),
        fecha_entrada: fech[0],
        forma_pago: this.formPagoRef.current.value,
        fecha_prevista_pago: this.fecha_entradaRef.current.value,
        fecha_real_pago: "Sin fecha",
        base_imponible: this.base_imponibleRef.current.value,
        porcentaje_iva: this.ivaPorcentajeRef.current.value,
        iva: this.ivaRef.current.value,
        base_imponible2: this.base_imponibleRef2.current.value,
        porcentaje_iva2: this.ivaPorcentajeRef2.current.value,
        iva2: this.ivaRef2.current.value,
        base_imponible3: this.base_imponibleRef3.current.value,
        porcentaje_iva3: this.ivaPorcentajeRef3.current.value,
        iva3: this.ivaRef3.current.value,
        base_imponible_retencion: this.baseImpoRetRef.current.value,
        porcentaje_retencion_irpf: this.porRetRef.current.value,
        retencion_irpf: this.retencionRef.current.value,
        total_retencion_irpf: this.retencionTotalIrpfRef.current.value,
        porcentaje_retencion_garantia: 0,
        retencion_garantia: 0,
        total_retencion_garantia: 0,
        total_factura1: this.total_facturaRef.current.value,
        total_factura2: this.total_facturaRef.current.value,
        total_factura3: this.total_facturaRef.current.value,
        estado_factura: "contabilizada",
        path_factura: "",
        observaciones: "Sin incidencias",
      },
    });
  };

  //peticion para crear factura
  crearFactura = (e) => {
    e.preventDefault();

    //rellenar state con formulario
    this.changeStateFactura();

    //guardamos archivo factura
    this.saveFactura();

    axios
      .post(this.url + "crear/factura", this.state.factura, this.config)
      .then((res) => {
        this.setState({
          factura: res.data,
          status: "success",
        });
        swal("ok! Tu factura se registro exitosamente!", {
          icon: "success",
        });
        this.createNotification();
      })
      .catch((err) => {
        swal("Hubo algún problema al guardar tu factura, intentelo de nuevo.", {
          icon: "warning",
        });
      });
  };
  saveFactura = () => {
   //año actual
   var fecha = new Date();
   var ano = fecha.getFullYear();
   //crear formdata y añadir fichero
   const formData = new FormData();
   formData.append(
     "file0",
     this.selectedFileRef[0],
     this.selectedFileRef[0].name,
     this.state.form.mounth
   );

   var number = fecha.getMonth() + parseInt(1);

   axios.post(this.url + 'storage/create/subdir/bills/' + ano, {mounth:number},this.config).then(subdir =>{
     if(subdir.data){
      swal("Nuevo directorio creado.", {
        icon: "success",
      });
     }
   })

   axios
     .post(this.url + "storage/create/file/bill/" + number + '/' + ano,  formData , this.config)
     .then((res) => {
      
     })
     .catch((err) => {
       swal("Hubo algún problema al crear tu factura, intentelo de nuevo.", {
         icon: "warning",
       });
     });
  };

  fileChange = (files) => {
    this.selectedFileRef[0] = files[0];
    var ano = this.fecha.getFullYear();
    var fech = [];
    fech[0]= this.fecha.toISOString().split('T').splice(0,1).toString();
    this.setState({
      factura: {
        num_pedido: "NP",
        año: ano,
        cif: this.cifRef.current.value,
        empresa: this.empresaref.current.value,
        nº_factura: this.nº_facturaRef.current.value,
        cod_proyecto: this.codRef.current.value,
        denominacion: this.denoRef.current.value,
        fecha_emision: this.fecha.toLocaleDateString(),
        fecha_entrada: fech[0],
        forma_pago: this.formPagoRef.current.value,
        fecha_prevista_pago: this.fecha_entradaRef.current.value,
        fecha_real_pago: "Sin fecha",
        base_imponible: this.base_imponibleRef.current.value,
        porcentaje_iva: this.ivaPorcentajeRef.current.value,
        iva: this.ivaRef.current.value,
        base_imponible2: this.base_imponibleRef2.current.value,
        porcentaje_iva2: this.ivaPorcentajeRef2.current.value,
        iva2: this.ivaRef2.current.value,
        base_imponible3: this.base_imponibleRef3.current.value,
        porcentaje_iva3: this.ivaPorcentajeRef3.current.value,
        iva3: this.ivaRef3.current.value,
        base_imponible_retencion: this.baseImpoRetRef.current.value,
        porcentaje_retencion_irpf: this.porRetRef.current.value,
        retencion_irpf: this.retencionRef.current.value,
        total_retencion_irpf: this.retencionTotalIrpfRef.current.value,
        porcentaje_retencion_garantia: 0,
        retencion_garantia: 0,
        total_retencion_garantia: 0,
        total_factura1: this.total_facturaRef.current.value,
        total_factura2: this.total_facturaRef.current.value,
        total_factura3: this.total_facturaRef.current.value,
        estado_factura: "contabilizada",
        path_factura: this.selectedFileRef[0].name,
        observaciones: "Sin incidencias",
      },
    });

  };
  
  render() {
    if (this.state.status === "success") {
      return <Redirect to="/home/administracion" />;
    }
    return (
      <div id="factura">

        <div className="center">
          <section id="content">
            <div id="search" className="slidebar-item">
              <form
                className="mid-form-formulario"
                id="formulario"
                encType="multipart/form-data"
                onSubmit={this.crearFactura}
              >
                <div className="form-group"></div>
                <div className="form-group">
                  <p className="subheader">Datos factura</p>
                </div>
                <div className="form-group"></div>
                <div className="form-group">
                  <label htmlFor="empresa ">Proveedor </label>
                  <input
                    type="text"
                    name="empresa "
                    id="empresa "
                    ref={this.empresaref}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cif">Cif</label>
                  <input type="text" name="cif" id="cif" ref={this.cifRef} />
                </div>
                <div className="form-group">
                  <label htmlFor="factura">Nº factura</label>
                  <input
                    type="text"
                    name="factura"
                    id="factura"
                    ref={this.nº_facturaRef}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cod_proyecto">Cod proyecto</label>
                  <input
                    type="text"
                    name="cod_proyecto"
                    id="cod_proyecto"
                    defaultValue="COD"
                    ref={this.codRef}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="deno">Denominación</label>
                  <input
                    type="text"
                    name="deno"
                    id="deno"
                    defaultValue="DEN"
                    ref={this.denoRef}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="fecha-prevista">Fecha prevista pago</label>
                  <input
                    type="text"
                    name="fecha-prevista"
                    id="fecha-prevista"
                    ref={this.fecha_entradaRef}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="base-imponible-retencion">
                    Base imponible retención
                  </label>
                  <input
                    type="text"
                    name="base-imponible-retencion"
                    id="base-imponible-retencion"
                    defaultValue={0}
                    ref={this.baseImpoRetRef}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="porcentaje-irpf">
                    %Retención IRPF/Alquiler
                  </label>
                  <select
                    id="porcentaje-irpf"
                    name="porcentaje-irpf"
                    onChange={this.calculateIrpfRental}
                    ref={this.porRetRef}
                  >
                    <option value="0"></option>
                    <option value="15">15</option>
                    <option value="19">19</option>
                  </select>
                </div>
                <div className="form-group">
                  {" "}
                  <label htmlFor="retencion">Retención IRPF/Alquiler</label>
                  <input
                    type="text"
                    name="retencion"
                    id="retencion"
                    defaultValue={0}
                    value={this.state.factura.retencion_garantia}
                    ref={this.retencionRef}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="total-retencion">
                    Total retención IRPF/Alquiler
                  </label>
                  <input
                    type="text"
                    name="total-retencion"
                    id="total-retencion"
                    defaultValue={0}
                    value={this.state.factura.total_retencion_garantia}
                    ref={this.retencionTotalIrpfRef}
                  />
                </div>

                <div className="form-group"></div>

                <div className="form-group">
                  {" "}
                  <label htmlFor="forma-pago">Forma de pago</label>
                  <select
                    id="forma-pago"
                    name="forma-pago"
                    style={{ width: `${100}%` }}
                    ref={this.formPagoRef}
                    onChange={this.changeStateFactura}
                  >
                    <option value="null"></option>
                    <option value="efectivo">efectivo</option>
                    <option value="pagaré/cheque">pagaré/cheque</option>
                    <option value="transferencia">transferencia</option>
                    <option value="vencimiento">vencimiento</option>
                    <option value="confirming">confirming</option>
                    <option value="tarjeta">tarjeta</option>
                  </select>
                </div>
                <div className="form-group"></div>
                <div className="form-group"></div>
                <div className="form-group"></div>
                <div className="form-group">
                  <label htmlFor="base-imponible">Base imponible</label>
                  <input
                    type="text"
                    name="base-imponible"
                    id="base-imponible"
                    ref={this.base_imponibleRef}
                    defaultValue={0}
                  />

                  <label htmlFor="iva">%Iva</label>
                  <select
                    id="porcentaje-iva"
                    name="porcentaje-iva"
                    ref={this.ivaPorcentajeRef}
                    onChange={this.calculoIva}
                  >
                    <option value="0">Inversión del sujeto pasivo</option>
                    <option value="0">Exención de iva</option>
                    <option value="0">Suplido</option>
                    <option value="4">4</option>
                    <option value="10">10</option>
                    <option value="21">21</option>
                  </select>
                  <label htmlFor="iva">Iva</label>
                  <input
                    type="text"
                    name="iva"
                    id="iva"
                    ref={this.ivaRef}
                    defaultValue={0}
                    value={this.state.factura.iva}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="base-imponible">Base imponible 2</label>
                  <input
                    type="text"
                    name="base-imponible"
                    id="base-imponible"
                    ref={this.base_imponibleRef2}
                    defaultValue={0}
                  />

                  <label htmlFor="iva">%Iva 2</label>
                  <select
                    id="porcentaje-iva"
                    name="porcentaje-iva"
                    ref={this.ivaPorcentajeRef2}
                    onChange={this.calculoIva2}
                  >
                    <option value="0">Inversión del sujeto pasivo</option>
                    <option value="0">Exención de iva</option>
                    <option value="0">Suplido</option>
                    <option value="4">4</option>
                    <option value="10">10</option>
                    <option value="21">21</option>
                  </select>
                  <label htmlFor="iva">Iva 2</label>
                  <input
                    type="text"
                    name="iva"
                    id="iva"
                    ref={this.ivaRef2}
                    defaultValue={0}
                    value={this.state.factura.iva2}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="base-imponible">Base imponible 3</label>
                  <input
                    type="text"
                    name="base-imponible"
                    id="base-imponible"
                    ref={this.base_imponibleRef3}
                    defaultValue={0}
                  />

                  <label htmlFor="iva">%Iva 3</label>
                  <select
                    id="porcentaje-iva"
                    name="porcentaje-iva"
                    ref={this.ivaPorcentajeRef3}
                    onChange={this.calculoIva3}
                  >
                    <option value="0">Inversión del sujeto pasivo</option>
                    <option value="0">Exención de iva</option>
                    <option value="0">Suplido</option>
                    <option value="4">4</option>
                    <option value="10">10</option>
                    <option value="21">21</option>
                  </select>
                  <label htmlFor="iva">Iva 3</label>
                  <input
                    type="text"
                    name="iva"
                    id="iva"
                    ref={this.ivaRef3}
                    defaultValue={0}
                    value={this.state.factura.iva3}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="total-factura">Total factura</label>
                  <input
                    type="text"
                    name="total-factura"
                    id="total-factura"
                    ref={this.total_facturaRef}
                    defaultValue={0}
                    value={this.state.factura.total_factura}
                  />
                </div>
                <div className="form-group"></div>

                <div className="form-group" id="file">
                  <label htmlFor="file0" id="label-file">
                    Archivo PDF.
                  </label>
                  <InputFiles
                    className="btn btn-success"
                    name="file0"
                    webkitdirectory
                    directory
                    multiple
                    onChange={(files) => this.fileChange(files)}
                  >
                    <input
                      type="submit"
                      name="submit"
                      value="Subir factura"
                      className="btn btn-success"
                    />
                  </InputFiles>
                </div>

                <div className="form-group">
                  <input
                    type="submit"
                    name="submit"
                    value="Guardar fact."
                    className="btn btn-success"
                  />
                </div>

                <div className="clearfix"></div>
              </form>
            </div>
          </section>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default FacturaAdministracion;

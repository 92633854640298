import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import Slider from "../../components/slider";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import swal from "sweetalert";

class AsignarJefeGrupo extends Component {
  //url general peticiones
  url = Global.url;
  //referencias campos formularios
  codProyectoRef = React.createRef();
  denominacionref = React.createRef();
  //estado
  state = {
    jefeGrupo: [],
    entrada: 0,
    page: 0,
    rowsPerPage: 3,
    status: null,
  };
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
    
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.jefeGrupo.length * this.state.rowsPerPage
    );



    getBossWork = (e) =>{
        e.preventDefault();
        axios.get(this.url + 'jefe/grupo', this.config).then(res =>{
            if(res.data){
                this.setState({
                    jefeGrupo:res.data,
                });
            }
        }).catch(err =>{
            swal("No se pudo encontrar los jefes de grupos",{icon:'warning'});
        });
    }

    getIdBossWork = (e) => {
        const id = e.target.dataset.txt;
        axios
          .get(this.url + "jefe/grupo/" + id,this.config)
          .then((res) => {
            this.setState({
              jefeGrupo: res.data,
            });
          })
          .catch((err) => {
            swal(
              "Upps hubo ocurrio algún error en la busqueda, intentelo de nuevo!!!",
              {
                icon: "warning",
              }
            );
          });
    
      };
      getUpdateBossWork = (e) =>{
        e.preventDefault();
        axios.put(this.url + 'actualizar/jefe/grupo/' + this.state.jefeGrupo.id,
        {cod_proyecto: this.codProyectoRef.current.value , denominacion: this.denominacionref.current.value}, this.config)
        .then(res => {
          if(res.data){
            swal("Asignada nueva obra al jefe grupo!!!",{icon:'success'});
          }
        }).catch(err =>{
          swal("No se pudo asignar nueva grupo al jefe de grupo!!!",{icon:'warning'});
        })
      }

  render() {
    return (
      <div className="administracion">
        <Header
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />

        <div className="center">
          <section id="content">
            <h1 className="subheader">Jefes de grupo</h1>
            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Nombre</TableCell>
                      <TableCell align="left">Apellidos</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Cod proyecto</TableCell>
                      <TableCell align="left">Denominación</TableCell>
                      <TableCell align="left">Jefe grupo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.jefeGrupo.length >= 0
                      ? this.state.jefeGrupo
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left">{row.nombre}</TableCell>
                              <TableCell align="left">
                                {row.apellidos}
                              </TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">
                                {row.cod_proyecto}
                              </TableCell>
                              <TableCell align="left">
                                {row.denominacion}
                              </TableCell>
                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value="Jefe grupo"
                                  data-txt={row.id}
                                  onClick={this.getIdBossWork}
                                  className="btn-table2"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                      : null}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 3, 10]}
                  count={
                    this.state.jefeGrupo.length >= 0
                      ? this.state.jefeGrupo.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
            <p className="subheader">Asignar obra</p>
            <form className="mid-form-formulario">
              <div className="form-group">
                <label htmlFor="cod_proyecto">Cod proyecto</label>
                <input
                  type="text"
                  name="cod_proyecto"
                  id="cod_proyecto"
                  defaultValue={this.state.jefeGrupo.cod_proyecto}
                  ref={this.codProyectoRef}
                />
              </div>

              <div className="form-group">
                <label htmlFor="denominacion">Denominación</label>
                <input
                  type="text"
                  name="denominacion"
                  id="denominacion"
                  defaultValue={this.state.jefeGrupo.denominacion}
                  ref={this.denominacionref}
                />
              </div>

              <div className="clearfix"></div>

              <input
                type="submit"
                value="Asignar ob."
                onClick={this.getUpdateBossWork}
                className="btn btn-success"
              />
              <input
                type="submit"
                value="refrescar"
                onClick={this.getBossWork}
                className="btn btn-success"
              />
            </form>
          </section>
        </div>
      </div>
    );
  }
}

export default AsignarJefeGrupo;
import React from 'react';
import Header from './Header';
import Slider from './slider';
import logo from "../assets/images/error.png";

const Footer = () => {
    return (
        <React.Fragment>
        <Header/>
        <Slider title="La página que buscas no se ha podido encontrar" size="slider-small" />
        <div id="center">
        <section id="content">
      <h2 className="subheader">Uppps....</h2>
        <img src={logo} className="app-logo" alt="logotipo" />
        </section>
        </div>
        </React.Fragment>
    );
}

export default Footer;
import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import Slider from "../../components/slider";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";
import InputFiles from "react-input-files";

class AmpliacionPedCompra extends Component {
  //url general peticiones
  url = Global.url;
    //imagen
    selectedFileRef = [];
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  numPedidoRef = React.createRef();
  jefeRef = React.createRef();
  direRef = React.createRef();

  texto = "";
  totalFactura = 0;
  importTotPed = 0;
  totalIrpf = 0;

  path = "https://drive.google.com/file/d/";

  order = [];
  bills = [];

  state = {
    order: [],
    bill: [],
    suma: [],
    email: [],
    isAuthenticated: true,
    entrada: 0,
    image: "",
    page: 0,
    rowsPerPage: 5,
    status: null,
  };

  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
    
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.bill.length * this.state.rowsPerPage
    );

  getBill = () => {
    axios
      .get(this.url + "facturas", this.config)
      .then((bills) => {
        axios
          .get(this.url + "pedidos", this.config)
          .then((orders) => {
            for (var x = 0; x < orders.data.length; x++) {
            for (var i = 0; i < bills.data.length; i++) {
              
                if (bills.data[i].num_pedido === orders.data[x].num_pedido) {
                  this.totalFactura += bills.data[i].base_imponible;
                  this.totalIrpf += bills.data[i].base_imponible_retencion;
                  this.importTotPed = orders.data[x].importe_total_real;

                }
                
              }
              if (
                parseFloat(this.totalFactura) >
                parseFloat(this.importTotPed) ||
                parseFloat(this.totalIrpf) > 
                parseFloat(this.importTotPed)
              ) {

                this.order[x] = orders.data[x];

                this.setState({
                  order: this.order,
                });
                console.log(this.totalFactura + ' --> ' +  orders.data[x].num_pedido);
             
              }
              this.totalFactura = 0;
            }
            this.totalFactura = 0;
          })
          .catch((err) => {
            swal("No se pudo encontrar facturas!!", { icon: "warning" });
          });
      })
      .catch((err) => {
        swal("No se pudo encontrar pedidos!!", { icon: "warning" });
      });
  };
  getTableBill = () => {
    axios
      .get(this.url + "facturas", this.config)
      .then((bill) => {
        axios
          .get(this.url + "pedidos", this.config)
          .then((orders) => {
            for (var i = 0; i < bill.data.length; i++) {
              for (var x = 0; x < orders.data.length; x++) {
                if (bill.data[i].num_pedido === orders.data[x].num_pedido) {
                 
                  if (this.numPedidoRef.current.value === "null") {
                    this.setState({
                      suma: [],
                      bill: [],
                    });
                    this.bills = [];
                  }
                  if (
                    bill.data[i].num_pedido === this.numPedidoRef.current.value
                  ) {

                    this.bills[i] = bill.data[i];
                    this.setState({
                      bill: this.bills,
                    });
                  }
                }
                
              }
            }
          })
          .catch((err) => {
            if (401) {
              swal(
                "Tiempo de sesión excedido, logueese de nuevo, por favor!! ",
                {
                  icon: "info",
                }
              );
              this.setState({
                isAuthenticated: false,
              });
            } else {
              swal("Upps, no se encontraron facturas!! ", { icon: "warning" });
            }
          });
      })
      .catch((err) => {
        swal("No se pudo encontrar pedidos!!", { icon: "warning" });
      });
  };
  getBillDate = (e) => {
    this.texto = e.target.dataset.txt;
    axios
      .get(this.url + "facturas", this.config)
      .then((bills) => {
        axios
          .get(this.url + "pedidos", this.config)
          .then((orders) => {
            for (var x = 0; x < orders.data.length; x++) {
            for (var i = 0; i < bills.data.length; i++) {
             
                if (bills.data[i].num_pedido === this.texto && orders.data[x].num_pedido === this.texto) {
                  this.importTotPed = orders.data[x].importe_total_real;
                  console.log(orders.data[x].importe_total_real + ' --> ' + this.texto);
                  this.totalFactura += bills.data[i].base_imponible;
                  console.log("ff "  + this.totalFactura);
                  this.setState({
                    suma:{
                      num: this.texto,
                      total: this.totalFactura,
                      import: this.importTotPed,
                    }
                  })
                  if (
                    parseFloat(this.totalFactura) >
                    parseFloat(this.importTotPed)
                  ) {

                    this.setState({
                      suma: {
                        num: this.texto,
                        total: this.totalFactura,
                        import: this.importTotPed,
                      },
                    });
                    
                   
                  }
                }
              }

            }
            this.totalFactura = 0;
          })
          .catch((err) => {
            swal("No se pudo encontrar facturas!!", { icon: "warning" });
          });
      })
      .catch((err) => {
        swal("No se pudo encontrar pedidos!!", { icon: "warning" });
      });
  };
  setStateEmail = () => {
    this.setState({
      email: {
        emailTo: this.direRef.current.value, //direccion
        email: this.jefeRef.current.value, //email contacto
        name: this.jefeRef.current.value,
        title:
          "Ampliación pedido: " +
          this.state.suma.num +
          ", Importe pedido: " +
          this.state.suma.import +
          ", Total facturas: " +
          this.state.suma.total,
      },
    });
  };
  sendEmail = (e) => {
    e.preventDefault();

    axios
      .post(this.url + "email", this.state.email, this.config)
      .then((email) => {
        if (email.data) {
          swal("Email enviado correctamente!! ", { icon: "success" });
        }
      })
      .catch((err) => {
        swal("No se pudo enviar el email!!", { icon: "warning" });
      });
  };
  componentDidMount() {
    this.getBill();
  }

  fileChange = (files) => {
    this.selectedFileRef[0] = files[0];
    
  }

  saveFactura = (e) => {
    e.preventDefault();
    //crear formdata y añadir fichero
    const formData = new FormData();
    formData.append(
      "file0",
      this.selectedFileRef[0],
      this.selectedFileRef[0].name,
    );
    const text = '-numero-pedido-' + this.state.suma.num;
    const orders = text.replace('/','-').replace('/','-').replace('/','-');
  
    axios.post(this.url + 'storage/create/sub/payment/special/order/' + orders, '',this.config).then(subdir =>{

    }).catch(err =>{
    
      swal("No se guardo tu archivo de pedido!!!", {
        icon: "warning",
      });
    })

    axios
      .post(this.url + "storage/create/file/payment/special/order/" + orders,  formData , this.config)
      .then((res) => {
        swal("Ampliación pago pedido, guardado con exito!!!", {
          icon: "success",
        });
      })
      .catch((err) => {
        swal("No se guardo tu archivo de pedido!!", {
          icon: "warning",
        });
      });
  }

  getImageBills = (e) => {
    this.texto = e.target.dataset.txt;
    axios
      .get(this.url + "facturas", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].path_factura === this.texto) {
            this.setState({
              bills: res.data[i]
            });

            axios
              .get(this.url + "factura/imagen/" + res.data[i].id, this.config)
              .then((res) => {
                this.setState({
                  image: res.data,
                });

                window.open(this.path + res.data);
              })
              .catch((err) => {
                swal("Upps, no se encontraron facturas!!", { icon: "warning" });
              });
          }
        }
      })
      .catch((err) => {
        swal("Upps, no se encontraron facturas!!", { icon: "warning" });
      });
  };

  render() {
    if (!this.state.isAuthenticated) return <Redirect to="/home" />;
    return (
      <div className="ampliacion">
        <Header buzon="Buzon" visible="true" visibleBU="true" />
        <Slider title="Jefe obra" size="slider-small" visibleJO="true" />
        <div className="center">
          <section id="content">
            <h1 className="subheader">Facturas proveedores</h1>

            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Entrada</TableCell>
                      <TableCell align="left">Cif</TableCell>
                      <TableCell align="left">Proveedor</TableCell>
                      <TableCell align="left">Nº factura</TableCell>
                      <TableCell align="left">Fecha emisión</TableCell>
                      <TableCell align="left">Fecha entrada</TableCell>
                      <TableCell align="left">Total factura</TableCell>
                      <TableCell align="left">Forma pago</TableCell>
                      <TableCell align="left">Fecha prevista pago</TableCell>
                      <TableCell align="left">Fecha real pago</TableCell>
                      <TableCell align="left">Cod Proyecto</TableCell>
                      <TableCell align="left">Denominación</TableCell>
                      <TableCell align="left">Pedido</TableCell>
                      <TableCell align="left">Factura</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.bill.length >= 0
                      ? this.state.bill
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .sort((a, b) => (a.num_pedido > b.num_pedido ? 1 : -1))
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left">{row.id}</TableCell>
                              <TableCell align="left">{row.cif}</TableCell>
                              <TableCell align="left">{row.empresa}</TableCell>
                              <TableCell align="left">
                                {row.nº_factura}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_emision}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_entrada}
                              </TableCell>

                              <TableCell align="left">
                                {row.total_factura1}
                              </TableCell>

                              <TableCell align="left">
                                {row.forma_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_prevista_pago}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_real_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.cod_proyecto}
                              </TableCell>
                              <TableCell align="left">
                                {row.denominacion}
                              </TableCell>

                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value={row.num_pedido}
                                  data-txt={row.num_pedido}
                                  onClick={this.getBillDate}
                                  className="btn-table2"
                                />
                              </TableCell>
                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value=""
                                  data-txt={row.path_factura}
                                  onClick={this.getImageBills}
                                  className="btn-table"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                      : null}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={14} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[5, 10, 15]}
                  count={
                    this.state.bill.length >= 0 ? this.state.bill.length : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
          </section>
          <div id="search" className="slidebar-item">
            <form className="mid-form-formulario" id="formulario">
              <div className="form-group"></div>
              <div className="form-group">
                <p className="subheader">Envio email</p>
              </div>
              <div className="form-group"></div>

              <div className="form-group">
                <label htmlFor="pedido">
                  Número pedido:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.suma.num}</p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="pedido">
                  Importe pedido:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.suma.import}</p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="pedido">
                  Total facturas:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.suma.total}</p>
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="numero-pedido">Número de pedido</label>
                <select
                  id="numero-pedido"
                  name="numero-pedido"
                  ref={this.numPedidoRef}
                  onChange={this.getTableBill}
                >
                  <option value="null">NP</option>
                  {this.state.order.length > 0 ? (
                    this.state.order.map((ped, i) => (
                      <option
                        key={i}
                        value={`${ped.num_pedido}`}
                      >{`${ped.num_pedido}`}</option>
                    ))
                  ) : (
                    <option value={this.state.order.num_pedido}>
                      {this.state.order.num_pedido}
                    </option>
                  )}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="jefeobra">Email jefe obra </label>
                <input
                  type="text"
                  name="jefeobra"
                  id="jefeobra"
                  onChange={this.setStateEmail}
                  ref={this.jefeRef}
                />
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Email dirección</label>
                <input
                  type="text"
                  name="direccion"
                  id="direccion"
                  onChange={this.setStateEmail}
                  ref={this.direRef}
                />
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="Enviar email."
                  onClick={this.sendEmail}
                  className="btn btn-success"
                />
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="Guardar arch."
                  onClick={this.saveFactura}
                  className="btn btn-success"
                />
              </div>
              <div className="form-group" id="file">
                <label htmlFor="file0" id="label-file">
                    Archivo PDF.
                  </label>
                <InputFiles
                    className="btn btn-success"
                    name="file0"
                    webkitdirectory
                    directory
                    multiple
                    onChange={(files) => this.fileChange(files)}
                  >
                    <input
                      type="submit"
                      name="submit"
                      value="pago especial"
                      className="btn btn-success"
                    />
                  </InputFiles>
                </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AmpliacionPedCompra;

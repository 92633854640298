import React, { Component } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

class ProgressTimer extends Component{
    render(){
        return(

            <div className="timer-wrapper">
            <CountdownCircleTimer
              isPlaying
              duration={10}
              colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
              onComplete={() => [false, 10]}
              
            >
              {this.props.renderTime}
            </CountdownCircleTimer>
            </div>
        );
    }
}

export default ProgressTimer;
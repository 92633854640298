import { blue } from "@material-ui/core/colors";
import React, { Component } from "react";


class Notification extends Component {
  state = {
    notify: "false",
  };

  visibleNotify = () => {
    if (this.state.notify === "true") {
      this.setState({
        notify: "false",
      });
    } else {
      this.setState({
        notify: "true",
      });
    }
  };

  render() {
    return (
      <div className="navbar-nav">

        {this.props.visible === "true" && (
          <input
            type="submit"
            name="submit"
            className="btn btn-buzon"
            value="Notificaciones"
            onClick={this.visibleNotify}
          />
        )}
        {this.state.notify === "true" && (
          <ul >
            <div>
           
              <li style={{color:blue[800]}}>{this.props.title} &nbsp; {this.props.count}  </li>
              <li> {this.props.content}</li>
            </div>
          </ul>
        )}
      </div>
    );
  }
}

export default Notification;

import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";

class DatosPedido extends Component {
  //url base
  url = Global.url;
  //referencias campos de busqueda
  rol = '';
  name = '';
  email = '';
  user_id = '';
  uemail = '';
  pemail = '';
  dir = '';
  observacionesRef = React.createRef();
  inciRef = React.createRef();
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  state = {
    b_pedido: [],
    email: [],
    entrada: 0,
    page: 0,
    rowsPerPage: 3,
    status: null,
  };

  acceptOrder = () => {
   

                //usuario origen/destino email
                axios.get(this.url + 'registro',this.config).then(user =>{
                  user.data.forEach(element => {
                    if(this.state.b_pedido.proveedor === element.nombre_empresa){
                      this.pemail = element.email;
                      this.dir = element.direccion;
                    
                    }
                });
              axios.get(this.url + 'user',this.config).then(res =>{
                 this.uemail = res.data.email;
              }).catch(err =>{
                swal('No se encontro al usuario!!',{icon:'warning'});
              })
                
              }).catch(err =>{
                swal('No se encontro al usuario!!',{icon:'warning'});
              })
  };

  order = (e) => {
    //  e.preventDefault();
    this.setState({
      b_pedido: this.props.estado,
    });
  };

  componentDidMount() {
    this.order();
  }
  denegedOrder = () =>{
  

    axios.get(this.url + 'registro',this.config).then(user =>{
        user.data.forEach(element => {
          if(this.state.b_pedido.proveedor === element.nombre_empresa){
            this.pemail = element.email;
            this.dir = element.direccion;
            
          }
      });
    axios.get(this.url + 'user',this.config).then(res =>{
       this.uemail = res.data.email;
    }).catch(err =>{
      swal('No se encontro al usuario!!',{icon:'warning'});
    })
      
    }).catch(err =>{
      swal('No se encontro al usuario!!',{icon:'warning'});
    })

  
  }

  setStateEmail = () =>{
    this.denegedOrder();
    this.setState({
      
      email:{
        emailTo:this.pemail, //proveedor pemail
        email: this.uemail, //email contacto jefe grupo uemail
        name:
        this.name,
        title: 
        'Pedido: ' + this.state.b_pedido.num_pedido
        +  ', Obra: ' + this.state.b_pedido.cod_proyecto + ' ' + this.state.b_pedido.denominacion
        + ', Direccion obra: ' + this.dir
        + ', Observaciones: ' + this.observacionesRef.current.value,
    
      },
     
    })
    
  }
  accept = () =>{
    axios
    .put(this.url + "actualizar/pedido/" + this.state.b_pedido.id, {
      estado: "pedido aceptado",
    }, this.config)
    .then((res) => {
      if (res.data) {
        swal("Pedido aceptado!!", { icon: "success" });
        this.setState({
          b_pedido: {
            estado: "pedido aceptado",
          },
        });

      }
    })
    .catch((err) => {
      swal("Hubo algún problema al aceptar el pedido!!", { icon: "warning" });
    });
  }
  setStateEmailAccepted = () =>{
    this.acceptOrder();

    this.setState({
      
      email:{
        emailTo:this.pemail, //proveedor pemail
        email: this.uemail, //email contacto jefe grupo uemail
        name:
        this.name,
        title: 
        'Pedido: ' + this.state.b_pedido.num_pedido
        +  ', Obra: ' + this.state.b_pedido.cod_proyecto + ' ' + this.state.b_pedido.denominacion
        + ', Direccion obra: ' + this.dir
        + ', Observaciones: ' + this.observacionesRef.current.value,
    
      },
     
    })
    
  }
  sendEmail = (e) =>{
    e.preventDefault();
    axios.post(this.url + 'email',this.state.email, this.config).then(email =>{
      if(email.data){
        swal('Email enviado al proveedor correctamente!! ',{icon:'success'});
      }

     }).catch(err =>{
       swal('No se pudo enviar el email!!',{icon:'warning'});
     });
  }
  sendEmailAccept = (e) =>{
    e.preventDefault();
    this.accept();
    axios.post(this.url + 'email',this.state.email, this.config).then(email =>{
      if(email.data){
        swal('Email enviado al proveedor correctamente!! ',{icon:'success'});
      }

     }).catch(err =>{
       swal('No se pudo enviar el email!!',{icon:'warning'});
     });
  }
 
  render() {
    return (
      <div className="center">
        <h1 className="subheader">Pedidos proveedores</h1>
        <div id="search" className="slidebar-item">
          {this.props.pedido === "true" && this.props.estado !== null && (
            <form className="mid-form-formulario">
              <div className="form-group">
                <label htmlFor="numero-pedido">
                  Numero pedido:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.b_pedido.num_pedido}
                  </p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="cod-proyecto">
                  Cod proyecto:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.b_pedido.cod_proyecto}
                  </p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="proveedor">
                  Proveedor:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.b_pedido.proveedor}
                  </p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="cif">
                  Cif:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.b_pedido.cif}</p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="plazo-ejecucion">
                  Plazo de ejecución:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.b_pedido.plazo_ejecucion}
                  </p>
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="retecion-garantia">
                  Retención por garantia:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.b_pedido.total_retencion_garantia}
                  </p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="observaciones">
                  Observaciones:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.b_pedido.observaciones}
                  </p>
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="total-factura">
                  Importe pedido:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.b_pedido.importe_total_real}
                  </p>
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="forma-pago">
                  Forma de pago:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.b_pedido.forma_pago}
                  </p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="forma-pago">
                  Estado:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.b_pedido.estado}</p>
                </label>
              </div>
              <div className="form-group"></div>
              <div className="form-group"></div>
              <div className="form-group">
                <label htmlFor="incidencias">Incidencia</label>
                <p className="p-required">(* Es obligatorio rellenar este campo)</p>
                <textarea
                  name="incidencias"
                  id="incidencias"
                  ref={this.inciRef}
                  onChange={this.setStateEmail}
                ></textarea>
              </div>
              <div className="form-group"></div>
              <div className="form-group">
              <label htmlFor="observaciones">Observaciones</label>
              <p className="p-required">(* Es obligatorio rellenar este campo)</p>
                <textarea
                  name="observaciones"
                  id="observaciones"
                  ref={this.observacionesRef}
                  onChange={this.setStateEmailAccepted}
                ></textarea>
              </div>
              <div className="form-group">
              <input
                type="submit"
                name="submit"
                value="No aceptado"
                onClick={this.sendEmail}
                className="btn btn-success"
              />
              </div>

              <div className="form-group"></div>
              <div className="form-group">
              <input
                type="submit"
                name="submit"
                value="Si aceptado"
                onClick={this.sendEmailAccept}
                className="btn btn-success"
              />
              </div>

            </form>
          )}
        </div>
      </div>
    );
  }
}

export default DatosPedido;

import React, { Component } from "react";
import FacturaAdministracion from "../administracion/FacturaAdministracion";
import Header from "../../components/Header";
import Slider from "../../components/slider";

class CrearFacturaAdminNormal extends Component{

    render(){
        return(
            <div id="factura">
            <Header
            buzon="Buzon"
            visible="true"
            visibleBU="true"
          />
          <Slider
            title="Administración"
            size="slider-small"
            visibleAdminNormal="true"
          />
          <FacturaAdministracion/>
          </div>
        );
    }
}

export default CrearFacturaAdminNormal;
import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import swal from "sweetalert";

class TablaProveedor extends Component {
  //url general peticiones
  url = Global.url;
  supplierRef = React.createRef();
  emailRef = React.createRef();
  nameRef = React.createRef();
  emailpRef = React.createRef();
  emailcRef = React.createRef();
    //header
    config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

  state = {
    proveedores: [],
    proveedor: [],
    incidencias: "",
    email: [],
    entrada: 0,
    image: "",
    page: 0,
    rowsPerPage: 3,
    status: null,
  };
  //referencia incidencia
  observacionesRef = React.createRef();

  componentDidMount() {
    this.getProvider();
  }
  changeStateProvider = () => {
    this.setState({
      incidencias: this.observacionesRef.current.value,
    });
  };
  //peticion AJAX para crear incidencia
  getCreateIncidence = (e) => {
    e.preventDefault();

    axios
      .put(this.url + "registro/actualizar/" + this.state.proveedor.id, {
        incidencias: this.state.incidencias,
      },this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            email:{
              emailTo:this.emailpRef.current.value,
              email:this.emailcRef.current.value,
              name:this.state.proveedor.nombre + ', ' + this.state.proveedor.email, 
              title:'Incidencia ' + this.observacionesRef.current.value,
            }
          })
          swal("Incidencia creada con exito!!", { icon: "success" });
          axios.post(this.url + 'email',this.state.email).then(email => {
            if(email.data){
              swal("Correo enviado a " + this.state.email.emailTo + " con exito!!", { icon: "success" });
            }
          })
        
        }
      })
      .catch((err) => {
        swal("No se pudo crear incidencia!!", { icon: "warning" });
      });
  };
  
  getProvider = () => {
    axios.get(this.url + "registro",this.config).then((res) => {
      this.setState({
        proveedores: res.data,
      });
    }).catch(err =>{
      swal("No se pudo encontrar proveedores!!", { icon: "warning" });
    });
  };

  provider = (e) => {
    const id = e.target.dataset.txt;
    axios
      .get(this.url + "registro/proveedor/" + id, this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            proveedor: res.data,
          });
        }
      })
      .catch((err) => {
        swal("No se pudo encontrar proveedor!!", { icon: "warning" });
      });
  };

  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.proveedores.length * this.state.rowsPerPage
    );
  render() {
    return (
      <div className="center">
        <section id="content">
          <h1 className="subheader">Proveedores</h1>
          <Paper>
            <TableContainer>
              <Table className={this.useStyles.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Nombre</TableCell>
                    <TableCell align="left">Apellidos</TableCell>
                    <TableCell align="left">Cargo proveedor</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Proveedor</TableCell>
                    <TableCell align="left">Formas de pago</TableCell>
                    <TableCell align="left">Bancos de pago</TableCell>
                    <TableCell align="left">Cuentas de pago</TableCell>
                    <TableCell align="left">Incidencias</TableCell>
                    <TableCell align="left">Cif</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.proveedores.length >= 0
                    ? this.state.proveedores
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        ).sort((a,b) => a.nombre_empresa > b.nombre_empresa ? 1 : -1)
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">{row.nombre}</TableCell>
                            <TableCell align="left">{row.apellidos}</TableCell>
                            <TableCell align="left">
                              {row.cargo_empresa}
                            </TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">
                              {" "}
                              {row.nombre_empresa}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {row.forma_pago1}
                              <br />
                              {row.forma_pago2}
                              <br />
                              {row.forma_pago3}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {row.banco_pago1}
                              <br />
                              {row.banco_pago2}
                              <br />
                              {row.banco_pago3}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {row.cuenta_pago1}
                              <br />
                              {row.cuenta_pago2}
                              <br />
                              {row.cuenta_pago3}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {row.incidencias}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              <input
                                type="submit"
                                name="submit"
                                value={row.cif}
                                data-txt={row.id}
                                onClick={this.provider}
                                className="btn-table2"
                              />
                            </TableCell>
                          </TableRow>
                        ))
                    : null}
                  {this.emptyRows > 0 && (
                    <TableRow style={{ height: 0 * this.emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                labelRowsPerPage={"Filas por página"}
                component="div"
                rowsPerPageOptions={[1, 3, 10]}
                count={
                  this.state.proveedores.length >= 0
                    ? this.state.proveedores.length
                    : 1
                }
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableContainer>
          </Paper>
          <div id="search" className="slidebar-item">
            <form
              className="mid-form-formulario"
             
            >

              <div className="form-group">
                <label htmlFor="Proveedor">
                  Proveedor:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.proveedor.nombre_empresa}
                  </p>
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="nombre">
                  Cif:{""}
                  <p style={{ fontWeight: 12 }}>{this.state.proveedor.cif}</p>
                </label>
              </div>

                  <div className="form-group">
                  <label htmlFor="email">
                    Email:{" "}
                    <p style={{ fontWeight: 12 }}>{this.state.proveedor.email}</p>
                  </label>
                </div>
                {this.props.incidencia !== "false" && (
                <>
                <div className="form-group">
                  <label htmlFor="observaciones">Incidencias</label>
                  <textarea
                    name="observaciones"
                    id="observaciones"
                    onChange={this.changeStateProvider}
                    ref={this.observacionesRef}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="email-p">Email proveedor</label>
                  <input
                    type="text"
                    name="email-p"
                    id="email-p"
                    ref={this.emailpRef}
                    onChange={this.changeStateProvider}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email-c">Email contacto</label>
                  <input
                    type="text"
                    name="email-c"
                    id="email-c"
                    ref={this.emailcRef}
                    onChange={this.changeStateProvider}
                  />
                </div>
  
                <div className="form-group"></div>
                <input
                  type="submit"
                  name="submit"
                  value="Incidencia"
                  onClick={this.getCreateIncidence}
                  className="btn btn-success"
                />
                </>
                )}
            
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default TablaProveedor;

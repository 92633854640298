import React, { Component } from "react";
import HeaderProveedor from "./HeaderProveedor";


class IncidenciaProveedorCapa extends Component {
  render() {
    return (
      <div id="factura-principal">
        <HeaderProveedor
          inicioSesion2="Inicio Sesión"
          misFacturas="Mis Facturas"
          crearFactura="Crear Facturas"
          
        />

        <p className="brand-titulo" >
          No puedes crear facturas, tienes incidencias pendientes en alguna obra, por favor revisa tu email, o ponte en contacto con  la empresa.
        </p>
      </div>
    );
  }
}

export default IncidenciaProveedorCapa;

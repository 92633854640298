import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Slider from "./slider";
import axios from "axios";
import Global from "../Global";
import swal from "sweetalert";

class Login extends Component {
  //url base
  url = Global.url;
  //referencias a los campos para enviar EN LAS PETICIONES AJAX
  emailRef = React.createRef();
  passwordRef = React.createRef();

  state = {
    user: {},
    rol: "null",
    status: null,
  };

  changeState = () => {
    this.setState({
      user: {
        email: this.emailRef.current.value,
        password: this.passwordRef.current.value,
      },
    });

    this.forceUpdate();
  };

  loginUser = (e) => {
    e.preventDefault();

    //rellenar state con formulario
    this.changeState();

    axios.post(this.url + "login", this.state.user).then((res) => {
      if (res.data) {
        localStorage.setItem("token", res.data[0].token);
        this.setState({
          user: res.data.user,
          role: res.data[1][0].name,
          status: "success",
        });
      } else {
        swal("Usuario o contraseña incorrectos, intentelo de nuevo", {
          icon: "warning",
        });
      }
    }).catch(err =>{
      swal("Upps, parece que hubo algún problema..intentelo de nuevo más tarde!!  " + err, {
        icon: "warning",
      });
    });
  };

  recordarUsuario = () => {};

  render() {
    if (this.state.role === "proveedores") {
      return <Redirect to="/proveedor/factura" />;
    } else if (this.state.role === "administración_responable") {
      return <Redirect to="/home/administracion" />;
    } else if (this.state.role === "jefe_obra") {
      return <Redirect to="/jefeObra/pedido" />;
    } else if (this.state.role === "jefe_grupo") {
      return <Redirect to="jefeGrupo/pedido" />;
    } else if (this.state.role === "calidad") {
      return <Redirect to="proveedor" />;
    }else if(this.state.role === 'administración_normal'){
      return <Redirect to="/home/administracion/normal" />;
    }else if(this.state.role === 'gestoria'){
      return <Redirect to="/home/gestoria"/>
    }else if(this.state.role === "direccion"){
      return <Redirect to="/direccion"/>
    }

    return (
      <div id="login">
        <Slider title="Grupo Sima" size="slider-small" />

        <h1 className="subheader">Login usuario</h1>

        <div className="center">
          <aside id="slidebar">
            <div id="search" className="slidebar-item">
              <form className="mid-form" onSubmit={this.loginUser}>
                <div className="form-group-login">
                  <label htmlFor="usuario" name="usuario">Email</label>

                  <input
                    type="text"
                    name="usuario"
                    id="usuario"
                    className="formulario-input"
                    placeholder="Email"
                    ref={this.emailRef}
                    value={this.state.user.email || ''}
                    onChange={this.changeState}
                  />
                </div>

                <div className="form-group-login">
                  <label htmlFor="contrasena" name="contrasena">Contraseña</label>

                  <input
                    type="password"
                    name="contrasena"
                    id="contrasena"
                    className="formulario-input"
                    placeholder="password"
                    ref={this.passwordRef}
                    value={this.state.user.password || ''}
                    onChange={this.changeState}
                  />
                </div>
                <div id="search" className="group-link">
                  <Link className="link-reg" to={"/registro/proveedor"}>
                       Registro proveedores   
                  </Link>
                  {/*<Link style={{marginLeft:10}} onClick={this.recordarUsuario} className="link-reg" >Recordar usuario</Link>*/}
                </div>
                <input
                  type="submit"
                  name="submit"
                  value="Iniciar sesion"
                  className="btn btn-success"
                />
              </form>
            </div>
          </aside>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default Login;

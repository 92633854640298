import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Slider from "../slider";
import swal from "sweetalert";
import Moment from "react-moment";
import "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { Redirect } from "react-router-dom";
import HeaderProveedor from "./HeaderProveedor";

class MisFacturas extends Component {
  //url base
  url = Global.url;
  //entradas facturas
  entrada = [];
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  state = {
    facturas: [],
    page: 0,
    rowsPerPage: 3,
    status: null,
  };

  componentDidMount() {
    this.getFacturas();
  }

  //lista de facturas de proveedores
  getFacturas = () => {
    axios.get(this.url + "user", this.config).then((resuser) => {
      if (resuser.data) {
        axios.get(this.url + "registro",this.config).then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            if (resuser.data.email === response.data[i].email) {
              this.entrada = response.data[i].nombre_empresa;

              this.getFactura();
            }
          }
        });
      }
    });
  };
  getFactura = () => {
    const factura = [];
    axios
      .get(this.url + "facturas", this.config)
      .then((resfacturas) => {
        for (var x = 0; x < resfacturas.data.length; x++) {
          if (this.entrada === resfacturas.data[x].empresa) {
            factura[x] = resfacturas.data[x];
            this.setState({
              facturas: factura,
              status: "success",
            });
          }
        }
      })
      .catch((err) => {
        swal("Lo sentimos, no hemos podido encontrar tus facturas!", {
          icon: "info",
        });
        this.setState({
          status: "failed",
        });
      });
  };

  useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.facturas.length * this.state.rowsPerPage
    );

  render() {
    if (this.state.status === "failed") {
      return <Redirect to="/proveedor/factura" />;
    }
    return (
      <div id="mis-factura">
        <HeaderProveedor inicioSesion2="Inicio Sesión" misFacturas="Mis Facturas" crearFactura="Crear Facturas"/>

        <Slider title={this.entrada} size="slider-small" />
        <h1 className="subheader">Mis Facturas</h1>
        <div className="center">
          <section id="content">
            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">Número de pedido</TableCell>
                      <TableCell component="th">Año</TableCell>
                      <TableCell align="right">Entrada</TableCell>
                      <TableCell align="right">Cif</TableCell>
                      <TableCell align="right">Empresa</TableCell>
                      <TableCell align="right">Nº factura</TableCell>
                      <TableCell align="right">Total factura</TableCell>
                      <TableCell align="right">Incidencias</TableCell>
                      <TableCell align="right">Estado factura</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.facturas
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="td" scope="row">
                            {row.num_pedido}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            <Moment format="YYYY">{row.año}</Moment>
                          </TableCell>

                          <TableCell align="right">{row.id}</TableCell>
                          <TableCell align="right">{row.cif}</TableCell>
                          <TableCell align="right">{row.empresa}</TableCell>
                          <TableCell align="right">{row.nº_factura}</TableCell>                    
                         
                          <TableCell align="right">
                            {row.total_factura1}
                          </TableCell>

                          <TableCell align="right">
                            {row.observaciones}
                          </TableCell>
                          <TableCell align="right">
                            {row.estado_factura}
                          </TableCell>
                        </TableRow>
                      ))}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={9} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 3, 10]}
                  count={
                    this.state.facturas.length > 1
                      ? this.state.facturas.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
          </section>
        </div>
      </div>
    );
  }
}

export default MisFacturas;

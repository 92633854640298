import React, { Component } from "react";
import Header from "../../components/Header";
import Slider from "../../components/slider";
import axios from "axios";
import Global from "../../Global";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import InputFiles from "react-input-files";

class PlazoPagoEspecial extends Component {
  //url general peticiones
  url = Global.url;
  //imagen
  selectedFileRef = [];

  num_pedidoRef = React.createRef();
  jefeRef = React.createRef();
  direRef = React.createRef();

  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  pedido = [];
  state = {
    pedidos: [],
    pedido: [],
    entrada: 0,
    page: 0,
    rowsPerPage: 5,
    status: null,
  };
  useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });
  changeStatePedido = () => {
    const num = this.num_pedidoRef.current.value;
    this.pedido = [];
    axios
      .get(this.url + "pedidos", this.config)
      .then((order) => {
        for (var i = 0; i < order.data.length; i++) {
          if (order.data[i].num_pedido === num) {
            this.pedido[i] = order.data[i];
          }
        }

        this.setState({
          pedidos: this.pedido,
        });
      })
      .catch((err) => {
        swal("No se encontro ningún pedido!!", { icon: "warning" });
      });
  };
  //ver pedidos
  getOrder = () => {
    axios
      .get(this.url + "pedidos", this.config)
      .then((res) => {
        if (res.data) {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].plazo_pago === "60 días") {
              this.pedido[i] = res.data[i];
              this.setState({
                pedidos: this.pedido,
                pedido: this.pedido,
                status: "failed",
              });
            } else {
              swal("No hay pedidos pendientes!!!", { icon: "info" });
            }
          }
        }
      })
      .catch((err) => {
        swal("No hay pedidos pendientes!!!", { icon: "info" });
      });
  };
  setStateEmail = () => {
    this.setState({
      email: {
        emailTo: this.direRef.current.value, //direccion
        email: this.jefeRef.current.value, //email contacto
        name: this.jefeRef.current.value,
        title:
          "Ampliación pago pedido: " +
          this.state.pedidos.num_pedido +
          ", Plazo pago: " +
          this.state.pedidos.plazo_pago,
      },
    });
  };
  sendEmail = (e) => {
    e.preventDefault();
    axios
      .post(this.url + "email", this.state.email, this.config)
      .then((email) => {
        if (email.data) {
          swal("Email enviado correctamente!! ", { icon: "success" });
        }
      })
      .catch((err) => {
        swal("No se pudo enviar el email!!", { icon: "warning" });
      });
  };
  getOrderDate = (e) =>{
    const num = e.target.dataset.txt;
    axios.get(this.url + 'pedidos', this.config).then(order =>{
      order.data.forEach(element => {
        if(num === element.num_pedido){
          this.setState({
            pedidos:element,
          })
        }
      });
    }).catch(err =>{
      swal('No se pudo encontrar el pedido!!',{icon:'warning'});
    })
  }

  componentDidMount() {
    this.getOrder();
  }

  fileChange = (files) => {
    this.selectedFileRef[0] = files[0];
    
  }

  saveFactura = (e) => {
    e.preventDefault();
    //crear formdata y añadir fichero
    const formData = new FormData();
    formData.append(
      "file0",
      this.selectedFileRef[0],
      this.selectedFileRef[0].name,
    );
    const text = '-numero-pedido-' + this.state.pedidos.num_pedido;
    const orders = text.replace('/','-').replace('/','-').replace('/','-');
  
    axios.post(this.url + 'storage/create/sub/payment/extension/order/' + orders, '',this.config).then(subdir =>{

    }).catch(err =>{
      swal("No se guardo tu archivo de pedido!!!", {
        icon: "warning",
      });
    })

    axios
      .post(this.url + "storage/create/file/payment/extension/order/" + orders,  formData , this.config)
      .then((res) => {
        swal("Pedido guardado con exito!!!", {
          icon: "success",
        });
      })
      .catch((err) => {
        swal("No se guardo tu archivo de pedido!!", {
          icon: "warning",
        });
      });
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.pedidos.length * this.state.rowsPerPage
    );
  render() {
    return (
      <div className="plazo">
        <Header buzon="Buzón" visible="true" visibleBU="true" />

        <Slider title="Jefe obra" size="slider-small" visibleJO="true" />
        <h1 className="subheader">Pedidos proveedores</h1>
        <div className="center">
          <section id="content">
            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Número pedido</TableCell>
                      <TableCell align="right">Cod proyecto</TableCell>
                      <TableCell align="right">Proveedor</TableCell>
                      <TableCell align="right">cif</TableCell>
                      <TableCell align="right">Fecha inicio</TableCell>
                      <TableCell align="right">Fecha fin</TableCell>
                      <TableCell align="right">
                        Retención por garantia
                      </TableCell>
                      <TableCell align="right">Importe pedido</TableCell>
                      <TableCell align="right">Plazo ejecución</TableCell>
                      <TableCell align="right">Plazo pago</TableCell>
                      <TableCell align="right">Forma pago</TableCell>
                      <TableCell align="right">Observaciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.pedidos.length > 0 ? (
                      this.state.pedidos
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="right">
                              <input
                                type="submit"
                                name="submit"
                                value={row.num_pedido}
                                data-txt={row.num_pedido}
                                onClick={this.getOrderDate}
                                className="btn-table2"
                              />
                            </TableCell>
                            <TableCell align="right">
                              {row.cod_proyecto}
                            </TableCell>
                            <TableCell align="right">{row.proveedor}</TableCell>
                            <TableCell align="right">{row.cif}</TableCell>
                            <TableCell align="right">
                              {row.fecha_inicio}
                            </TableCell>
                            <TableCell align="right">{row.fecha_fin}</TableCell>

                            <TableCell align="right">
                              {row.total_retencion_garantia}
                            </TableCell>
                            <TableCell align="right">
                              {row.importe_total_real}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_ejecucion}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_pago}
                            </TableCell>

                            <TableCell align="right">
                              {row.forma_pago}
                            </TableCell>
                            <TableCell align="right">
                              {row.observaciones}
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow key={this.pedido.id}>
                        <TableCell align="right">
                          {this.pedido.num_pedido}
                        </TableCell>
                        <TableCell align="right">
                          {this.pedido.cod_proyecto}
                        </TableCell>
                        <TableCell align="right">
                          {this.pedido.proveedor}
                        </TableCell>
                        <TableCell align="right">{this.pedido.cif}</TableCell>
                        <TableCell align="right">
                          {this.pedido.fecha_inicio}
                        </TableCell>

                        <TableCell align="right">
                          {this.pedido.fecha_fin}
                        </TableCell>
                        <TableCell align="right">
                          {this.pedido.total_retencion_garantia}
                        </TableCell>
                        <TableCell align="right">
                          {this.pedido.importe_total_real}
                        </TableCell>
                        <TableCell align="right">
                          {this.pedido.plazo_ejecucion}
                        </TableCell>
                        <TableCell align="right">
                          {this.pedido.plazo_pago}
                        </TableCell>
                        <TableCell align="right">
                          {this.pedido.forma_pago}
                        </TableCell>
                        <TableCell align="right">
                          {this.pedido.observaciones}
                        </TableCell>
                      </TableRow>
                    )}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 5, 10]}
                  count={
                    this.state.pedidos.length > 1
                      ? this.state.pedidos.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
            <div className="clearfix"></div>
            <p className="subheader">Buscar pedido</p>
            <form className="mid-form-formulario">
              <div className="form-group">
                <label htmlFor="pedido">
                  Número pedido:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.pedidos.num_pedido}
                  </p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="pedido">
                  Plazo pago:{" "}
                  <p style={{ fontWeight: 12 }}>
                    {this.state.pedidos.plazo_pago}
                  </p>
                </label>
              </div>
              <div className="form-group"></div>
              <div className="form-group">
                <label htmlFor="numero-pedido">Número de pedido</label>
                <select
                  id="numero-pedido"
                  name="numero-pedido"
                  ref={this.num_pedidoRef}
                  onChange={this.changeStatePedido}
                >
                  {this.state.pedido.map((option) => (
                    <option
                      key={`${option.id}`}
                      value={`${option.num_pedido}`}
                    >{`${option.num_pedido}`}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="jefeobra">Email jefe obra </label>
                <input
                  type="text"
                  name="jefeobra"
                  id="jefeobra"
                  onChange={this.setStateEmail}
                  ref={this.jefeRef}
                />
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Email dirección</label>
                <input
                  type="text"
                  name="direccion"
                  id="direccion"
                  onChange={this.setStateEmail}
                  ref={this.direRef}
                />
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="Enviar email."
                  onClick={this.sendEmail}
                  className="btn btn-success"
                />
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="Guardar arch."
                  onClick={this.saveFactura}
                  className="btn btn-success"
                />
              </div>
              <div className="form-group" id="file">
                <label htmlFor="file0" id="label-file">
                    Archivo PDF.
                  </label>
                <InputFiles
                    className="btn btn-success"
                    name="file0"
                    webkitdirectory
                    directory
                    multiple
                    onChange={(files) => this.fileChange(files)}
                  >
                    <input
                      type="submit"
                      name="submit"
                      value="forma pago"
                      className="btn btn-success"
                    />
                  </InputFiles>
                </div>
            </form>
          </section>
        </div>
      </div>
    );
  }
}

export default PlazoPagoEspecial;

import React, { Component } from "react";
import Header from "../../components/Header";
import Slider from "../../components/slider";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";

class DatosFacturaJO extends Component {
  //url base
  url = Global.url;

  obsRef = React.createRef();

  rol = '';
  name = '';
  email = '';
  user_id = '';
  pemail = '';
  uemail = '';

  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  
  state = {
    email:[],
    post:[],
    status: null,
  };

  createNotification = () =>{
  
    axios.get(this.url + 'role',this.config).then(role => {
      role.data.forEach(element => {
       
          if(element.name === 'administración_responable'){
            this.rol = element.id;
          }
        
      });
      axios.get(this.url + 'role/' + this.rol,this.config).then(user =>{
        user.data.forEach(element => {
        this.name = element.name;
        this.email = element.email;
        this.user_id = element.id;
        });

        axios
        .post(
          this.url + "crear/notificacion/" + this.user_id,
          {title:this.name, description:'Aprobación factura para ' + this.email, user_id:this.user_id},
          this.config
        )
        .then((res) => {
          if (res.data) {
            swal(res.data, { icon: "success" });
          }
        })
        .catch((err) => {
          swal("No se pudo enviar notificacion!!", { icon: "warning" });
        });

      }).catch(err =>{
        swal('Upps, no se creo la notificaión!!',{icon:'warning'});
      })
    }).catch(err =>{
      swal('Upps, no se creo la notificaión!!',{icon:'warning'});
    })
  }

 sendEmail = (e) =>{
  e.preventDefault();
  axios.post(this.url + 'email',this.state.email, this.config).then(email =>{
    if(email.data){
      swal('Email incidencia enviado correctamente!! ',{icon:'success'});
    }

   }).catch(err =>{
     swal('No se pudo enviar el email!!',{icon:'warning'});
   });
 }
 changeStateMail = () =>{
      axios.get(this.url + 'usuarios',this.config).then(user =>{
        user.data.forEach(element => {
          if(this.props.empresa === element.nombre_empresa){
            this.pemail = element.email;
          }
      });
    axios.get(this.url + 'user',this.config).then(res =>{
      this.uemail = res.data.email;
    }).catch(err =>{
      swal('No se encontro al usuario!!',{icon:'warning'});
    })
      
    }).catch(err =>{
      swal('No se encontro al usuario!!',{icon:'warning'});
    })
   this.setState({
     email:{
      emailTo:this.pemail, //proveedor pemail
      email: this.uemail, //email contacto jefe obra uemail
      name:
      this.name,
      title: 
      'Factura no aceptada: ' 
      + 'Factura: ' + this.props.pedido
      +  ', Obra: ' + this.props.cod_proyecto + ' ' + this.props.denominacion
      + ', Observaciones: ' + this.obsRef.current.value,
     }
   })
 }

  //peticion AJAX para actualizar estado factura
  updateState = (e) => {
    e.preventDefault();


    axios.put(this.url + 'factura/actualizada/' + this.props.entrada, {estado_factura:'aprobada'},this.config ).then(res =>{

      if(res.data){
        this.setState({
          status:"success",
        })
        swal("Estado factura actualizado",{icon:'success'});
        this.createNotification();
      }

    }).catch(err =>{
      swal("Estado factura no actualizado!!!",{icon:'warning'});
    })
  };

  render() {
    if(this.state.status === 'success'){
      return (<Redirect to="/jefeObra/pedido"/>);
    }
    return (
      <div id="administracion">
        <Header
          buzon="Buzon"
          visible="true"
          visibleBU="true"
        />
        <Slider
          title="Jefe Obra"
          size="slider-small"
          visibleJO="true"
        />
        <div className="center">
          <section id="content">
            <p className="subheader">Factura proveedor {this.props.empresa}</p>
            <div id="search" className="slidebar-item">
              <p className="subheader">Datos Factura</p>
              <form className="mid-form-formulario-2">
                <div className="form-group">
                  <label htmlFor="entrada">
                    Entrada:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.entrada}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="cif">
                    CIF: <p style={{ fontWeight: 12 }}>{this.props.cif}</p>
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="empresa">
                    Proveedor:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.empresa}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="nº_factura">
                    Nº factura:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.nº_factura}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="fecha_emision">
                    Fecha emisión:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.fecha_emision}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="fecha_entrada">
                    Fecha entrada:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.fecha_entrada}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="total_factura">
                    Total factura:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.total_factura1}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="base_imponible">
                    Forma de pago:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.forma_pago}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="por_iva">
                    Fecha prevista pago:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.props.fecha_prevista_pago}
                    </p>
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="base_imponible">
                    Fecha real pago:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.props.fecha_real_pago}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="base_imponible">
                    Cod proyecto:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.cod_proyecto}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="base_imponible">
                    Denominación:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.denominacion}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="pedido">
                    Número pedido:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.pedido}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="pedido">
                    Estado:{" "}
                    <p style={{ fontWeight: 12 }}>{this.props.estado}</p>
                  </label>
                </div>
                <div className="form-group"></div>
                <div className="form-group"></div>
              </form>
            </div>
            <div id="search" className="slidebar-item">
              <form className="mid-form-formulario">
                <div className="form-group">
                  <label htmlFor="Dpto. Administracion">
                    Observaciones 
                  </label>
                  <p className="p-required">(* Este campo es obligatorio(no  aceptado))</p>
                  <textarea
                    name="Dpto. Administracion"
                    id="Dpto. Administracion"
                    onChange={this.changeStateMail}
                    ref={this.obsRef}
                  ></textarea>
                </div>
                <div className="form-group"></div>
                <div className="form-group"></div>
                <input
                  type="submit"
                  name="submit"
                  value="si aceptada"
                  onClick={this.updateState}
                  className="btn btn-success"
                />

                <input
                  type="submit"
                  name="submit"
                  value="no aceptada"
                  onClick={this.sendEmail}
                  className="btn btn-success"
                />
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default DatosFacturaJO;
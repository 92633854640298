import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import { Redirect } from "react-router-dom";
import Slider from "../../components/slider";
import swal from "sweetalert";

class RegistroJefeGrupoAdmin extends Component {
  //url base API
  url = Global.url;

  //referencias campos formularios
  nombreRef = React.createRef();
  apellidosRef = React.createRef();
  emailRef = React.createRef();
  codProyectoRef = React.createRef();
  denominacionref = React.createRef();
  estRef = React.createRef();

  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  state = {
    jefeObra: {},
    status: null,
  };

  changeStateBossWork = () => {
    this.setState({
      jefeObra: {
        nombre: this.nombreRef.current.value,
        apellidos: this.apellidosRef.current.value,
        email: this.emailRef.current.value,
        cod_proyecto: this.codProyectoRef.current.value,
        denominacion: this.denominacionref.current.value,
        estado:this.estRef.current.value,
      },
    });
  };

  registroJefeObra = (e) => {
    e.preventDefault();

    //guardamos estado formulario
    this.changeStateBossWork();

    //peticion AJAX
    axios
      .post(this.url + "crear/jefe/grupo", this.state.jefeObra, this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            status: "success",
          });
          swal("Jefe grupo registrado correctamente!", {
            icon: "success",
          });
        }
      })
      .catch((err) => {
        swal("El jefe de grupo no pudo ser registrado correctamente!", {
          icon: "warning",
        });
      });
  };

  render() {
    if (this.state.status === "success") {
      return <Redirect to="/jefe/grupo/usuario" />;
    }
    return (
      <div id="jefe-obra">
        <Header
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
          visible="true"
          visibleBU="true"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />

        <div className="center">
          <section id="content">
            <h1 className="subheader">Datos jefe grupo</h1>
            <form
              className="mid-form-formulario"
              onSubmit={this.registroJefeObra}
            >
              <div className="form-group">
                <label htmlFor="Nombre">Nombre</label>
                <input
                  type="text"
                  name="Nombre"
                  id="Nombre"
                  ref={this.nombreRef}
                  onChange={this.changeStateBossWork}
                />
              </div>

              <div className="form-group">
                <label htmlFor="Apellidos">Apellidos</label>
                <input
                  type="text"
                  name="Apellidos"
                  id="Apellidos"
                  ref={this.apellidosRef}
                  onChange={this.changeStateBossWork}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  ref={this.emailRef}
                  onChange={this.changeStateBossWork}
                />
              </div>

              <div className="form-group">
                <label htmlFor="cod_proyecto">Cod proyecto</label>
                <input
                  type="text"
                  name="cod_proyecto"
                  id="cod_proyecto"
                  ref={this.codProyectoRef}
                  onChange={this.changeStateBossWork}
                />
              </div>

              <div className="form-group">
                <label htmlFor="denominacion">Denominación</label>
                <input
                  type="text"
                  name="denominacion"
                  id="denominacion"
                  ref={this.denominacionref}
                  onChange={this.changeStateBossWork}
                />
              </div>

              <div className="form-group">
              <label htmlFor="estado">Estado</label>
                <input
                  type="text"
                  name="estado"
                  id="estado"
                  defaultValue={'ALTA'}
                  ref={this.estRef}
                  onChange={this.changeStateBossWork}
                />
              </div>
              <div className="clearfix"></div>

              <input
                type="submit"
                value="Crear Jefe GP."
                className="btn btn-success"
              />
            </form>
          </section>

          <div className="clearfix"></div>
          <br />
        </div>
      </div>
    );
  }
}

export default RegistroJefeGrupoAdmin;

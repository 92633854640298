import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import Slider from "../../components/slider";
import swal from "sweetalert";
import TablaJefeObra from "../tablas/TablaJefeObra";


class BajaJefeObra extends Component {
  //url base API
  url = Global.url;

  state = {
    jefeObra: {},
    page: 0,
    rowsPerPage: 3,
    status: null,
  };

  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  componentDidMount(){
    this.getBossWork();
  }

  getBossWork = () => {
    //peticion AJAX
    axios
      .get(this.url + "jefe/obra", this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            jefeObra: res.data,
          });
        }
      })
      .catch((err) => {
        swal("No hay jefe de obras que mostrar!!!", {
          icon: "warning",
        });
      });


  };


  
  render() {

    return (
      <div id="jefe-obra">
        <Header
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />
        <TablaJefeObra jefeObra={this.state.jefeObra} visible="true"/>
       
      </div>
    );
  }
}

export default BajaJefeObra;

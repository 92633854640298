import React, { Component } from "react";
import Notification from "./Notification";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/images/LOGO_SIMA-02 (1).jpg";

class Buzon extends Component {
  state = {
    visible: false,
  };

  openDrawer = () => {
    if (this.state.visible === false) {
      this.setState({
        visible: true,
      });
    } else {
      this.setState({
        visible: false,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.visible === true ? (
          <div className="sidenav">
            <header id="header">
              <div className="center">
                {/*LOGO*/}
                <div id="logo-drawer">
                  <img src={logo} className="app-logo" alt="logotipo" />
                </div>
              </div>
            </header>
            <MenuIcon
              onClick={this.openDrawer}
              className="btn-sidenav"
              style={{ float: "right" }}
            />
            <p>Buzon</p>
         

            
            <Notification
              visible={this.props.visible}
              count={this.props.count}
              content={this.props.content}
              title={this.props.title}
            />
          </div>
        ) : (
          <div className="sidenav2" onClick={this.openDrawer}>
            <MenuIcon onClick={this.openDrawer} className="btn-sidenav2" />{" "}
            Buzon
          </div>
        )}
      </>
    );
  }
}

export default Buzon;

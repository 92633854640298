import React, { Component } from "react";
import Header from "./Header";
import Slider from "./slider";
import axios from "axios";
import Global from "../Global";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";

class AmpliacionPagoPedido extends Component {
  //url general peticiones
  url = Global.url;
  path = "https://drive.google.com/file/d/";
  texto = "";
  numPedido = "";
  id = 0;
  factura = [];
  pedido = [];
  totalFactura = 0;
  importTotPed = "";

  numPedidoRef = React.createRef();
  jefeRef = React.createRef();
  direRef = React.createRef();
  pagRef = React.createRef();
  obsRef = React.createRef();

  state = {
    facturas: [],
    pedido: [],
    email: [],
    isAuthenticated: true,
    suma: [],
    entrada: 0,
    image: "",
    page: 0,
    rowsPerPage: 3,
    status: null,
  };

  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  componentDidMount() {
    this.getBills();
  }
  getImageBills = (e) => {
    this.texto = e.target.dataset.txt;

    axios
      .get(this.url + "facturas", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].path_factura === this.texto) {
            this.setState({
              facturas: res.data[i],
              entrada: res.data[i].id,
            });

            axios
              .get(this.url + "factura/imagen/" + res.data[i].id, this.config)
              .then((res) => {
                this.setState({
                  image: res.data,
                });

                window.open(this.path + res.data);
              })
              .catch((err) => {
                swal("Upps, no se encontraron facturas!!", { icon: "warning" });
              });
          }
        }
      })
      .catch((err) => {
        swal("Upps, no se encontraron facturas!!", { icon: "warning" });
      });
  };

  getBillDate = (e) => {
    this.numPedido = e.target.dataset.txtx;
    axios
      .get(this.url + "facturas", this.config)
      .then((bills) => {
        axios
          .get(this.url + "pedidos", this.config)
          .then((orders) => {
            for (var i = 0; i < bills.data.length; i++) {
              for (var x = 0; x < orders.data.length; x++) {
                if (bills.data[i].num_pedido === orders.data[x].num_pedido) {
                  this.totalFactura += bills.data[i].total_factura1;
                  this.importTotPed = orders.data[x].importe_total_real;
                  this.id = orders.data[x].id;
                  if (
                    parseFloat(this.totalFactura) >
                    parseFloat(this.importTotPed)
                  ) {
                    this.setState({
                      suma: {
                        num: orders.data[x].num_pedido,
                        total: this.totalFactura,
                        import: this.importTotPed,
                      },
                    });

                    this.totalFactura = 0;
                  }
                }
              }
            }
          })
          .catch((err) => {
            swal("No se pudo encontrar facturas!!", { icon: "warning" });
          });
      })
      .catch((err) => {
        swal("No se pudo encontrar pedidos!!", { icon: "warning" });
      });
  };

  //lista de facturas de proveedores
  getBills = () => {
    axios.get(this.url + "pedidos", this.config).then((order) => {
      axios
        .get(this.url + "facturas", this.config)
        .then((res) => {
          for (var x = 0; x < order.data.length; x++) {
            for (var i = 0; i < res.data.length; i++) {
              if (
                res.data[i].estado_factura === "pendiente firma jefe obra" &&
                order.data[x].num_pedido === res.data[i].num_pedido
              ) {
               this.pedido[x] = order.data[x];
               this.factura[i] = res.data[i];
               this.setState({
                 pedido:this.pedido,
               })
               this.totalFactura =
              parseFloat(res.data[i].total_factura1) +
              parseFloat(this.totalFactura);
             
              if (
                parseFloat(this.totalFactura) >
                parseFloat(this.state.pedido[x].importe_total_real)
              ) {

                this.setState({
                  facturas: this.factura,
                  image: "",
                  status: "success",
                });
               this.totalFactura = 0;
              }
              }
            }
          }
        })
        .catch((err) => {

          if (401) {
            swal("Tiempo de sesión excedido, logueese de nuevo, por favor!! ", {
              icon: "info",
            });
            this.setState({
              isAuthenticated: false,
            });
          } else {
            swal("Upps, no se encontraron facturas!! ", { icon: "warning" });
          }
          
        });
    });
  };

  updateOrder = (e) =>{
    e.preventDefault();
  axios.put(this.url + 'actualizar/pedido/' + this.id , {importe_total_real:this.pagRef.current.value}, this.config).then(order =>{

    if(order.data){
      swal('Plazo pago pedido actualizado correctamente!!',{icon:'success'});
    }

  }).catch(err =>{
    swal('No se pudo actualizar el pedido!!',{icon:'warning'});
  }) 
  }

  setStateEmail = () =>{

    this.setState({

      email:{
        emailTo:  this.jefeRef.current.value, //jefe obra
        email: this.direRef.current.value, //email contacto
        name:
        this.direRef.current.value,
        title: 
        'Plazo pago pedido aceptado: ' 
        + 'Pedido: ' + this.state.suma.num 
        +  ', Importe pedido: ' + this.state.suma.import 
        + ', Ampliación pedido: ' + this.pagRef.current.value
        + ', Total facturas: ' +this.state.suma.total,
      }
    })
  }
  sendEmail = (e) =>{
    e.preventDefault();
    axios.post(this.url + 'email',this.state.email, this.config).then(email =>{
      if(email.data){
        swal('Email enviado correctamente!! ',{icon:'success'});
      }

     }).catch(err =>{
       swal('No se pudo enviar el email!!',{icon:'warning'});
     });
  }
  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });

  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.facturas.length * this.state.rowsPerPage
    );

  render() {
    if (!this.state.isAuthenticated) return <Redirect to="/home" />;
    return (
      <div className="Gestion">
        <Header
          buzon="Buzon"
          proyectos="Proyectos"
          facturas="Facturas"
          pedidos="Pedidos"
          calidad="Calidad"
          gestion="Gestion"
          visible="true"
          visibleBU="true"
        />
        <Slider title="Dirección" size="slider-small" />
        <div className="center">
          <section id="content">
            <h1 className="subheader">Facturas proveedores</h1>

            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Entrada</TableCell>
                      <TableCell align="left">Cif</TableCell>
                      <TableCell align="left">Proveedor</TableCell>
                      <TableCell align="left">Nº factura</TableCell>
                      <TableCell align="left">Fecha emisión</TableCell>
                      <TableCell align="left">Fecha entrada</TableCell>
                      <TableCell align="left">Total factura</TableCell>
                      <TableCell align="left">Forma pago</TableCell>
                      <TableCell align="left">Fecha prevista pago</TableCell>
                      <TableCell align="left">Fecha real pago</TableCell>
                      <TableCell align="left">Cod Proyecto</TableCell>
                      <TableCell align="left">Denominación</TableCell>
                      <TableCell align="left">Pedido</TableCell>
                      <TableCell align="left">Factura</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.facturas.length >= 0
                      ? this.state.facturas
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left">{row.id}</TableCell>
                              <TableCell align="left">{row.cif}</TableCell>
                              <TableCell align="left">{row.empresa}</TableCell>
                              <TableCell align="left">
                                {row.nº_factura}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_emision}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_entrada}
                              </TableCell>

                              <TableCell align="left">
                                {row.total_factura1}
                              </TableCell>
                              <TableCell align="left">
                                {row.forma_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_prevista_pago}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_real_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.cod_proyecto}
                              </TableCell>
                              <TableCell align="left">
                                {row.denominacion}
                              </TableCell>

                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value={row.num_pedido}
                                  data-txtx={row.num_pedido}
                                  onClick={this.getBillDate}
                                  className="btn-table2"
                                />
                              </TableCell>
                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value=""
                                  data-txt={row.path_factura}
                                  onClick={this.getImageBills}
                                  className="btn-table"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                      : null}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={14} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 3, 10]}
                  count={
                    this.state.facturas.length >= 0
                      ? this.state.facturas.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
          </section>
          <div id="search" className="slidebar-item">
            <form className="mid-form-formulario" id="formulario">
              <div className="form-group"></div>
              <div className="form-group">
                <p className="subheader">Envio email</p>
              </div>
              <div className="form-group"></div>
              <div className="form-group">
                <label htmlFor="pedido">
                  Número pedido:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.suma.num}</p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="pedido">
                  Importe pedido:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.suma.import}</p>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="pedido">
                  Total facturas:{" "}
                  <p style={{ fontWeight: 12 }}>{this.state.suma.total}</p>
                </label>
              </div>

              <div className="form-group">
           
              </div>
              <div className="form-group"></div>
              <div className="form-group"></div>
              <div className="form-group">
                <label htmlFor="jefeobra">Email jefe obra </label>
                <input
                  type="text"
                  name="jefeobra"
                  id="jefeobra"
                  onChange={this.setStateEmail}
                  ref={this.jefeRef}
                />
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Email dirección</label>
                <input
                  type="text"
                  name="direccion"
                  id="direccion"
                  onChange={this.setStateEmail}
                  ref={this.direRef}
                />
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Ampliación pedido</label>
                <input
                  type="text"
                  name="direccion"
                  id="direccion"
                  onChange={this.setStateEmail}
                  ref={this.pagRef}
                />
              </div>
 
              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="Ampliar ped."
                  onClick={this.updateOrder}
                  className="btn btn-success"
                />
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="Enviar email."
                  onClick={this.sendEmail}
                  className="btn btn-success"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AmpliacionPagoPedido;

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Slider from "../../components/slider";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import swal from "sweetalert";

class RegistroProveedorAdmin extends Component {
  //url base para peticiones
  url = Global.url;
  //campos dtos del contacto
  nombreRef = React.createRef();
  apellidosRef = React.createRef();
  roleRef = React.createRef();
  cEmpresaRef = React.createRef();
  dniRef = React.createRef();
  nomEmpresaRef = React.createRef();
  cifRef = React.createRef();
  emailRef = React.createRef();
  movilRef = React.createRef();
  fijoRef = React.createRef();
  bancoPagoRef = React.createRef();
  banco2PagoRef = React.createRef();
  banco3PagoRef = React.createRef();
  cuentaBancoRef = React.createRef();
  cuenta2BancoRef = React.createRef();
  cuenta3BancoRef = React.createRef();
  formaRef = React.createRef();
  forma2Ref = React.createRef();
  forma3Ref = React.createRef();

  //estado registro proveedor
  state = {
    user: {},
    status: null,
  };
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  //dar valores campos provveedores
  changeStateProvider = () => {
    this.setState({
      user: {
        nombre: this.nombreRef.current.value,
        apellidos: this.apellidosRef.current.value,
        nombre_rol: this.roleRef.current.value,
        cod_proyecto: "no asignado",
        denominacion: "no asignado",
        cargo_empresa: this.cEmpresaRef.current.value,
        direccion: this.dniRef.current.value,
        nombre_empresa: this.nomEmpresaRef.current.value,
        cif: this.cifRef.current.value,
        email: this.emailRef.current.value,
        tlfn_movil: this.movilRef.current.value,
        tlfn_fijo: this.fijoRef.current.value,
        forma_pago1: this.formaRef.current.value,
        forma_pago2: this.forma2Ref.current.value,
        forma_pago3: this.forma3Ref.current.value,
        banco_pago1: this.bancoPagoRef.current.value,
        banco_pago2: this.banco2PagoRef.current.value,
        banco_pago3: this.banco3PagoRef.current.value,
        cuenta_pago1: this.cuentaBancoRef.current.value,
        cuenta_pago2: this.cuenta2BancoRef.current.value,
        cuenta_pago3: this.cuenta3BancoRef.current.value,
        incidencias:'Sin incidencias',
      },
    });

    this.forceUpdate();
  };
  //peticion para crear proveedor
  registerProvider = (e) => {
    e.preventDefault();

    //rellenar state con formulario
    this.changeStateProvider();

    axios
      .post(this.url + "registrar", this.state.user, this.config)
      .then((res) => {
        this.setState({
          status: "success",
        });
        swal("Proveedor creado correctamente!!", { icon: "success" });
      })
      .catch((err) => {
        swal("No se pudo crear el proveedor correctamente!!", {
          icon: "warning",
        });
      });
  };

  render() {
    if (this.state.status === "success") {
      return <Redirect to="/registro/usuario" />;
    }
   
    return (
      <div id="proveedor">
        <Header
          buzon="Buzón"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
          visible="true"
          visibleBU="true"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />

        <div className="center">
          <section id="content">
            <h1 className="subheader">Datos del contacto</h1>
            <form
              className="mid-form-formulario"
              onSubmit={this.registerProvider}
            >
              <div className="form-group">
                <label htmlFor="Nombre">Nombre</label>
                <input
                  type="text"
                  name="Nombre"
                  id="Nombre"
                  ref={this.nombreRef}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="Apellidos">Apellidos</label>
                <input
                  type="text"
                  name="Apellidos"
                  id="Apellidos"
                  ref={this.apellidosRef}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="nombre_rol">Role</label>
                <input
                  type="text"
                  name="nombre_rol"
                  id="nombre_rol"
                  ref={this.roleRef}
                  defaultValue="proveedores"
                  disabled={true}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="cargo_empresa">Cargo empresa</label>
                <input
                  type="text"
                  name="cargo_empresa"
                  id="cargo_empresa"
                  ref={this.cEmpresaRef}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Dirección</label>
                <input
                  type="text"
                  name="direccion"
                  id="direccion"
                  ref={this.dniRef}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="Nombre Empresa">Nombre Empresa</label>
                <input
                  type="text"
                  name="Nombre Empresa"
                  id="Nombre Empresa"
                  ref={this.nomEmpresaRef}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="CIF">CIF</label>
                <input
                  type="text"
                  name="CIF"
                  id="CIF"
                  ref={this.cifRef}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="Email">Email</label>
                <input
                  type="text"
                  name="Email"
                  id="Email"
                  ref={this.emailRef}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="Tlfno movil">Tlfno movil</label>
                <input
                  type="text"
                  name="Tlfno movil"
                  id="Tlfno movil"
                  ref={this.movilRef}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="Tlfno fijo">Tlfno fijo</label>
                <input
                  type="text"
                  name="Tlfno fijo"
                  id="Tlfno fijo"
                  ref={this.fijoRef}
                  onChange={this.changeStateProvider}
                />
              </div>
              <div className="form-group"></div>
              <div className="form-group"></div>
              <div className="form-group">
                <label htmlFor="banco-pago 1">Banco de pago 1</label>
                <input
                  type="text"
                  name="banco-pago 1"
                  id="banco-pago 1"
                  defaultValue="."
                  ref={this.bancoPagoRef}
                  onChange={this.changeStateProvider}
                />
                <label htmlFor="cuenta-pago">Cuenta de pago 1</label>
                <input
                  type="text"
                  name="cuenta-pago"
                  id="cuenta-pago"
                  defaultValue="."
                  ref={this.cuentaBancoRef}
                  onChange={this.changeStateProvider}
                />
              </div>
              <div className="form-group">
                <label htmlFor="banco-pago 2">Banco de pago 2</label>
                <input
                  type="text"
                  name="banco-pago 2"
                  id="banco-pago 2"
                  defaultValue="."
                  ref={this.banco2PagoRef}
                  onChange={this.changeStateProvider}
                />
                <label htmlFor="cuenta-pago 2">Cuenta de pago 2</label>
                <input
                  type="text"
                  name="cuenta-pago 2"
                  id="cuenta-pago 2"
                  defaultValue="."
                  ref={this.cuenta2BancoRef}
                  onChange={this.changeStateProvider}
                />
              </div>
              <div className="form-group">
                <label htmlFor="banco-pago 3">Banco de pago 3</label>
                <input
                  type="text"
                  name="banco-pago 3"
                  id="banco-pago 3"
                  defaultValue="."
                  ref={this.banco3PagoRef}
                  onChange={this.changeStateProvider}
                />
                <label htmlFor="cuenta-pago 3">Cuenta de pago 3</label>
                <input
                  type="text"
                  name="cuenta-pago 3"
                  id="cuenta-pago 3"
                  defaultValue="."
                  ref={this.cuenta3BancoRef}
                  onChange={this.changeStateProvider}
                />
              </div>

              <div className="form-group">
                <label htmlFor="forma-pago">Forma de pago 1</label>
                <select
                  id="forma-pago"
                  name="forma-pago"
                  style={{ width: `${100}%` }}
                  ref={this.formaRef}
                  onChange={this.changeStateProvider}
                >
                  <option value="."></option>
                  <option value="efectivo">efectivo</option>
                  <option value="pagaré/cheque">pagaré/cheque</option>
                  <option value="transferencia">transferencia</option>
                  <option value="vencimiento">vencimiento</option>
                  <option value="confirming">confirming</option>
                  <option value="tarjeta">tarjeta</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="forma-pago">Forma de pago 2</label>
                <select
                  id="forma-pago"
                  name="forma-pago"
                  style={{ width: `${100}%` }}
                  ref={this.forma2Ref}
                  onChange={this.changeStateProvider}
                >
                  <option value="."></option>
                  <option value="efectivo">efectivo</option>
                  <option value="pagaré/cheque">pagaré/cheque</option>
                  <option value="transferencia">transferencia</option>
                  <option value="vencimiento">vencimiento</option>
                  <option value="confirming">confirming</option>
                  <option value="tarjeta">tarjeta</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="forma-pago">Forma de pago 3</label>
                <select
                  id="forma-pago"
                  name="forma-pago"
                  style={{ width: `${100}%` }}
                  ref={this.forma3Ref}
                  onChange={this.changeStateProvider}
                >
                  <option value="."></option>
                  <option value="efectivo">efectivo</option>
                  <option value="pagaré/cheque">pagaré/cheque</option>
                  <option value="transferencia">transferencia</option>
                  <option value="vencimiento">vencimiento</option>
                  <option value="confirming">confirming</option>
                  <option value="tarjeta">tarjeta</option>
                </select>
              </div>
              <div className="form-group"></div>
              <div className="clearfix"></div>

              <input
                type="submit"
                value="Crear prov."
                className="btn btn-success"
              />
            </form>
          </section>

          <div className="clearfix"></div>
          <br />
        </div>
      </div>
    );
  }
}

export default RegistroProveedorAdmin;

import React, { Component } from "react";
import Slider from "../../components/slider";
import Header from "../../components/Header";
import axios from "axios";
import Global from "../../Global";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Push from "push.js";
import logo from "../../assets/images/LOGO_SIMA_png-02 (1).png";
import swal from "sweetalert";

class JefeGrupo extends Component {
  //url base
  url = Global.url;
  //referencias campos de busqueda
  pedido = [];
  rol = '';
  name = '';
  email = '';
  user_id = '';
  uemail = '';
  pemail = '';
  uname='';

  observacionesRef = React.createRef();
  path = "https://drive.google.com/file/d/";
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  state = {
    pedidos: [],
    b_pedido: [],
    post: [],
    email: [],
    entrada: 0,
    page: 0,
    rowsPerPage: 5,
    status: null,
  };
  componentDidMount() {
    this.getPost();
  }
  acceptOrder = (e) => {
    e.preventDefault();
    axios
      .put(
        this.url + "actualizar/pedido/" + this.state.b_pedido.id,
        {
          estado: "pendiente firma administración",
        },
        this.config
      )
      .then((res) => {
        if (res.data) {
          swal("Pedido aceptado!!", { icon: "success" });
          this.setState({
            b_pedido: {
              estado: "pendiente firma administracion",
            },
          });
          this.createNotification();
        }
      })
      .catch((err) => {
        swal("Hubo algún problema al aceptar el pedido!!", { icon: "warning" });
      });
  };

  getPedidos = (e) => {
    e.preventDefault();
    this.pedido = [];
    axios.get(this.url + "pedidos", this.config).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].estado === "pendiente firma jefe grupo") {
          this.pedido[i] = res.data[i];
          this.setState({
            pedidos: this.pedido,
            status: "success",
          });

          
        }
      }
    });
  };
  getPost = () => {
    axios
      .get(this.url + "user", this.config)
      .then((user) => {
        axios
          .get(this.url + "notificacion/" + user.data.id, this.config)
          .then((res) => {
            if (res.data) {
              for (var i = 0; i < res.data.length; i++) {
                var date = new Date();
                var fech = new Date(res.data[i].created_at);

               if(fech.toISOString().split('T').splice(0,1) >= date.toISOString().split('T').splice(0,1)){
                this.setState({
                  post: {
                    title: res.data[i].data.title,
                    description: res.data[i].data.description,
                  },
                  length: res.data.length,
                });

               }
              }

              Push.create(this.state.post.title, {
                icon: `${logo}`,
                body: this.state.post.description,
                onClick: function () {
                  window.focus();
                  this.close();
                },
              });
            } else {
              this.setState({
                post: {
                  title: "Sin notificaciones",
                },
                length: 0,
              });
              // Push.Permission.request(Push.Permission.GRANTED, Push.Permission.DENIED);

              Push.create(this.state.post.title, {
                icon: `${logo}`,
                body: this.state.post.description,
                onClick: function () {
                  window.focus();
                  this.close();
                },
              });
            }
          })
          .catch((err) => {
            swal("No tienes notificaciones!!!", { icon: "warning" });
          });
      })
      .catch((err) => {
        swal("No se encontraron notificaciones para este usuario!", {
          icon: "warning",
        });
      });
  };

  getImageFactura = (e) => {
    this.texto = e.target.dataset.txt;
    
    axios.get(this.url + "pedidos", this.config).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].path_pedido === this.texto) {
          this.setState({
            proveedores: res.data[i],
            entrada: res.data[i].id,
          });

          axios
            .get(this.url + "pedido/imagen/" + res.data[i].id, this.config)
            .then((res) => {
              this.setState({
                image: res.data,
              });
             
              window.open(this.path + res.data);
            }).catch(err =>{
              swal("Upps, no se encontraron peidos!!" , {icon:'warning'});
            });;
        }
      }
    }).catch(err =>{
      swal("Upps, no se encontraron peidos!!" , {icon:'warning'});
    });;
  };

  buscarPedido = (e) => {
    const num_pedido = e.target.value;
  

    axios.get(this.url + "pedidos", this.config).then((res) => {
      if (res.data) {
        for (var i = 0; i < res.data.length; i++) {
          if (num_pedido === res.data[i].num_pedido) {
            this.setState({
              b_pedido: res.data[i],
            });
          }
        }
      }
    });
  };
  denegedOrder = () =>{
  

    axios.get(this.url + 'usuarios',this.config).then(user =>{
        user.data.forEach(element => {
          if(this.state.pedidos.proveedor === element.nombre_empresa){
            this.pemail = element.email;
          }
      });
    axios.get(this.url + 'user',this.config).then(res =>{
       this.uemail = res.data.email;
       this.uname = res.data.name;
    }).catch(err =>{
      swal('No se encontro al usuario!!',{icon:'warning'});
    })
      
    }).catch(err =>{
      swal('No se encontro al usuario!!',{icon:'warning'});
    })

  
  }
  setStateEmail = () =>{
    this.denegedOrder();
    this.setState({
      
      email:{
        emailTo:this.pemail, //proveedor pemail
        email: this.uemail, //email contacto jefe grupo uemail
        name:
        this.uname,
        title: 
        'Pedido no aceptado: ' 
        + 'Pedido: ' + this.state.b_pedido.num_pedido
        +  ', Obra: ' + this.state.b_pedido.cod_proyecto + ' ' + this.state.b_pedido.denominacion
        + ', Observaciones: ' + this.observacionesRef.current.value,
    
      },
     
    })
   
  }
  sendEmail = (e) =>{
    e.preventDefault();
    axios.post(this.url + 'email',this.state.email, this.config).then(email =>{
      if(email.data){
        swal('Email incidencia enviado correctamente!! ',{icon:'success'});
      }

     }).catch(err =>{
       swal('No se pudo enviar el email!!',{icon:'warning'});
     });
  }
  createNotification = () =>{
  
    axios.get(this.url + 'role',this.config).then(role => {
      role.data.forEach(element => {
       
          if(element.name === 'administración_responable'){
            this.rol = element.id;
          }
        
      });
      axios.get(this.url + 'role/' + this.rol,this.config).then(user =>{
        user.data.forEach(element => {
        this.name = element.name;
        this.email = element.email;
        this.user_id = element.id;
        });

        axios
        .post(
          this.url + "crear/notificacion/" + this.user_id,
          {title:this.name, description:'Aprobación pedido para ' + this.email, user_id:this.user_id},
          this.config
        )
        .then((res) => {
          if (res.data) {
            swal(res.data, { icon: "success" });
          }
        })
        .catch((err) => {
          swal("No se pudo enviar notificacion!!", { icon: "warning" });
        });

      }).catch(err =>{
        swal('Upps, no se creo la notificaión!!',{icon:'warning'});
      })
    }).catch(err =>{
      swal('Upps, no se creo la notificaión!!',{icon:'warning'});
    })
  }
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
    
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.pedidos.length * this.state.rowsPerPage
    );

  render() {
    return (
      <div id="jefe_obra">
        <Header
          buzon="Buzon"
          visible="true"
          visibleBU="true"
          
          content={this.state.post.description}
          title={this.state.post.title}
        />

        <Slider
          title="Alta pedido compra"
          size="slider-small"
          subtitle="Jefe Grupo"
          visibleJO2="true"
        />
        <div className="center">
          <section id="content">
            <h1 className="subheader">Pedido compra</h1>
            <Paper>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Número de pedido</TableCell>
                      <TableCell align="right">Cod proyecto</TableCell>
                      <TableCell align="right">Proveedor</TableCell>
                      <TableCell align="right">cif</TableCell>
                      <TableCell align="right">Fecha inicio</TableCell>
                      <TableCell align="right">Fecha fin</TableCell>
                      <TableCell align="right">
                        Retención por garantia
                      </TableCell>
                      <TableCell align="right">Importe pedido</TableCell>
                      <TableCell align="right">Plazo ejecución</TableCell>
                      <TableCell align="right">Plazo pago</TableCell>
                      <TableCell align="right">Forma pago</TableCell>
                      <TableCell align="right">Observaciones</TableCell>
                      <TableCell align="right">Ver pedido</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.pedido.length > 0 ? (
                      this.pedido
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((row, i) => (
                          <TableRow key={i}>
                            <TableCell align="right">
                              <input
                                type="submit"
                                name="submit"
                                value={row.num_pedido}
                                onClick={this.buscarPedido}
                                className="btn-table2"
                              />
                            </TableCell>
                            <TableCell align="right">
                              {row.cod_proyecto}
                            </TableCell>
                            <TableCell align="right">{row.proveedor}</TableCell>
                            <TableCell align="right">{row.cif}</TableCell>
                            <TableCell align="right">
                              {row.fecha_inicio}
                            </TableCell>
                            <TableCell align="right">{row.fecha_fin}</TableCell>

                            <TableCell align="right">
                              {row.total_retencion_garantia}
                            </TableCell>
                            <TableCell align="right">
                              {row.importe_total_real}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_ejecucion}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_pago}
                            </TableCell>

                            <TableCell align="right">
                              {row.forma_pago}
                            </TableCell>
                            <TableCell align="right">
                              {row.observaciones}
                            </TableCell>
                            <TableCell align="right">
                              <input
                                type="submit"
                                name="submit"
                                value=""
                                data-txt={row.path_pedido}
                                onClick={this.getImageFactura}
                                className="btn-table"
                              />
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      null
                    )}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={13} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 5, 10]}
                  count={this.pedido.length > 1 ? this.pedido.length : 1}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>

            <div id="search" className="slidebar-item">
              <form className="mid-form-formulario">
                <div className="form-group">
                  <label htmlFor="numero-pedido">
                    Numero pedido:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.b_pedido.num_pedido}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="cod-proyecto">
                    Cod proyecto:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.b_pedido.cod_proyecto}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="proveedor">
                    Proveedor:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.b_pedido.proveedor}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="cif">
                    Cif:{" "}
                    <p style={{ fontWeight: 12 }}>{this.state.b_pedido.cif}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="plazo-ejecucion">
                    Plazo de ejecución:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.b_pedido.plazo_ejecucion}
                    </p>
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="retecion-garantia">
                    Retención por garantia:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.b_pedido.total_retencion_garantia}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="observaciones">
                    Observaciones:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.b_pedido.observaciones}
                    </p>
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="total-factura">
                    Importe pedido:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.b_pedido.importe_total_real}
                    </p>
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="forma-pago">
                    Forma de pago:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.b_pedido.forma_pago}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="forma-pago">
                    Estado:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.b_pedido.estado}
                    </p>
                  </label>
                </div>
                <div className="form-group"></div>
                <div className="form-group"></div>
                <div className="form-group">
                  <label htmlFor="observaciones">Observaciones</label>
                  <p className="p-required">(* Campo obligatorio (no aceptado))</p>
                  <textarea
                    name="observaciones"
                    id="observaciones"
                    ref={this.observacionesRef}
                    onChange={this.setStateEmail}
                  ></textarea>
                </div>
                <div className="form-group"></div>
                <div className="form-group"></div>
                <input
                  type="submit"
                  name="submit"
                  value="No aceptado"
                  onClick={this.sendEmail}
                  className="btn btn-success"
                />
                <input
                  type="submit"
                  name="submit"
                  value="Si aceptado"
                  onClick={this.acceptOrder}
                  className="btn btn-success"
                />
                <input
                  type="submit"
                  name="submit"
                  value="refrescar"
                  onClick={this.getPedidos}
                  className="btn btn-success"
                />
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default JefeGrupo;

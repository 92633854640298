import React, { Component } from "react";
import Slider from "./slider";
import Header from "./Header";
import axios from "axios";
import Global from "../Global";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import * as moment from "moment";
import swal from "sweetalert";

class Gestoria extends Component {
  //url general peticiones
  url = Global.url;

  path = "https://drive.google.com/uc?export=download&id=";
  triRef = React.createRef();

  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  texto = "";
  factura = [];

  state = {
    proveedores: {},
    ano: 0,
    entrada: 0,
    image: "",
    page: 0,
    rowsPerPage: 50,
    status: null,
  };

  componentDidMount() {
    if (this.state.proveedores !== null) {
      this.calculateCuarter();
    }
  }
  //calculo cuatrimestre
  calculateCuarter = () => {
    var fech1 = "";
    var fech2 = "";
    fech1 = this.triRef.current.value;
    var factura1 = [];
    var factura2 = [];
    var factura3 = [];
    var factura4 = [];
    axios.get(this.url + "facturas", this.config).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].estado_factura === "facturada") {
          fech2 = res.data[i].fecha_entrada;
        
          if (fech1 === this.state.ano.toString() + "-03-31") {
            
            var primerTrimestre = moment(fech2).isBetween(
              this.state.ano.toString() + "-01-01",
              fech1
            );
           
            if (primerTrimestre === true) {
              factura1[i] = res.data[i];
            
              this.setState({
                proveedores: factura1,
              });
            }
          } else if (fech1 === this.state.ano.toString() + "-06-30") {

            var segundoTrimestre = moment(fech2).isBetween(
              this.state.ano.toString() + "-04-01",
              fech1
            );
            if (segundoTrimestre === true) {
              factura2[i] = res.data[i];
              this.setState({
                proveedores: factura2,
              });
            }
          } else if (fech1 === this.state.ano.toString() + "-09-30") {

            var tercerTrimestre = moment(fech2).isBetween(
              this.state.ano.toString() + "-07-01",
              fech1
            );
            if (tercerTrimestre === true) {
              factura3[i] = res.data[i];
              this.setState({
                proveedores: factura3,
              });
            }
          }else if (fech1 === this.state.ano.toString() + "-12-31") {

            var cuartoTrimestre = moment(fech2).isBetween(
              this.state.ano.toString() + "-10-01",
              fech1
            );
            if (cuartoTrimestre === true) {
              factura4[i] = res.data[i];
              this.setState({
                proveedores: factura4,
              });
            }
          }
        }
      }
    });
  };
  //lista de facturas de proveedores
  getFacturas = (e) => {
    e.preventDefault();
    var fecha = new Date();
    axios
      .get(this.url + "facturas", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].estado_factura === "facturada") {
            this.factura[i] = res.data[i];
            this.setState({
              proveedores: this.factura,
              ano: fecha.getFullYear(),
              image: "",
              status: "success",
            });
          }
        }
      })
      .catch((err) => {
        swal("No se pudo encontra facturas!!", { icon: "warning" });
      });
  };

  getImageFactura = (e) => {
    this.texto = e.target.dataset.txt;
    axios
      .get(this.url + "facturas", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].path_factura === this.texto) {
            this.setState({
              proveedores: res.data[i],
              entrada: res.data[i].id,
            });

            axios
              .get(this.url + "factura/descarga/" + res.data[i].id, this.config)

              .then((res) => {
                this.setState({
                  image: res.data,
                });
                window.open(this.path + res.data, "download");
              })
              .catch((err) => {
                swal("No se pudo descargar la factura!!", { icon: "warning" });
              });
          }
        }
      })
      .catch((err) => {
        swal("No se pudo encontrar las facturas!!", { icon: "warning" });
      });
  };

  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });

  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.proveedores.length * this.state.rowsPerPage
    );
  render() {
    return (
      <div className="Gestoria">
        <Header buzon="Buzon" />
        <Slider title="Gestoria" size="slider-small" />
        <div className="center">
          <section id="content">
            <h1 className="subheader">Facturas</h1>

            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Entrada</TableCell>
                      <TableCell align="left">Cif</TableCell>
                      <TableCell align="left">Proveedor</TableCell>
                      <TableCell align="left">Nº factura</TableCell>
                      <TableCell align="left">Fecha emisión</TableCell>
                      <TableCell align="left">Fecha entrada</TableCell>
                      <TableCell align="left">Total factura</TableCell>
                      <TableCell align="left">Forma pago</TableCell>
                      <TableCell align="left">Fecha prevista pago</TableCell>
                      <TableCell align="left">Fecha real pago</TableCell>
                      <TableCell align="left">Cod Proyecto</TableCell>
                      <TableCell align="left">Denominación</TableCell>
                      <TableCell align="left">Pedido</TableCell>
                      <TableCell align="left">Nombre factura</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.proveedores.length >= 0
                      ? this.state.proveedores
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          ).sort((a,b) => a.empresa > b.empresa ? 1 : -1)
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left">{row.id}</TableCell>
                              <TableCell align="left">{row.cif}</TableCell>
                              <TableCell align="left">{row.empresa}</TableCell>
                              <TableCell align="left">
                                {row.nº_factura}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_emision}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_entrada}
                              </TableCell>

                              <TableCell align="left">
                                {row.total_factura1}
                              </TableCell>

                              <TableCell align="left">
                                {row.forma_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_prevista_pago}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_real_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.cod_proyecto}
                              </TableCell>
                              <TableCell align="left">
                                {row.denominacion}
                              </TableCell>

                              <TableCell align="left">
                                {row.num_pedido}
                              </TableCell>
                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value=""
                                  data-txt={row.path_factura}
                                  onClick={this.getImageFactura}
                                  className="btn-table"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                      : null}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={14} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 50, 100]}
                  count={
                    this.state.proveedores.length >= 0
                      ? this.state.proveedores.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
          </section>
          <div className="clearfix"></div>
          <p className="subheader">Buscar factura</p>
          <form className="mid-form-formulario">
            <div className="form-group">
              <label htmlFor="cuatrimestre">Cuatrimestre</label>
              <select
                id="cuatrimestre"
                name="cuatrimestre"
                onChange={this.calculateCuarter}
                ref={this.triRef}
              >
                <option value="0"></option>
                <option value={this.state.ano.toString() + "-03-31"}>
                  Primer trimestre
                </option>
                <option value={this.state.ano.toString() + "-06-30"}>
                  Segundo trimestre
                </option>
                <option value={this.state.ano.toString() + "-09-30"}>
                  Tercer trimestre
                </option>
                <option value={this.state.ano.toString() + "-12-31"}>
                  Cuarto trimestre
                </option>
              </select>
            </div>
            <div className="form-group"></div>
            <div className="form-group"></div>
            <input
              type="submit"
              name="submit"
              value="Refrescar"
              className="btn btn-success"
              onClick={this.getFacturas}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default Gestoria;

import React, { Component } from "react";
import Slider from "../../components/slider";
import Header from "../../components/Header";
import Administracion from "../administracion/Administracion";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";
import Push from "push.js";
import logo from "../../assets/images/LOGO_SIMA_png-02 (1).png";

class ConsultaFacturaAdmin extends Component {
  //url general peticiones
  url = Global.url;
  
  state = {
    post: [],
    status: null,
    length:'',
  };
 
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  componentDidMount() {
    this.getPost();
  
  }
  getPost = () => {
  
    axios.get(this.url + 'user',this.config).then(user =>{

    axios.get(this.url + "notificacion/" + user.data.id, this.config).then((res) => {
      if (res.data) {

        for (var i = 0; i < res.data.length; i++) {
          var date = new Date();
          var fech = new Date(res.data[i].created_at);
          if(fech.toISOString().split('T').splice(0,1) >= date.toISOString().split('T').splice(0,1)){
          this.setState({
            post: {
              title: res.data[i].data.title,
              description: res.data[i].data.description,
            },
            length:res.data.length,
          });

          Push.create(this.state.post.title, {
            icon: `${logo}`,
            body: this.state.post.description,
            timeout: 4000,
            onClick: function () {
              
              window.focus();
              this.close();
            },
          });
        }
      }


      }else{
       
        this.setState({
          post: {
            title: 'Sin notificaciones',
           
          },
          length:0,
        });
      
          Push.create(this.state.post.title, {
            icon: `${logo}`,
            body: this.state.post.description,
            timeout: 4000,
            onClick: function () {
              
              window.focus();
              this.close();
            },
          });
      }
    }).catch(err =>{
      swal("No tienes notificaciones!!!",{icon:'warning'});
    });   
  }).catch(err =>{
    swal('No se encontraron notificaciones para este usuario!',{icon:'warning'});
  })
  };

  render() {
    return (
      <div className="administracion">
       
        <Header
        buzon="Buzon"
        facturas="Facturación"
        proyectos="Proyectos"
        pedidos="Pedidos"
        visible="true"
        visibleBU="true"
        count={ this.state.length + ', Notificacion/es.'}
        content={this.state.post.description}
        title={this.state.post.title}
        />
  

        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />
        <Administracion />
      </div>
    );
  }
}

export default ConsultaFacturaAdmin;

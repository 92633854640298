import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import { Redirect } from "react-router-dom";
import Slider from "../../components/slider";
import swal from "sweetalert";

class RegistroObras extends Component {
  //url base para peticiones
  url = Global.url;

  //datos campos registro usuario
  codRef = React.createRef();
  denoRef = React.createRef();
  descRef = React.createRef();
  obsRef = React.createRef();
  estRef = React.createRef();

  //estado
  state = {
    obra: {},
    status: null,
  };

  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  changeStateObra = () => {
    this.setState({
      obra: {
        cod_proyecto: this.codRef.current.value,
        denominacion: this.denoRef.current.value,
        descripcion: this.descRef.current.value,
        observaciones: this.obsRef.current.value,
        estado: this.estRef.current.value,
      },
    });
  };

  //peticiones AJAX

  crearObra = (e) => {
    e.preventDefault();
    //cambiar estado
    //this.changeStateObra();

    //crear obra
    axios
      .post(this.url + "registro/obra", this.state.obra, this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            status: "success",
          });
          swal("Obra registrada con exito!", {
            icon: "success",
          });
        }
      })
      .catch((err) => {
        swal("Hubo un problema al registrar la obra!", {
          icon: "warning",
        });
      });
  };

  render() {
    if (this.state.status === "success") {
      return <Redirect to="/home/administracion" />;
    }
    return (
      <div id="obras">
        <Header
          
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
          visible="true"
          visibleBU="true"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />

        <aside id="slidebar">
          <h1 className="subheader">Datos obra</h1>
          <div id="search" className="slidebar-item">
            <form className="mid-form" onSubmit={this.crearObra}>
              <div className="form-group-login">
                <label htmlFor="codigo_proyecto">Cod proyecto</label>
                <input
                  type="text"
                  name="codigo_proyecto"
                  id="codigo_proyecto"
                  onChange={this.changeStateObra}
                  ref={this.codRef}
                />
              </div>



              <div className="form-group-login">
                <label htmlFor="denominacion">Denominación</label>
                <input
                  type="text"
                  name="denominacion"
                  id="denominacion"
                  onChange={this.changeStateObra}
                  ref={this.denoRef}
                />
              </div>

              <div className="form-group-login">
                <label htmlFor="descripcion">Descripción</label>
                <input
                  type="text"
                  name="descripcion"
                  id="descripcion"
                  onChange={this.changeStateObra}
                  ref={this.descRef}
                />
              </div>
              <div className="form-group-login">
                <label htmlFor="estado">Estado</label>
                <input
                  type="text"
                  name="estado"
                  id="estado"
                  defaultValue={'ALTA'}
                  onChange={this.changeStateObra}
                  ref={this.estRef}
                />
              </div>

              <div className="form-group-login">
                <label htmlFor="observaciones">Observaciones</label>
                <textarea
                  name="observaciones"
                  id="observaciones"
                  defaultValue={"No hay."}
                  onChange={this.changeStateObra}
                  ref={this.obsRef}
                />
              </div>

              <div className="form-group-login"></div>
              <div className="clearfix"></div>
              <div className="form-group-login"></div>

              <input
                type="submit"
                value="Crear obra."
                className="btn btn-success"
              />
            </form>
          </div>

          <div className="clearfix"></div>
          <br />
        </aside>
      </div>
    );
  }
}

export default RegistroObras;

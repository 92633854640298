import React, { Component } from "react";
import Slider from "../slider";
import Header from "../Header";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";
import InputFiles from "react-input-files";
import Push from "push.js";
import logo from "../../assets/images/LOGO_SIMA_png-03 (1).png";

class JefeObra extends Component {
  //url base
  url = Global.url;

  //campos a enviar formulario
  numPedidoRef = React.createRef();
  codProyectoRef = React.createRef();
  denoRef = React.createRef();
  proveedorRef = React.createRef();
  cifRef = React.createRef();
  plazoejRef = React.createRef();
  fechFinRef = React.createRef();
  fechIniRef = React.createRef();
  plazoRef = React.createRef();
  porRetGaRef = React.createRef();
  retencionGaRef = React.createRef();
  retencionGaTlRef = React.createRef();
  observacionesRef = React.createRef();
  importeRef = React.createRef();
  formaRef = React.createRef();
  biRef = React.createRef();

  //estado
  state = {
    pedido: {},
    post: [],
    pedidos: [],
    notificacion: {},
    status: null,
  };
  //imagen
  selectedFileRef = [];
  //variables para calcular retencion garantia
  baseTaxable = 0;
  porWarranty = 0;
  retWarranty = 0;
  totWarranty = 0;
  importTotal = 0;
  //variables para crear notificacion
  id_obra = "";
  email_obra = "";
  id_grupo = "";

  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  componentDidMount() {
    this.getOrder();
    this.getPost();
  }
  //cambio de estado
  changeState = () => {

  };
  clearState = () =>{

    this.proveedorRef.current.value = '';
    this.cifRef.current.value= '';
    this.plazoejRef.current.value= '';
    this.fechIniRef.current.value= '';
    this.fechFinRef.current.value= '';
    this.importeRef.current.value= '0';
    this.biRef.current.value= '0';
    this.porRetGaRef.current.value= '0';
    this.retencionGaRef.current.value= '0';
    this.retencionGaTlRef.current.value= '0';
    this.plazoRef.current.value= '';
    this.observacionesRef.current.value= '';

    this.baseTaxable = 0;
    this.porWarranty = 0;
    this.retWarranty = 0;
    this.totWarranty = 0;
    this.importTotal = 0;
  }
  getOrder = () => {
    var fecha = new Date();
    var ano = fecha.getFullYear();
    var numPedido = 0;

    axios.get(this.url + "pedidos", this.config).then((res) => {
      axios.get(this.url + "usuarios", this.config).then((user) => {
        axios.get(this.url + "jefe/obra", this.config).then((obra) => {
          for (var i = 0; i < user.data.length; i++) {
            for (var x = 0; x < obra.data.length; x++) {
              if (user.data[i].email === obra.data[x].email) {
                numPedido = res.data.length + parseInt("1");
                this.setState({
                  pedidos: res.data,
                  pedido: {
                    num_pedido:
                      "SM/" +
                      obra.data[x].cod_proyecto +
                      "/" +
                      ano +
                      "/" +
                      numPedido,
                    cod_proyecto: obra.data[x].cod_proyecto,
                    denominacion: obra.data[x].denominacion,
                  },
                });
              }
            }
          }
        });
      });
    });
  };
  calculationRetention = () => {
    this.baseTaxable = this.biRef.current.value;
    this.porWarranty = this.porRetGaRef.current.value;

    if (this.baseTaxable !== 0) {
      this.retWarranty =
        (parseFloat(this.baseTaxable) * parseFloat(this.porWarranty)) / 100;
      this.totWarranty =
        parseFloat(this.baseTaxable) - parseFloat(this.retWarranty);

      this.importTotal =
        parseFloat(this.baseTaxable) + parseFloat(this.retWarranty);

    }
  };

  //noticamos a usuario
  notification = (id) => {
    axios
      .post(
        this.url + "crear/notificacion/" + id,
        this.state.notificacion,
        this.config
      )
      .then((res) => {
        if (res.data) {
          swal(res.data, { icon: "success" });
        }
      })
      .catch((err) => {
        swal("No se pudo enviar notificacion!!", { icon: "warning" });
      });
  };

  createNotification = () => {
    axios.get(this.url + "usuarios", this.config).then((user) => {
      axios.get(this.url + "user", this.config).then((obra) => {
        axios.get(this.url + "jefe/grupo", this.config).then((grupo) => {
          axios.get(this.url + "jefe/obra", this.config).then((res) => {
            for (var k = 0; k < grupo.data.length; k++) {
            
            
              for (var x = 0; x < user.data.length; x++) {
                if (user.data[x].email === obra.data.email) {

                  this.id_obra = user.data[x].id;
                  this.email_obra =
                    "Pedido acepatado por jefe obra: " + 
                    user.data[x].name +
                    ", email " +
                    user.data[x].email + 
                    ", pedido " + this.state.pedido.num_pedido;
                }
                for(var i=0;i<res.data.length;i++){

                  if (user.data[x].email === grupo.data[k].email && res.data[i].cod_proyecto === grupo.data[k].cod_proyecto) {
                    this.id_grupo = user.data[x].id;
                  }
                }

              }
            
           }
          

          this.setState({
            notificacion: {
              title: "Notificacion",
              description: this.email_obra,
              id: this.id_obra,
            },
          });
          this.notification(this.id_grupo);
        });
      });
    });
    });
  };
  getPost = () => {
    axios
      .get(this.url + "user", this.config)
      .then((user) => {
        axios
          .get(this.url + "notificacion/" + user.data.id, this.config)
          .then((res) => {
            if (res.data) {
              for (var i = 0; i < res.data.length; i++) {
                var date = new Date();
                var fech = new Date(res.data[i].created_at);

               if(fech.toISOString().split('T').splice(0,1) >= date.toISOString().split('T').splice(0,1)){
                this.setState({
                  post: {
                    title: res.data[i].data.title,
                    description: res.data[i].data.description,
                  },
                  length: res.data.length,
                });

              }
            }

              Push.create(this.state.post.title, {
                icon: `${logo}`,
                body: this.state.post.description,
                onClick: function () {
                  window.focus();
                  this.close();
                },
              });
            } else {
              this.setState({
                post: {
                  title: "Sin notificaciones",
                },
                length: 0,
              });
              // Push.Permission.request(Push.Permission.GRANTED, Push.Permission.DENIED);

              Push.create(this.state.post.title, {
                icon: `${logo}`,
                body: this.state.post.description,
                onClick: function () {
                  window.focus();
                  this.close();
                },
              });
            }
          })
          .catch((err) => {
            swal("No tienes notificaciones!!!", { icon: "warning" });
          });
      })
      .catch((err) => {
        swal("No se encontraron notificaciones para este usuario!", {
          icon: "warning",
        });
      });
  };
  //peticion AJAX
  createOrder = (e) => {
    e.preventDefault();


    //creamos pedido
     axios
      .post(this.url + "crear/pedido", this.state.pedido, this.config)
      .then((res) => {
        if (res.data) {
          this.setState({
            status: "success",
          });

          swal("Ok! Tu pedido se registro exitosamente!", {
            icon: "success",
          });
          //creamos notificación
         this.createNotification();
         this.saveFactura();
         this.clearState();
        } else {
          swal(
            "Upps! Hubo un problema al registrar tu pedido, intentalo de nuevo!",
            {
              icon: "warning",
            }
          );
        }
      })
      .catch((err) => {
        swal(
          "Upps! Hubo un problema al registrar tu pedido, intentalo de nuevo!",
          {
            icon: "warning",
          }
        );
      });

      
  };

  fileChange = (files) => {
    this.selectedFileRef[0] = files[0];
    this.setState({
      pedido: {
        confirmado: "no",
        num_pedido: this.numPedidoRef.current.value,
        cod_proyecto: this.codProyectoRef.current.value,
        denominacion: this.denoRef.current.value,
        proveedor: this.proveedorRef.current.value,
        cif: this.cifRef.current.value,
        plazo_ejecucion: this.plazoejRef.current.value,
        fecha_inicio: this.fechIniRef.current.value,
        fecha_fin: this.fechFinRef.current.value,
        importe_total_real: this.importeRef.current.value,
        base_imponible_retencion: this.biRef.current.value,
        porcentaje_retencion_garantia: this.porRetGaRef.current.value,
        retencion_garantia: this.retencionGaRef.current.value,
        total_retencion_garantia: this.retencionGaTlRef.current.value,
        plazo_pago: this.plazoRef.current.value,
        forma_pago: this.formaRef.current.value,
        estado: "pendiente firma jefe grupo",
        path_pedido:this.selectedFileRef[0].name,
        observaciones: this.observacionesRef.current.value,
      },
    });
  };

  saveFactura = () => {
    //año actual
    var fecha = new Date();
    var ano = fecha.getFullYear();
    //crear formdata y añadir fichero
    const formData = new FormData();
    formData.append(
      "file0",
      this.selectedFileRef[0],
      this.selectedFileRef[0].name,
    );

    var number = fecha.getMonth() + parseInt(1);

    axios.post(this.url + 'storage/create/sub/order/' + ano, {mounth:number},this.config).then(subdir =>{
      if(subdir.data){
        swal("Nuevo directorio creado!", {
          icon: "success",
        });
      }
    }).catch(err =>{
      swal("No se guardo tu archivo de pedido!!!", {
        icon: "warning",
      });
    })

    axios
      .post(this.url + "storage/create/file/order/" + number + '/' + ano,  formData , this.config)
      .then((res) => {

      })
      .catch((err) => {
        swal("Hubo algún problema al crear tu factura, intentelo de nuevo.", {
          icon: "warning",
        });
      });
  };

  render() {
    return (
      <div id="jefe_obra">
        <Header
          buzon="Buzon"
          visible="true"
          visibleBU="true"
          count={this.state.length}
          content={this.state.post.description}
          title={this.state.post.title}
        />

        <Slider
          title="Alta pedido compra"
          size="slider-small"
          subtitle="Jefe obra"
          visibleJO="true"
        />
        <div className="center">
          <section id="content">
            <h1 className="subheader">Pedido compra</h1>
            <div id="search" className="slidebar-item">
              <form id="formulario" className="mid-form-formulario">
                <div className="form-group">
                  <label htmlFor="numero-pedido">Número pedido</label>
                  <input
                    type="text"
                    name="numero-pedido"
                    id="numero-pedido"
                    defaultValue={this.state.pedido.num_pedido}
                    ref={this.numPedidoRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cod-proyecto">Cod proyecto</label>
                  <input
                    type="text"
                    name="cod-proyecto"
                    id="cod-proyecto"
                    defaultValue={this.state.pedido.cod_proyecto}
                    ref={this.codProyectoRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="denominacion">Denominación</label>
                  <input
                    type="text"
                    name="denominacion"
                    id="denominacion"
                    defaultValue={this.state.pedido.denominacion}
                    ref={this.denoRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="proveedor">Proveedor</label>
                  <input
                    type="text"
                    name="proveedor"
                    id="proveedor"
                    ref={this.proveedorRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cif">Cif</label>
                  <input
                    type="text"
                    name="cif"
                    id="cif"
                    ref={this.cifRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="fecha-inicio">Fecha inicio</label>
                  <input
                    type="text"
                    name="fecha-inicio"
                    id="fecha-inicio"
                    ref={this.fechIniRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="fecha-fin">Fecha fin</label>
                  <input
                    type="text"
                    name="fecha-fin"
                    id="fecha-fin"
                    ref={this.fechFinRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="plazo-ejecucion">Plazo de ejecución</label>
                  <input
                    type="text"
                    name="plazo-ejecucion"
                    id="plazo-ejecucion"
                    ref={this.plazoejRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="plazo-pago">Plazo pago</label>
                  <input
                    type="text"
                    name="plazo-pago"
                    id="plazo-pago"
                    defaultValue="60 días"
                    ref={this.plazoRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="base-imponible">B.I retención</label>
                  <input
                    type="text"
                    name="base-imponible"
                    id="base-imponible"
                    defaultValue={0}
                    ref={this.biRef}
                    onChange={this.changeState}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="total-factura">Importe pedido</label>
                  {this.importTotal <= 0 ? (
                    <input
                      type="text"
                      name="total-factura"
                      id="total-factura"
                      ref={this.importeRef}
                      onChange={this.changeState}
                    />
                  ) : (
                    <input
                      type="text"
                      name="total-factura"
                      id="total-factura"
                      value={`${this.importTotal}`}
                      ref={this.importeRef}
                      onChange={this.changeState}
                    />
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="forma-pago">Forma de pago</label>
                  <select
                    id="forma-pago"
                    name="forma-pago"
                    style={{ width: `${100}%` }}
                    ref={this.formaRef}
                    onChange={this.changeState}
                  >
                    <option value="efectivo">efectivo</option>
                    <option value="pagaré/cheque">pagaré/cheque</option>
                    <option value="transferencia">transferencia</option>
                    <option value="vencimiento">vencimiento</option>
                    <option value="confirming">confirming</option>
                    <option value="tarjeta">tarjeta</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="porcentaje-garantia">
                    %Retención garantia
                  </label>
                  <input
                    type="text"
                    name="porcentaje-garantia"
                    id="porcentaje-garantia"
                    defaultValue={0}
                    onChangeCapture={this.calculationRetention}
                    ref={this.porRetGaRef}
                    onChange={this.changeState}
                  />
                  <label htmlFor="retencion-garantia">Retención garantia</label>
                  <input
                    type="text"
                    name="retencion-garantia"
                    id="retencion-garantia"
                    value={`${this.retWarranty}`}
                    ref={this.retencionGaRef}
                    onChange={this.changeState}
                  />
                  <label htmlFor="total-retencion">
                    Total retención garantia
                  </label>
                  <input
                    type="text"
                    name="total-retencion"
                    id="total-retencion"
                    value={`${this.totWarranty}`}
                    ref={this.retencionGaTlRef}
                    onChange={this.changeState}
                  />
                </div>

                <div className="form-group"></div>
            
                <div className="form-group">
                  <label htmlFor="observaciones">Observaciones</label>
                  <textarea
                    name="observaciones"
                    id="observaciones"
                    ref={this.observacionesRef}
                    onChange={this.changeState}
                  ></textarea>
                </div>
                
                <div className="form-group">
                  <input
                    type="submit"
                    value="Crear pedido"
                    onClick={this.createOrder}
                    className="btn btn-success"
                  />
                </div>
                <div className="form-group"></div>
            
                <div className="form-group" id="file">
                <label htmlFor="file0" id="label-file">
                    Archivo PDF.
                  </label>
                <InputFiles
                    className="btn btn-success"
                    name="file0"
                    webkitdirectory
                    directory
                    multiple
                    onChange={(files) => this.fileChange(files)}
                  >
                    <input
                      type="submit"
                      name="submit"
                      value="Subir pedido"
                      className="btn btn-success"
                    />
                  </InputFiles>
                </div>
                
              </form>
            </div>
          </section>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default JefeObra;

import React, { Component } from "react";
import Slider from "../../components/slider";
import Header from "../../components/Header";
import axios from "axios";
import Global from "../../Global";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

class PedidosCalidad extends Component {
  //url base
  url = Global.url;
  //referencias campos de busqueda
  pedido = [];
  state = {
    pedidos: [],
    entrada: 0,
    page: 0,
    rowsPerPage: 5,
    status: null,
  };
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  getOrders = () => {
    axios.get(this.url + "pedidos",this.config).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].estado !== "pedido aceptado") {
          this.pedido[i] = res.data[i];
          this.setState({
            pedidos: res.data[i],
            status: "success",
          });

          
        }
      }
    });
  };

  searchOrders = (e) => {
    const num_pedido = e.target.value;

    axios.get(this.url + "pedidos", this.config).then((res) => {
      if (res.data) {
        for (var i = 0; i < res.data.length; i++) {
          if (num_pedido === res.data[i].num_pedido) {
            this.setState({
              pedidos: res.data[i],
              status: "success",
            });
          }
        }
      }
    });
  };
  componentDidMount() {
    this.getOrders();
  }
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
   
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.pedidos.length * this.state.rowsPerPage
    );

  render() {
    return (
      <div id="jefe_obra">
        <Header buzon="Buzon" visible="true" visibleBU="true" />

        <Slider title="Calidad y PRL" size="slider-small" visibleCA="true" />
        <div className="center">
          <section id="content">
            <h1 className="subheader">Pedidos activos</h1>
            <Paper>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Número de pedido</TableCell>
                      <TableCell align="right">Cod proyecto</TableCell>
                      <TableCell align="right">Denominación</TableCell>
                      <TableCell align="right">Proveedor</TableCell>
                      <TableCell align="right">cif</TableCell>
                      <TableCell align="right">
                        Retención por garantia
                      </TableCell>
                      <TableCell align="right">Importe pedido</TableCell>
                      <TableCell align="right">Plazo ejecución</TableCell>
                      <TableCell align="right">Plazo pago</TableCell>
                      <TableCell align="right">Forma pago</TableCell>
                      <TableCell align="right">Incidencias</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.pedido.length > 0 ? (
                      this.pedido
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((row, i) => (
                          <TableRow key={i}>
                            <TableCell align="right">
                              <input
                                type="submit"
                                name="submit"
                                value={row.num_pedido}
                                onClick={this.searchOrders}
                                className="btn-table2"
                              />
                            </TableCell>
                            <TableCell align="right">
                              {row.cod_proyecto}
                            </TableCell>
                            <TableCell align="right">
                              {row.denominacion}
                            </TableCell>
                            <TableCell align="right">{row.proveedor}</TableCell>
                            <TableCell align="right">{row.cif}</TableCell>

                            <TableCell align="right">
                              {row.total_retencion_garantia}
                            </TableCell>
                            <TableCell align="right">
                              {row.importe_total_real}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_ejecucion}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_pago}
                            </TableCell>

                            <TableCell align="right">
                              {row.forma_pago}
                            </TableCell>
                            <TableCell align="right">
                              {row.incidencias}
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow
                        key={this.state.pedidos.id}
                        onClick={this.buscarPedido}
                      >
                        <TableCell align="right">
                          {this.state.pedidos.num_pedido}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.cod_proyecto}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.denominacion}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.proveedor}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.cif}
                        </TableCell>

                        <TableCell align="right">
                          {this.state.pedidos.total_retencion_garantia}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.importe_total_real}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.plazo_ejecucion}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.plazo_pago}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.forma_pago}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.incidencias}
                        </TableCell>
                      </TableRow>
                    )}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={11} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 5, 10]}
                  count={this.pedido.length > 1 ? this.pedido.length : 1}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>

            <div id="search" className="slidebar-item">
              <form className="mid-form-formulario">
                <div className="form-group">
                  <label htmlFor="numero-pedido">
                    Numero pedido:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.pedidos.num_pedido}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="cod-proyecto">
                    Cod proyecto:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.pedidos.cod_proyecto}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="proveedor">
                    Proveedor:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.pedidos.proveedor}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="cif">
                    Cif:{" "}
                    <p style={{ fontWeight: 12 }}>{this.state.pedidos.cif}</p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="plazo-ejecucion">
                    Plazo de ejecución:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.pedidos.plazo_ejecucion}
                    </p>
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="retecion-garantia">
                    Retención por garantia:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.pedidos.total_retencion_garantia}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="observaciones">
                    Incidencias:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.pedidos.observaciones}
                    </p>
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="total-factura">
                    Importe pedido:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.pedidos.importe_total_real}
                    </p>
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="forma-pago">
                    Forma de pago:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.pedidos.forma_pago}
                    </p>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="forma-pago">
                    Estado:{" "}
                    <p style={{ fontWeight: 12 }}>
                      {this.state.pedidos.estado}
                    </p>
                  </label>
                </div>
               
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default PedidosCalidad;

import React, { Component } from "react";
import Slider from "../../components/slider";
import Header from "../../components/Header";
import axios from "axios";
import Global from "../../Global";
import Push from "push.js";
import logo from "../../assets/images/LOGO_SIMA_png-03 (1).png";
import swal from "sweetalert";
import TablaProveedor from "../tablas/TablaProveedor";

class CalidadPrl extends Component {
  //url general peticiones
  url = Global.url;

  state = {
    proveedores: [],
    post:[],
    status: null,
  };
  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  componentDidMount() {
    this.getProvider();
    this.getPost();
  }

  getProvider = () => {
    axios.get(this.url + "registro",this.config).then((res) => {
      this.setState({
        proveedores: res.data,
      });
    }).catch(err =>{
      swal("Upps, No se encontraron proveedores!!!",{icon:'warning'});
    });
  };
  getPost = () => {
  
    axios.get(this.url + 'user',this.config).then(user =>{

    axios.get(this.url + "notificacion/" + 2, this.config).then((res) => {
      if (res.data) {
        
        for (var i = 0; i < res.data.length; i++) {

          this.setState({
            post: {
              title: res.data[i].data.title,
              description: res.data[i].data.description,
            },
            length:res.data.length,
          });
        }

        Push.create(this.state.post.title, {
          
          icon: `${logo}`,
          body: this.state.post.description,
          timeout: 4000,
          onClick: function () {
            
            window.focus();
            this.close();
          },
        });
      }else{
       
        this.setState({
          post: {
            title: 'Sin notificaciones',
           
          },
          length:0,
        });
       // Push.Permission.request(Push.Permission.GRANTED, Push.Permission.DENIED);
        
        Push.create(this.state.post.title, {
          
          icon: `${logo}`,
          body: this.state.post.description,
          timeout: 4000,
          onClick: function () {
            
            window.focus();
            this.close();
          },
        });
      }
    }).catch(err =>{
      swal("No tienes notificaciones!!!",{icon:'warning'});
    });   
  }).catch(err =>{
    swal('No se encontraron notificaciones para este usuario!',{icon:'warning'});
  })
  };
  render() {
    return (
      <div className="calidad">
        <Header buzon="Buzon" 
        visible="true" 
        visibleBU="true" 
        count={this.state.length}
        content={this.state.post.description}
        title={this.state.post.title}
        />
        <Slider title="Calidad y PRL" size="slider-small" visibleCA="true" />
        <TablaProveedor />
      </div>
    );
  }
}

export default CalidadPrl;

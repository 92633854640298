import React, { Component } from "react";
import FacturaAdministracion from "../administracion/Administracion";
import Header from "../../components/Header";
import Slider from "../../components/slider";

class AdminNormal extends Component {
  render() {
    return (
      <div className="administracion">
        <Header buzon="Buzon" />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdminNormal="true"
          
        />
        
        <FacturaAdministracion />
      </div>
    );
  }
}

export default AdminNormal;

import React, { Component } from "react";
import Header from "./Header";
import Slider from "./slider";

import axios from "axios";
import Global from "../Global";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

class AmpliacionPagoEspecial extends Component {
  //url general peticiones
  url = Global.url;

  numPedidoRef = React.createRef();
  jefeRef = React.createRef();
  direRef = React.createRef();
  pagRef = React.createRef();

  id = 0;
  plazoPago = "";
  numPedido = "";

  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  pedido = [];
  state = {
    pedidos: [],
    email:[],
    entrada: 0,
    page: 0,
    rowsPerPage: 5,
    status: null,
  };

  changeStatePedido = () => {
    this.setState({
      pedido: {
        num_pedido: this.num_pedidoRef.current.value,
        proveedor: this.proveedorRef.current.value,
      },
    });
  };
  //filtrar pedidos
  getPedidosAdministracion = (e) => {
    this.id = e.target.dataset.id;
    this.plazoPago = e.target.dataset.plazo_pago;
    this.numPedido = e.target.dataset.num;

    axios.get(this.url + "pedidos", this.config)
      .then((res) => {
        if (res.data) {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].num_pedido === this.numPedido) {
              this.setState({
                pedidos: res.data[i],
                status: "success",
              });
            } else {
            }
          }
        }
      })
      .catch((err) => {
        swal("No se pudo encontrar el pedido!!!", { icon: "warning" });
      });
  };

  //ver pedidos
  getPedidos = (e) => {
    e.preventDefault();
    this.pedido = [];
    axios.get(this.url + "pedidos", this.config)
      .then((res) => {
        if (res.data) {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].plazo_pago !== "60 días") {
              this.pedido[i] = res.data[i];
              this.setState({
                pedidos: this.pedido,
                status: "failed",
              });
            }
          }
        }
      })
      .catch((err) => {
        swal("No hay pedidos pendientes!!!", { icon: "warning" });
      });
  };

  updateOrder = (e) =>{
     e.preventDefault();
     axios.put(this.url + 'actualizar/pedido/' + this.id, {plazo_pago:this.pagRef.current.value},this.config).then(order =>{
       if(order.data){
         swal("Plazo pago especial actualizado correctamente!!",{icon:'success'});
         
        
       }
     }).catch(err =>{
       swal("No se pudo actualizar plazo pago especial correctamente, intentelo de nuevo..",{icon:'warning'});
     })
  }
  setStateEmail = () =>{

    this.setState({

      email:{
        emailTo:  this.jefeRef.current.value, //jefe obra
        email: this.direRef.current.value, //email contacto
        name:
        this.direRef.current.value,
        title: 
        'Plazo pago especial pedido aceptado, ' 
        + 'Pedido: ' + this.numPedido
        +  ', Plazo pago: ' + this.state.pedidos.plazo_pago
        + ', Ampliación plazo pago especial: ' + this.pagRef.current.value
    
      },
     
    })
  }
  sendEmail = (e) =>{
    e.preventDefault();
    axios.post(this.url + 'email',this.state.email, this.config).then(email =>{
      if(email.data){
        swal('Email enviado correctamente!! ',{icon:'success'});

        this.pagRef.current.value = "";
        this.jefeRef.current.value = "";
        this.direRef.current.value = "";
      }

     }).catch(err =>{
       swal('No se pudo enviar el email!!',{icon:'warning'});
     });
  }
  useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.pedido.length * this.state.rowsPerPage
    );
  render() {
    return (
      <div className="Gestion">
        <Header
          buzon="Buzon"
          proyectos="Proyectos"
          facturas="Facturas"
          pedidos="Pedidos"
          calidad="Calidad"
          gestion="Gestion"
          visible="true"
          visibleBU="true"
        />
        <Slider title="Dirección" size="slider-small" />
        <h1 className="subheader">Pedidos proveedores</h1>
        <div className="center">
          <section id="content">
            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Número de pedido</TableCell>
                      <TableCell align="right">Cod proyecto</TableCell>
                      <TableCell align="right">Proveedor</TableCell>
                      <TableCell align="right">cif</TableCell>
                      <TableCell align="right">Fecha inicio</TableCell>
                      <TableCell align="right">Fecha fin</TableCell>
                      <TableCell align="right">
                        Retención por garantia
                      </TableCell>
                      <TableCell align="right">Importe pedido</TableCell>
                      <TableCell align="right">Plazo ejecución</TableCell>
                      <TableCell align="right">Plazo pago</TableCell>
                      <TableCell align="right">Forma pago</TableCell>
                      <TableCell align="right">Observaciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.pedido.length >= 1 ? (
                      this.pedido
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="right">
                              <input
                                type="submit"
                                name="submit"
                                value={row.num_pedido}
                                data-num={row.num_pedido}
                                data-id={row.id}
                                data-plazo_pago={row.plazo_pago}
                                onClick={this.getPedidosAdministracion}
                                className="btn-table2"
                              />
                            </TableCell>
                            <TableCell align="right">
                              {row.cod_proyecto}
                            </TableCell>
                            <TableCell align="right">{row.proveedor}</TableCell>
                            <TableCell align="right">{row.cif}</TableCell>
                            <TableCell align="right">
                              {row.fecha_inicio}
                            </TableCell>
                            <TableCell align="right">{row.fecha_fin}</TableCell>

                            <TableCell align="right">
                              {row.total_retencion_garantia}
                            </TableCell>
                            <TableCell align="right">
                              {row.importe_total_real}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_ejecucion}
                            </TableCell>
                            <TableCell align="right">
                              {row.plazo_pago}
                            </TableCell>

                            <TableCell align="right">
                              {row.forma_pago}
                            </TableCell>
                            <TableCell align="right">
                              {row.observaciones}
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow key={this.state.pedidos.id}>
                        <TableCell align="right">
                          {this.state.pedidos.num_pedido}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.cod_proyecto}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.proveedor}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.cif}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.fecha_inicio}
                        </TableCell>

                        <TableCell align="right">
                          {this.state.pedidos.fecha_fin}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.total_retencion_garantia}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.importe_total_real}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.plazo_ejecucion}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.plazo_pago}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.forma_pago}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.pedidos.observaciones}
                        </TableCell>
                      </TableRow>
                    )}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 5, 10]}
                  count={
                    this.state.pedidos.length > 1
                      ? this.state.pedidos.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
            <div className="clearfix"></div>
            <p className="subheader">Buscar pedido</p>
            <form className="mid-form-formulario">
              <div className="form-group">
                <label htmlFor="jefeobra">Email jefe obra </label>
                <input
                  type="text"
                  name="jefeobra"
                  id="jefeobra"
                  onChange={this.setStateEmail}
                  ref={this.jefeRef}
                />
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Email dirección</label>
                <input
                  type="text"
                  name="direccion"
                  id="direccion"
                  onChange={this.setStateEmail}
                  ref={this.direRef}
                />
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Ampliación pago</label>
                {this.plazo_pago === "" ? (
                  <input
                    type="text"
                    name="direccion"
                    id="direccion"
                    onChange={this.setStateEmail}
                    ref={this.pagRef}
                  />
                ) : (
                  <input
                    type="text"
                    name="direccion"
                    id="direccion"
                    defaultValue={this.plazoPago}
                    onChange={this.setStateEmail}
                    ref={this.pagRef}
                  />
                )}
              </div>

              <div className="form-group">
                <input
                  type="submit"
                  name="submit"
                  value="Ampliar pago."
                  onClick={this.updateOrder}
                  className="btn btn-success"
                />
              </div>
              <input
                type="submit"
                name="submit"
                value="Refrescar"
                className="btn btn-success"
                onClick={this.getPedidos}
              />
                           
                <input
                  type="submit"
                  name="submit"
                  value="Enviar email."
                  onClick={this.sendEmail}
                  className="btn btn-success"
                />
            </form>
          </section>
        </div>
      </div>
    );
  }
}

export default AmpliacionPagoEspecial;

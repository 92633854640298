import React, { Component } from "react";
import axios from "axios";
import Global from "../../Global";
import Header from "../../components/Header";
import Slider from "../../components/slider";
import TablaJefeGrupo from "../tablas/TablaJefeGrupo";
import swal from "sweetalert";

class ConsultaJefeGrupo extends Component{
    url = Global.url;

    config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    state = {
      jefeGrupo: {},
  
    };

    componentDidMount(){
        this.getBossWork();
    }
  
    getBossWork = () => {
      //peticion AJAX
      axios
        .get(this.url + "jefe/grupo", this.config)
        .then((res) => {
          if (res.data) {
            this.setState({
              jefeGrupo: res.data,
            });
          }
        })
        .catch((err) => {
          swal("No hay jefe de grupos que mostrar!!!", {
            icon: "warning",
          });
        });
  
  
    };
    render(){
        return(
            <div id="jefe-obra">
            <Header
              buzon="Buzon"
              facturas="Facturación"
              proyectos="Proyectos"
              pedidos="Pedidos"
              visible="true"
              visibleBU="true"
            />
            <Slider
              title="Administración"
              size="slider-small"
              visibleAdmin="true"
            />
            <TablaJefeGrupo jefeGrupo={this.state.jefeGrupo} visible="false"/>
            </div>
        );
    }
}

export default ConsultaJefeGrupo;
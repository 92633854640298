import React, { Component } from "react";
import Slider from "../slider";
import Header from "../Header";
import axios from "axios";
import Global from "../../Global";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import swal from "sweetalert";

class ObrasProyectos extends Component {
  //url general peticiones
  url = Global.url;

  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  state = {
    obras: [],
    entrada: 0,
    image: "",
    page: 0,
    rowsPerPage: 3,
    status: null,
  };

  componentDidMount(){
      this.getPlays();
  }

  //peticion AJAX para obtener listado de obras
  getPlays = () =>{

    axios.get(this.url + 'obras',this.config).then(res =>{

        this.setState({
            obras:res.data
        })

    }).catch(err =>{
        swal("No se pudo obtener listado de obras",{icon:'warning'});
    })
  }

  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.obras.length * this.state.rowsPerPage
    );

  render() {
    return (
      <div className="obras">
        <Header
         
          buzon="Buzon"
          facturas="Facturación"
          proyectos="Proyectos"
          pedidos="Pedidos"
          visible="true"
          visibleBU="true"
        />
        <Slider
          title="Administración"
          size="slider-small"
          visibleAdmin="true"
        />
        <div className="center">
          <section id="content">
            <h1 className="subheader">Obras</h1>
            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Cod proyecto</TableCell>
                      <TableCell align="left">Denominación</TableCell>
                      <TableCell align="left">Descripción</TableCell>
                      <TableCell align="left">Observaciones</TableCell>
                      <TableCell align="left">Estado</TableCell>
                    
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.obras.length >= 0
                      ? this.state.obras
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left">{row.cod_proyecto}</TableCell>
                              <TableCell align="left">{row.denominacion}</TableCell>
                              <TableCell align="left">{row.descripcion}</TableCell>
                              <TableCell align="left">{row.observaciones}</TableCell>
                              <TableCell align="left">{row.estado}</TableCell>
                            </TableRow>
                          ))
                      : null}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={5} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 3, 10]}
                  count={
                    this.state.obras.length >= 0
                      ? this.state.obras.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
          </section>
        </div>
      </div>
    );
  }
}
export default ObrasProyectos;

import React, { Component } from "react";
import Header from "./Header";
import Slider from "./slider";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ProgressTimer from "./ProgressTimer";
import { Redirect } from "react-router-dom";

class Gestion extends Component {
  state = {
    value: 0,
    remainig: 1,
    status: null,
    length: "",
  };
  TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography ><span  className="subheader">{children}</span></Typography>
          </Box>
        )}
      </div>
    );
  };

  handleChange = (event, newValue) => {
    console.log("valor ", newValue);
    this.setState({
      value: newValue,
    });
  };

  a11yProps = (index) => {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
  };

  LinkTab(props) {
    console.log("links ", props);
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }
  renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return this.moduleManagement();
      //  return  <div className="timer">Enviando</div>
    }

    return (
      <div className="timer">
        <div className="text">Restante</div>
        <div className="value">{remainingTime}</div>
        <div className="text">Segundos</div>
      </div>
    );
  };
  moduleManagement = () =>{
    if (this.state.value === 0) {
      return (
        <>
          <Redirect to="/direccion/pago"/>
        </>
      );
    }
    if (this.state.value === 1) {
      return (
        <>
          <Redirect to="/direccion/ampliacion" />
        </>
      );
    }
    if (this.state.value === 2) {
      return (
        <>
          <Redirect to="/direccion/aprobacion" />
        </>
      );
    }

  };
  
  render() {
    this.TabPanel.prototype = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };
    return (
      <div className="Gestion">
        <Header
          buzon="Buzon"
          proyectos="Proyectos"
          facturas="Facturas"
          pedidos="Pedidos"
          calidad="Calidad"
          gestion="Gestion"
          visible="true"
          visibleBU="true"
        />
         <Slider title="Dirección" size="slider-small" />
        <div className="center">
          <section id="content">
            <h1 className="subheader">Gestión facturas / pedidos</h1>

            <AppBar position="static">
                <Tabs
                  variant="fullWidth"
                  value={this.state.value}
                  onChange={this.handleChange}
                  aria-label="nav tabs example"
                  className="tab-dire"
                >
                  <this.LinkTab
                    label="Ampliación pago especial pedido"
                    {...this.a11yProps(0)}
                  />
                  <this.LinkTab
                    label=" Ampliación pago pedido"
                    {...this.a11yProps(1)}
                  />
                  <this.LinkTab
                    label="Aprobación facturas"
                    {...this.a11yProps(2)}
                  />
                </Tabs>
              </AppBar>
              <this.TabPanel value={this.state.value} index={0}>
                Ampliación pago especial pedido
              </this.TabPanel>
              <this.TabPanel value={this.state.value} index={1}>
                Ampliación pago pedido
              </this.TabPanel>
              <this.TabPanel value={this.state.value} index={2}>
                Aprobación facturas
              </this.TabPanel>

              <ProgressTimer renderTime={this.renderTime} />
          </section>
        </div>
      </div>
    );
  }
}

export default Gestion;

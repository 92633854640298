import React, { Component } from "react";
import axios from "axios";
import Global from '../../Global';

import Header from "../Header";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Slider from "../slider";
import DatosFacturaJO from "./DatosFcturaJO";
import swal from "sweetalert";

class FacturasJefeObra extends Component {
  //url general peticiones
  url = Global.url;

  path = "https://drive.google.com/file/d/";

  //header
  config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  texto = "";
  factura = [];

  state = {
    proveedores: [],
    entrada: 0,
    image: "",
    page: 0,
    rowsPerPage: 10,
    status: null,
  };
  componentDidMount() {
    this.getFacturas();
  }

  //lista de facturas de proveedores
  getFacturas = () => {
    axios
      .get(this.url + "facturas", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].estado_factura === "pendiente firma jefe obra") {
            this.factura[i] = res.data[i];
            this.setState({
              proveedores: this.factura,
              image: "",
              status: "success",
            });
          }
        }
      })
      .catch((err) => {
        swal("No se pudo encontra facturas!!", { icon: "warning" });
      });

  };

  getImageFactura = (e) => {
    this.texto = e.target.dataset.txt;
    axios
      .get(this.url + "facturas", this.config)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].path_factura === this.texto) {
            this.setState({
              proveedores: res.data[i],
              entrada: res.data[i].id,
            });

            axios
              .get(this.url + "factura/imagen/" + res.data[i].id, this.config)

              .then((res) => {
                this.setState({
                  image: res.data,
                });
                window.open(this.path + res.data);
               
              })
              .catch((err) => {
                swal("No se pudo encontra la factura!!", { icon: "warning" });
              });
              i = res.data.length;
          }
        }
       
      })
      .catch((err) => {
        swal("No se pudo encontrar la factura!!", { icon: "warning" });
      });
     
      
  };

  useStyles = makeStyles({
    table: {
      width: 100 + "%",
    },
  });

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });

  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.state.proveedores.length * this.state.rowsPerPage
    );

  render() {
    if (this.state.image !== "") {
      return (
        <DatosFacturaJO
          image={this.state.image}
          entrada={this.state.entrada}
          cif={this.state.proveedores.cif}
          empresa={this.state.proveedores.empresa}
          nº_factura={this.state.proveedores.nº_factura}
          fecha_emision={this.state.proveedores.fecha_emision}
          fecha_entrada={this.state.proveedores.fecha_entrada}
          total_factura1={this.state.proveedores.total_factura1}
          forma_pago={this.state.proveedores.forma_pago}
          fecha_prevista_pago={this.state.proveedores.fecha_prevista_pago}
          fecha_real_pago={this.state.proveedores.fecha_real_pago}
          cod_proyecto={this.state.proveedores.cod_proyecto}
          denominacion={this.state.proveedores.denominacion}
          pedido={this.state.proveedores.num_pedido}
          estado={this.state.proveedores.estado_factura}
        />
      );
    }
    return (
      <div className="administracion">
        <Header buzon="Buzon" visible="true" visibleBU="true" />
        <Slider title="Jefe obra" size="slider-small" visibleJO="true" />
        <div className="center">
          <section id="content">
            <h1 className="subheader">Facturas proveedores</h1>

            <Paper>
              <TableContainer>
                <Table
                  className={this.useStyles.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Entrada</TableCell>
                      <TableCell align="left">Cif</TableCell>
                      <TableCell align="left">Proveedor</TableCell>
                      <TableCell align="left">Nº factura</TableCell>
                      <TableCell align="left">Fecha emisión</TableCell>
                      <TableCell align="left">Fecha entrada</TableCell>
                      <TableCell align="left">Total factura</TableCell>
                      <TableCell align="left">Forma pago</TableCell>
                      <TableCell align="left">Fecha prevista pago</TableCell>
                      <TableCell align="left">Fecha real pago</TableCell>
                      <TableCell align="left">Cod Proyecto</TableCell>
                      <TableCell align="left">Denominación</TableCell>
                      <TableCell align="left">Pedido</TableCell>
                      <TableCell align="left">Nombre factura</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.proveedores.length >= 0
                      ? this.state.proveedores
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left">{row.id}</TableCell>
                              <TableCell align="left">{row.cif}</TableCell>
                              <TableCell align="left">{row.empresa}</TableCell>
                              <TableCell align="left">
                                {row.nº_factura}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_emision}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_entrada}
                              </TableCell>

                              <TableCell align="left">
                                {row.total_factura1}
                              </TableCell>

                              <TableCell align="left">
                                {row.forma_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.fecha_prevista_pago}
                              </TableCell>
                              <TableCell align="left">
                                {row.fecha_real_pago}
                              </TableCell>

                              <TableCell align="left">
                                {row.cod_proyecto}
                              </TableCell>
                              <TableCell align="left">
                                {row.denominacion}
                              </TableCell>

                              <TableCell align="left">
                                {row.num_pedido}
                              </TableCell>
                              <TableCell align="left">
                                <input
                                  type="submit"
                                  name="submit"
                                  value=""
                                  data-txt={row.path_factura}
                                  onClick={this.getImageFactura}
                                  className="btn-table"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                      : null}
                    {this.emptyRows > 0 && (
                      <TableRow style={{ height: 0 * this.emptyRows }}>
                        <TableCell colSpan={14} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={"Filas por página"}
                  component="div"
                  rowsPerPageOptions={[1, 10, 20]}
                  count={
                    this.state.proveedores.length >= 0
                      ? this.state.proveedores.length
                      : 1
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
          </section>
        </div>
      </div>
    );
  }
}

export default FacturasJefeObra;

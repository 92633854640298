import React, { Component } from "react";
import Header from "../../components/Header";
import Slider from "../../components/slider";
import TablaProveedor from '../tablas/TablaProveedor';

class ConsultaProveedor extends Component{

    render(){
        return(

            <div className="administracion">
            <Header
              buzon="Buzon"
              facturas="Facturación"
              proyectos="Proyectos"
              pedidos="Pedidos"
              visible="true"
              visibleBU="true"
            />
            <Slider
              title="Administración"
              size="slider-small"
              visibleAdmin="true"
            />
            <TablaProveedor/>
            </div>
        );
    }
}
export default ConsultaProveedor;